import GraphCardsContainer from "./GraphCardsContainer";
import LeadListTableComponent from "./LeadListTableComponent";
import RenderGraphComponent from "./RenderGraphComponent";
import TaskListTableComponent from "./TaskListTableComponent";

const MainDashboardContainer = () => {
  return (
    <div className="form-content-area">
      <GraphCardsContainer />
      <RenderGraphComponent />
      <TaskListTableComponent />
      <LeadListTableComponent />
    </div>
  )
}


export default MainDashboardContainer;
