import classNames from "classnames";
import { useEffect } from "react";
import { getImage } from "../helpers/assets";
import { useAuth } from "../hooks/useAuth";
import { ESelectedGraphCard } from "../pages/Dashboard/enums";
import { getDashboardMeta } from "../services/dashboard";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectCard, selectDashboardState, setMetaInfo } from "../store/reducers/dashboard/dashboardSlice";


const GraphCardsContainer = () => {
  const { metaInfo, selectedCard } = useAppSelector(selectDashboardState);
  const { token } = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (token) {
      getDashboardMeta(token).then(response => {
        dispatch(setMetaInfo(response.data))
      })
    }
  }, [token])

  return (
    <div className="row statics-row graph-row-column uniform-space-b-5 ">
      <div className="col" onClick={() => dispatch(selectCard(ESelectedGraphCard.LEADS))}>
        <div className={
          classNames('card-block sm-block hover-block', {
            'acitve-block': selectedCard === ESelectedGraphCard.LEADS,
          })}>
          <p> Leads </p>
          <h3> {metaInfo?.leads.count.toLocaleString()}
            {
              metaInfo?.leads.change &&
              <span className={
                classNames('number-badge ms-1', {
                  'badege-success': metaInfo?.leads.change === 'positive',
                  'badege-danger': metaInfo?.leads.change !== 'positive',
                })}
              > {metaInfo?.leads.change === 'positive' ? '+' : '-'}{metaInfo?.leads.diff}%
              </span>
            }
          </h3>
          <div className="graph-area"> <img src={getImage("Graphline.png")} alt="" /></div>
        </div>
      </div>
      <div className="col" onClick={() => dispatch(selectCard(ESelectedGraphCard.APPOINTMENTS))}>
        <div className={
          classNames('card-block sm-block hover-block', {
            'acitve-block': selectedCard === ESelectedGraphCard.APPOINTMENTS,
          })}>
          <p> Appointments </p>
          <h3> {metaInfo?.appointments.count.toLocaleString()}
            {
              metaInfo?.appointments.change && <span className={
                classNames('number-badge ms-1', {
                  'badege-success': metaInfo?.appointments.change === 'positive',
                  'badege-danger': metaInfo?.appointments.change !== 'positive',
                })}> {metaInfo?.appointments.change === 'positive' ? '+' : '-'}{metaInfo?.appointments.diff}%
              </span>
            }
          </h3>
          <div className="graph-area"> <img src={getImage("Graphline2.png")} alt="" /></div>
        </div>
      </div>
      <div className="col" onClick={() => dispatch(selectCard(ESelectedGraphCard.SURGERIES))}>
        <div className={
          classNames('card-block sm-block hover-block', {
            'acitve-block': selectedCard === ESelectedGraphCard.SURGERIES,
          })}>
          <p> Surgeries </p>
          <h3> {metaInfo?.surgeries.count.toLocaleString()}
            {
              metaInfo?.surgeries.change && 
              <span className={
                classNames('number-badge ms-1', {
                  'badege-success': metaInfo?.surgeries.change === 'positive',
                  'badege-danger': metaInfo?.surgeries.change !== 'positive',
                })}> {metaInfo?.surgeries.change === 'positive' ? '+' : '-'}{metaInfo?.surgeries.diff}%
              </span>
            }
          </h3>
          <div className="graph-area"> <img src={getImage("Graphline2.png")} alt="" /></div>
        </div>
      </div>
      <div className="col" onClick={() => dispatch(selectCard(ESelectedGraphCard.REVENUE))}>
        <div className={
          classNames('card-block sm-block hover-block', {
            'acitve-block': selectedCard === ESelectedGraphCard.REVENUE,
          })}>
          <p> Revenue </p>
          <h3> {metaInfo?.revenue.count.toLocaleString()}
            {
              metaInfo?.revenue.change && 
              <span className={
                classNames('number-badge ms-1', {
                  'badege-success': metaInfo?.revenue.change === 'positive',
                  'badege-danger': metaInfo?.revenue.change !== 'positive',
                })}> {metaInfo?.revenue.change === 'positive' ? '+' : '-'}{metaInfo?.revenue.diff}%
              </span>
            }
          </h3>
          <div className="graph-area"> <img src={getImage("Graphline2.png")} alt="" /></div>
        </div>
      </div>
      <div className="col" onClick={() => dispatch(selectCard(ESelectedGraphCard.TASKS))}>
        <div className={
          classNames('card-block sm-block hover-block', {
            'acitve-block': selectedCard === ESelectedGraphCard.TASKS,
          })}>
          <p> Tasks </p>
          <h3> {metaInfo?.tasks.count.toLocaleString()}
            <span className={
              classNames('number-badge ms-1', {
                'badege-success': metaInfo?.tasks.change === 'positive',
                'badege-danger': metaInfo?.tasks.change !== 'positive',
              })}> {metaInfo?.tasks.change === 'positive' ? '+' : '-'}{metaInfo?.tasks.diff}%
            </span>
          </h3>
          <div className="graph-area"> <img src={getImage("Graphline2.png")} alt="" /></div>
        </div>
      </div>
    </div>
  )
}


export default GraphCardsContainer;
