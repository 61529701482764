import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useDebounce } from "../hooks/useDebounce";
import usePrevious from "../hooks/usePrevious";
import { getAllContacts } from "../services/contacts";
import { getNylasAuthorizationUrl } from "../services/email";
import ContactCreateModal from "./CreateContactModal";

interface IContactDetails {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_no: string | number;
}

type ICheckboxes = { [key: number | string]: boolean };

const ContactListComponent = ({ IsNylasConnected }: { IsNylasConnected: boolean }) => {
  const pageSize = 5;
  const { token } = useAuth();
  const [contacts, setContacts] = useState<IContactDetails[]>([]);
  const [searchedContacts, setSeachedContacts] = useState<IContactDetails[]>([]);
  const [contactCount, setContactCount] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [loading, setIsLoading] = useState<boolean>(false);

  const previousDebouncedSearchTerm = usePrevious(debouncedSearchTerm);

  useEffect(() => {
    if (!IsNylasConnected) return;
    if (token) {
      let searchTerm;
      if (previousDebouncedSearchTerm !== debouncedSearchTerm && debouncedSearchTerm) {
        searchTerm = debouncedSearchTerm;
      }
      setIsLoading(true);
      getAllContacts(token, searchTerm).then((response) => {
        setIsLoading(false);
        setContacts(response.data.contacts);
        const pageArray = [];
        for (var i = 1; i <= Math.ceil(response.data.count / pageSize); i++) {
          pageArray.push(i);
        }
        setContactCount(response.data.count);
      }).catch(error => {
        console.log(error);
      });
    }
  }, [token, IsNylasConnected])

  if (!IsNylasConnected) {
    return (
      <div className="form-content-area">
        <button className="btn btn-primary btn-sm padd-x-20"
          onClick={() => {
            if (token) {
              getNylasAuthorizationUrl(token).then(response => {
                const { authorization_url } = response.data;
                window.open(authorization_url, '_blank', `location=yes,height=570,width=520,top=${((window.screen.height / 2) - 285) / 2},left=${(window.screen.width / 2) - 260} scrollbars = yes, status = yes`)
              })
            }
          }}
        >
          Connect email with Nylas </button>
      </div>
    )
  }


  return (
    <div className="form-content-area">
      <div className="row statics-row  ">
        <div className="col-sm-12">
          <div className="card-block ">
            <div className="align-with-leftright">
              <div>
                <h3> Your Contacts list </h3>
                {!loading && <p className="sub-section-title transform-none"> There are <strong> {contactCount} </strong>
                  call list </p>}
              </div>
              <div className="search-with-button">
                <button className="btn btn-primary btn-sm padd-x-20"
                  onClick={() => {
                    $(`#creat-contact`).modal('show');
                  }}
                >
                  Create Contact </button>
                <div className="searcharea ">
                  <i className="v-transform-center"> <img src="images/icon-search.svg" alt="" />
                  </i>
                  <input type="text" className="input-control" placeholder="Search keyword..." aria-label="Search keyword..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {!loading &&
              <div className="table-responsive table-custome">
                <table className="table datatable responsive-mobile-table">
                  <thead>
                    <tr>
                      {/* <th scope="col" width={30}> <input type="checkbox" className="form-check-input" /> </th> */}
                      {/* <th scope="col"> <input type="checkbox" className="form-check-input" /> </th> */}
                      <th scope="col"> <span> Contact name </span> <i className="short-arrow">
                        <img src="images/short-arrow.svg" alt="" /> </i> </th>
                      <th scope="col"> <span> Email </span> <i className="short-arrow"> <img src="images/short-arrow.svg" alt="" /> </i> </th>
                      <th scope="col"> <span> Phone Number </span> <i className="short-arrow">
                        <img src="images/short-arrow.svg" alt="" /> </i> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {contacts && contacts.map((contact) => {
                      return (
                        <tr>
                          {/* <td> <input type="checkbox" className="form-check-input" /> </td> */}
                          <td data-cell="Contact name">
                            <Link to={`/contacts/detail/${contact.id}`}>
                              <span className="text-primary font-semibold">
                                <span className="number-badge badege-primary me-1">
                                  {`${contact?.first_name && contact?.first_name.charAt(0).toUpperCase()}${contact?.last_name && contact?.last_name.charAt(0).toUpperCase()}`}

                                </span>
                                {`${contact?.first_name} ${contact?.last_name}`} </span>
                            </Link>
                          </td>
                          <td data-cell="Email"> {contact.email || 'Not Available'} </td>
                          <td data-cell="Phone Number ">{contact.phone_no}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div> || 'Loading ...'}

          </div>
        </div>
      </div>
      <ContactCreateModal />
    </div >
  )
}


export default ContactListComponent;
