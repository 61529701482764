import {
  ACTION_TYPE,
  EMAIL_LIST_TYPE,
} from "../components/EmailBodyComponent";
import httpClient from "../helpers/httpClient";
import { IEmailDetails, IEmailDraft } from "../store/reducers/email/emailSlice";

export const uploadEmailAttachment = (
  token: string,
  id: string | number | undefined | null,
  payload: {
    file: File;
  }
) => {
  let url = `/emails`;
  if (!id) {
    url = `${url}/files/`;
  } else {
    url = `${url}/${id}/files/`;
  }
  return httpClient.post(url, payload, {
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteAttachment = (
  token: string,
  id: string | number,
  attachmentId: string | number
) => {
  return httpClient.delete(`/emails/${id}/files/${attachmentId}/`, {
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const saveEmailDraft = (
  token: string,
  id: string | number,
  payload: IEmailDraft
) => {
  return httpClient.patch(`/emails/${id}/`, payload, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createEmailDraft = (token: string, payload: IEmailDraft) => {
  return httpClient.post(`/emails/`, payload, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getNylasAuthorizationUrl = (token: string) => {
  return httpClient.get(`/emails/nylas/auth`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const sendEmail = (token: string, emailId: string) => {
  return httpClient.get(`/emails/${emailId}/send`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getEmailDetail = (token: string, emailId: string) => {
  return httpClient.get(`/emails/${emailId}/`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteEmail = (token: string, emailId: string) => {
  return httpClient.delete(`/emails/${emailId}/`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};


export const getAllEmails = (
  token: string | null | undefined,
  page: number = 1,
  filterType = EMAIL_LIST_TYPE.ALL,
  search: string,
) => {
  let url = `/emails/?page=${page}&type=${filterType}`;
  if (search) {
    url += `&search=${search}`
  }
  return httpClient.get(
    url,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const searchEmails = (
  token: string | null | undefined,
  page: number = 1,
  q: string
) => {
  return httpClient.get(
    `/emails/?search=${q}&page=${page}`,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getEmailListMeta = (token: string | null | undefined) => {
  return httpClient.get(`/emails/meta/`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteAllEmails = (
  token: string | null | undefined,
  emailIds: number[] | string[],
  actionType = ACTION_TYPE.BULK_DELETE
) => {
  return httpClient.post(
    `/emails/bulk?action=${actionType}`,
    {
      ids: emailIds,
    },
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};


export const starUnstarEmail = (token: string | null | undefined, emailId: string | number, action: ACTION_TYPE) => {
  return httpClient.get(`/emails/${emailId}/star-unstar?action=${action}`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};


export const handleGoogleOauthRedirect = (token: string | null | undefined, accessToken: string) => {
  return httpClient.post(`/emails/oauth-redirect/`, {
    "google_access_token": accessToken
  }, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
