import { createSlice } from "@reduxjs/toolkit";
import { similarity } from "../../../helpers/search";
import { RootState } from "../../configure";
import { IUserMinDetail } from "../task/taskSlice";

// TODO: remove below lines
const fewSecondsAgo = new Date();
fewSecondsAgo.setMinutes(fewSecondsAgo.getMinutes() - 1);
const fourMinAgo = new Date();
fourMinAgo.setMinutes(fourMinAgo.getMinutes() - 4);
const twentyMinAgo = new Date();
twentyMinAgo.setMinutes(twentyMinAgo.getMinutes() - 20);
const fourHoursAgo = new Date();
fourHoursAgo.setMinutes(fourHoursAgo.getHours() - 4);
const tenHoursAgo = new Date();
tenHoursAgo.setMinutes(fourHoursAgo.getHours() - 10);

export enum MESSAGE_TYPE {
  TEXT = "text",
  FILE = "file",
  MISSED_CALL = "missed_call",
  OUTBOUND_CALL = "outbound_call",
  INBOUND_CALL = "inbound_call",
}
export interface IMessages {
  id?: number;
  message?: string;
  created_at?: string;
  user: IUserMinDetail;
  starred?: boolean;
  message_type: MESSAGE_TYPE;
  reply_to?: IMessages;
  duration?: string;
  room?: IChatRoom;
}

export interface IChatUserInfo {
  username?: string;
  shortUsername?: string;
  email?: string;
  phoneNo?: string;
  userId?: number;
}

export interface IChatRoom {
  id: string;
  user: IUserMinDetail;
  contact: IUserMinDetail;
  unread_message: number;
  last_message: IMessages;
  archived_at?: string;
}

export interface IChatState {
  currentRoom?: IChatRoom | null;
  replyTo?: IMessages;
  messages?: IMessages[];
  rooms?: IChatRoom[];
  searchedRooms?: IChatRoom[];
  client?: WebSocket;
  starredMessages?: IMessages[];
  archivedRooms?: IChatRoom[];
  showStarredMessages?: boolean;
  showArchivedMessages?: boolean;
  isMessageSelectorEnabled?: boolean;
  selectedMessages?: number[];
  selectedContacts?: number[],
  refreshChat?: boolean;
}

export const initialState: IChatState = {
  rooms: [],
  selectedMessages: [],
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setShowStarredMessages: (state, data) => {
      state.showStarredMessages = data.payload;
    },
    setShowArchivedMessages: (state, data) => {
      state.showArchivedMessages = data.payload;
    },
    selectRoom: (state, data) => {
      state.currentRoom = data.payload;
      state.replyTo = undefined;
    },
    addNewRoom: (state, data) => {
      state.rooms?.unshift(data.payload);
    },
    setReplyTo: (state, data) => {
      state.replyTo = data.payload;
    },
    addMessage: (state, data) => {
      if (state.currentRoom?.id === data.payload.room.id) {
        if (
          state.messages &&
          !state.messages.find((message) => message.id === data.payload.id)
        ) {
          const messages = [...state.messages];
          messages.push(data.payload);
          state.messages = messages;
          if (state.currentRoom?.last_message) {
            if ([MESSAGE_TYPE.INBOUND_CALL, MESSAGE_TYPE.MISSED_CALL, MESSAGE_TYPE.OUTBOUND_CALL].includes(data.payload.status)) {
              // Handle Call Scenarios
            } else {
              state.currentRoom = {
                ...state.currentRoom,
                last_message: data.payload
              };
            }
          }
          state.rooms?.map(room => {
            if (room.id === state.currentRoom?.id) {
              room.last_message = data.payload;
            }
            return room;
          })
        }
      } else {
        state.rooms?.map(room => {
          if (room.id === data.payload.room.id) {
            room.last_message = data.payload;
            if (room.unread_message && room.unread_message > 0) {
              room.unread_message = room.unread_message + 1;
            } else {
              room.unread_message = 1;
            }
          }
          return room;
        })
      }
      state.replyTo = undefined;
    },
    removeLastMessage: (state) => {
      state.messages = state.messages?.slice(0, -1)
    },
    setClient: (state, data) => {
      state.client = data.payload;
    },
    setRooms: (state, data) => {
      state.rooms = data.payload;
      state.currentRoom = data.payload[0];
      state.replyTo = undefined;
    },
    updateSearchedRooms: (state, data) => {
      const searchedText = data.payload.searchTerm.toLowerCase();
      if (searchedText) {
        state.searchedRooms = state.rooms?.filter((room: IChatRoom) => {
          const user =
            room.user.id === data.payload.currentUser?.id
              ? room.contact
              : room.user;
          return (
            similarity(searchedText, user.first_name.toLowerCase()) > 0.5 ||
            similarity(searchedText, user.last_name.toLowerCase()) > 0.5
          );
        });
      } else {
        state.searchedRooms = undefined;
      }
    },
    starUnstarMessageInState: (state, data) => {
      state.messages = state.messages?.map((message: IMessages) => {
        if (message.id === data.payload.messageId) {
          message.starred = data.payload.starred;
        }
        return message;
      });
    },
    setChatMessages: (state, data) => {
      state.messages = data.payload;
    },
    deleteMessageFromState: (state, data) => {
      state.messages = state.messages?.filter(
        (message: IMessages) => message.id !== data.payload
      );
    },
    initializeChatRoom: (state) => {
      state.currentRoom = null;
      state.messages = [];
      state.replyTo = undefined;
    },
    setStarredMessages: (state, data) => {
      state.starredMessages = data.payload;
    },
    setArchivedRooms: (state, data) => {
      state.archivedRooms = data.payload;
    },
    handleForwardButtonClicked: (state) => {
      state.isMessageSelectorEnabled = true;
    },
    updateMessageSelector: (state, data) => {
      state.isMessageSelectorEnabled = data.payload;
    },
    selectMessageForForward: (state, data) => {
      state.selectedMessages = [
        ...(state.selectedMessages || []),
        data.payload
      ]
    },
    unSelectMessageFromForward: (state, data) => {
      state.selectedMessages = state.selectedMessages?.filter(messageId => messageId !== data.payload);
    },
    selectContact: (state, data) => {
      state.selectedContacts = [
        ...(state.selectedContacts || []),
        data.payload
      ]
    },
    unSelectContact: (state, data) => {
      state.selectedContacts = state.selectedContacts?.filter(contactId => contactId !== data.payload);
    },
    updateRefreshChat: (state, data) => {
      state.refreshChat = data.payload;
    }
  },
});

export const {
  selectRoom,
  addNewRoom,
  setReplyTo,
  starUnstarMessageInState,
  addMessage,
  removeLastMessage,
  setRooms,
  setClient,
  updateSearchedRooms,
  setChatMessages,
  deleteMessageFromState,
  initializeChatRoom,
  setShowStarredMessages,
  setShowArchivedMessages,
  setStarredMessages,
  setArchivedRooms,
  handleForwardButtonClicked,
  selectMessageForForward,
  unSelectMessageFromForward,
  selectContact,
  unSelectContact,
  updateMessageSelector,
  updateRefreshChat,
} = chatSlice.actions;

export const selectChatState = (state: RootState) => state.chat;

export default chatSlice.reducer;
