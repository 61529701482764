import { useEffect } from "react";
import { getImage } from "../helpers/assets";
import { useAuth } from "../hooks/useAuth";
import { sendOtp, verifyOtp } from "../services/auth";
import { useAppSelector } from "../store/hooks";
import { selectUserState } from "../store/reducers/user/userSlice";

const OtpVerificationModal = () => {
  const { loginUser } = useAuth();
  const { user, token, verificationType } = useAppSelector(selectUserState)

  const handleVerifyClick = () => {
    const otp = $('.otp-input').toArray().map((input: any) => input.value).join('');
    let isValid = true;
    if (otp.length !== 6) {
      isValid = false;
    }
    if (isValid && token && user) {
      verifyOtp(token, {
        phone_no: user?.phone_no,
        otp
      }).then(() => {
        loginUser && loginUser(token, user);
        window.location.href = "/";
      })
    }

  }

  const handleResendClick = () => {
    if (token && user) {
      sendOtp(token, {
        phone_no: user.phone_no
      }).then(() => {
      })
    }
  }

  const handleChange = (el: any) => {
    let newValue = el.target.value;
    if (newValue.length > 1) {
      newValue = newValue.slice(0, 1);
    }
    el.target.value = newValue;
  };


  const handleKeyUp = (el: any) => {
    let newValue = el.target.value;
    let currentIndex = Number(el.target.id.replace('otpInput', ''));
    if (newValue.length > 1) {
      newValue = newValue.slice(0, 1);
    }
    if (el.keyCode === 8 && newValue.length === 0) {
      $(`#otpInput${currentIndex - 1}`)[0].focus()
    } else if (newValue.length === 1) {
      $(`#otpInput${currentIndex + 1}`)[0].focus()
    }
  }

  useEffect(() => {
    for (let i = 1; i <= 6; i++) {
      $(`#otpInput${i}`).val('');
    }
  }, [])

  return (
    <div className="modal fade" id="modal-otp" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered otp-diaolog ">
        <div className="modal-content ">
          <div className="modal-body text-center">
            <img src={getImage("padlock.svg")} alt="" />
            <h2> Enter OTP</h2>
            <p className=" mb-5"> We sent a one-time code to your {verificationType === 'email' ? 'email address' : 'phone number'} to confirm </p>
            <div className="form-group  mb-xl-5  mb-3">
              <div className="row">
                <div className="col">  <input id="otpInput1" className="form-control styled-control text-center otp-input" type="number" max="9" min="0" onKeyDown={handleChange} onKeyUp={handleKeyUp} /> </div>
                <div className="col">  <input id="otpInput2" className="form-control styled-control text-center otp-input" type="number" max="9" min="0" onKeyDown={handleChange} onKeyUp={handleKeyUp} /> </div>
                <div className="col">  <input id="otpInput3" className="form-control styled-control text-center otp-input" type="number" max="9" min="0" onKeyDown={handleChange} onKeyUp={handleKeyUp} /> </div>
                <div className="col">  <input id="otpInput4" className="form-control styled-control text-center otp-input" type="number" max="9" min="0" onKeyDown={handleChange} onKeyUp={handleKeyUp} /> </div>
                <div className="col">  <input id="otpInput5" className="form-control styled-control text-center otp-input" type="number" max="9" min="0" onKeyDown={handleChange} onKeyUp={handleKeyUp} /> </div>
                <div className="col">  <input id="otpInput6" className="form-control styled-control text-center otp-input" type="number" max="9" min="0" onKeyDown={handleChange} onKeyUp={handleKeyUp} /> </div>
              </div>
            </div>
            <div className="form-group  mb-xl-3  mb-2 text-center  ">
              <button type="button" className="btn btn-primary border-0 btn-lg w-full" onClick={handleVerifyClick}>Verify {verificationType === 'email' ? 'email' : 'Phone number'} </button>
            </div>
            <div className="form-group  mb-xl-5  mb-3 text-center  ">
              <p className="text-center mt-4 font-medium"> Didn’t get the code?<span className="text-link text-underline" onClick={handleResendClick}> Resend</span> </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OtpVerificationModal;
