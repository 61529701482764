import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { useAuth } from '../hooks/useAuth';
import { ESelectedGraphCard } from "../pages/Dashboard/enums";
import { getDashboardGraphData } from '../services/dashboard';
import { useAppSelector } from '../store/hooks';
import { selectDashboardState } from '../store/reducers/dashboard/dashboardSlice';


const RenderGraphComponent = () => {
  const { token } = useAuth();
  const { metaInfo, selectedCard } = useAppSelector(selectDashboardState);
  const [graphData, setGraphData] = useState<any>();

  const prepData = (rawData: any, xField: string, yField: string) => {
    let x: any = [];
    let y: any = [];

    rawData.forEach(function (datum: any, i: any) {
      if (i % 100) return;
      x.push(new Date(datum[xField]));
      y.push(datum[yField]);
    });

    return [{
      type: "bar",
      x: x,
      y: y,
      marker: {
        color: '#006FFF',
        'border-radius': '12px',
        line: {
          color: '#006FFF',
        }
      }
    }];
  }


  useEffect(() => {
    if (token && selectedCard) {
      getDashboardGraphData(token, selectedCard).then(response => {
        const { data: rawData, meta } = response.data;
        const { xField, yField } = meta;
        const data = prepData(rawData, xField, yField);
        setGraphData(data);
      })
    }
  }, [selectedCard, token])

  return (
    <div className="row statics-row uniform-space-b-5 ">
      <div className="col-sm-12">
        <div className="card-block ">
          <div className="align-with-leftright">
            <div>
              <p> {selectedCard} Total. </p>
              <h3> {metaInfo?.[selectedCard.toLowerCase() as ESelectedGraphCard].count.toLocaleString()}
                {metaInfo?.[selectedCard.toLowerCase() as ESelectedGraphCard].change && 
                  <span className={
                    classNames('number-badge ms-1', {
                      'badege-success': metaInfo?.[selectedCard.toLowerCase() as ESelectedGraphCard].change === 'positive',
                      'badege-danger': metaInfo?.[selectedCard.toLowerCase() as ESelectedGraphCard].change === 'negative',
                    })}> {metaInfo?.[selectedCard.toLowerCase() as ESelectedGraphCard].change === 'positive' ? '+' : '-'}{metaInfo?.[selectedCard.toLowerCase() as ESelectedGraphCard].diff}%
                  </span>
                }
              </h3>
            </div>
          </div>
          <div className="graph-area">
            {
              graphData && <Plot
                data={graphData}
                style={{
                  marginLeft: -25,
                }}
                layout={{
                  height: window.innerHeight - 200,
                  width: window.innerWidth - 350,
                  xaxis: {
                    rangeselector: {
                      bordercolor: "blue",
                      bgcolor: '#F8F9FB',
                      font: {
                        size: 12,
                      },
                      xanchor: 'left',
                      yanchor: 'top',
                      activecolor: 'white',
                      buttons: [{
                        step: 'month',
                        stepmode: 'backward',
                        count: 1,
                        label: '1m'
                      }, {
                        step: 'month',
                        stepmode: 'backward',
                        count: 6,
                        label: '6m'
                      }, {
                        step: 'year',
                        stepmode: 'todate',
                        count: 1,
                        label: 'YTD'
                      }, {
                        step: 'year',
                        stepmode: 'backward',
                        count: 1,
                        label: '1y'
                      }, {
                        step: 'all',
                        label: 'LIFETIME'
                      }]
                    },
                  },
                  yaxis: {
                    fixedrange: true
                  }
                }}
                config={{
                  displayModeBar: false,
                }}
              />
            }
          </div>
        </div>
      </div>
    </div>

  )
}

export default RenderGraphComponent;
