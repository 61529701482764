import classNames from "classnames";
import moment from "moment";
import { getImage } from "../../../helpers/assets";
import { useAuth } from "../../../hooks/useAuth";
import { starUnstarMessage } from "../../../services/chat";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { handleForwardButtonClicked, IChatUserInfo, IMessages, MESSAGE_TYPE, selectChatState, selectMessageForForward, setReplyTo, starUnstarMessageInState, unSelectMessageFromForward } from "../../../store/reducers/chat/chatSlice";
import DeleteMessageModal from "./DeleteMessageConfirmModal";

const RecieverTextMessageComponent = ({ message, receiverInfo }: { message?: IMessages, receiverInfo: IChatUserInfo }) => {
  const dispatch = useAppDispatch();
  const { token } = useAuth();
  const { currentRoom, isMessageSelectorEnabled, selectedMessages } = useAppSelector(selectChatState);

  const handleStarMessageClick = () => {
    if (message) {
      dispatch(starUnstarMessageInState({
        messageId: message?.id, starred: !message.starred
      }));

      starUnstarMessage(token, currentRoom?.id, message?.id, !message.starred).catch(error => {
        dispatch(starUnstarMessageInState({
          messageId: message?.id, starred: message.starred
        }));
      })
    }
  }

  const handleForwardCheckbox = () => {
    if (message && message?.id) {
      if (selectedMessages?.includes(message?.id)) {
        dispatch(unSelectMessageFromForward(message.id))
      } else {
        dispatch(selectMessageForForward(message.id))
      }
    }
  }

  return (
    <div className={
      classNames("message-sender-side with-more-action", {
        "message-mean-to-forward": isMessageSelectorEnabled,
      })
    } id={`Message-${message?.id}`}>
      {isMessageSelectorEnabled && <div className="checkbox-aside" onClick={handleForwardCheckbox}>
        <input type="checkbox" className="form-check-input" checked={message?.id ? selectedMessages?.includes(message?.id) : false} />
      </div>}
      <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute ">
        {receiverInfo.shortUsername}
      </span>
      <div className="message-bubbles d-inline-block">
        {message?.message}
      </div>
      <div className="more-action-wrap">
        <div className=" dropdown d-inline-block ">
          <span
            className="btn-dots dropdown-toggle d-inline-block"

            id="dropdonw-more"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div className="btn-moreaction">
              <img src={getImage("icon-more-verticale.svg")} alt="" />
            </div>
          </span>
          <div
            className="dropdown-menu dropdown-menu-right shadow dropdown-items-with-icon"
            aria-labelledby="dropdonw-more"
          >
            <span
              className="btn-dots dropdown-toggle"

              id="dropdonw-more"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            ></span>
            <span className="dropdown-item" onClick={() => {
              dispatch(setReplyTo(message))
            }}>
              Reply
            </span>
            <span className="dropdown-item" onClick={() => {
              // Open Forward Message Modal
              dispatch(handleForwardButtonClicked())
              dispatch(selectMessageForForward(message))
            }}>
              Forward Message
            </span>
            <span className="dropdown-item" onClick={handleStarMessageClick}>
              {message?.starred ? 'Unstar Message' : 'Star Message'}
            </span>
            <span
              className="dropdown-item"
              onClick={() => {
                $(`#deleteChatModal${message?.id}`).modal('show');
              }}
            >
              Delete
            </span>
          </div>
        </div>
      </div>
      <DeleteMessageModal messageId={message?.id} />
      <p>
        {moment(message?.created_at).format('hh : mm A')} {message?.starred && <img src={getImage("star-gray.svg")} alt="" />}
      </p>
    </div>
  );
}

const RecieverMissedCallComponent = ({ message, receiverInfo }: { message?: IMessages, receiverInfo: IChatUserInfo }) => {
  return (
    <div className="message-sender-side" id={`Message-${message?.id}`}>
      <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute ">
        {receiverInfo.shortUsername}
      </span>
      <div className="missid-call">
        <img src={getImage("phone_missed.svg")} alt="" /> Missed Call
      </div>
      <p> {moment(message?.created_at).format('hh : mm A')} </p>
    </div>
  );
}

const RecieverInBoundCallComponent = ({ message, receiverInfo }: { message?: IMessages, receiverInfo: IChatUserInfo }) => {
  return (
    <div className="message-sender-side" id={`Message-${message?.id}`}>
      <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute ">
        {receiverInfo.shortUsername}
      </span>
      <div className="inbound-call">
        <img src={getImage("phone_callback.svg")} alt="" /> Inbound Call
        <span> {message?.duration} </span>
      </div>
      <p> {moment(message?.created_at).format('hh : mm A')} </p>
    </div>
  );
}

const RecieverMessageComponent = ({ message, receiverInfo }: { message?: IMessages, receiverInfo: IChatUserInfo }) => {
  switch (message?.message_type) {
    case MESSAGE_TYPE.TEXT:
      return <RecieverTextMessageComponent message={message} receiverInfo={receiverInfo} />
    case MESSAGE_TYPE.MISSED_CALL:
      return <RecieverMissedCallComponent message={message} receiverInfo={receiverInfo} />
    case MESSAGE_TYPE.INBOUND_CALL:
      return <RecieverInBoundCallComponent message={message} receiverInfo={receiverInfo} />
    default:
      return <></>
  }
}

export default RecieverMessageComponent;
