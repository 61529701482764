import { createSlice } from "@reduxjs/toolkit";
import { ESelectedGraphCard } from "../../../pages/Dashboard/enums";
import { RootState } from "../../configure";

export interface graphSelectState {
  selectedCard: ESelectedGraphCard;
}

const initialState: graphSelectState = {
  selectedCard: ESelectedGraphCard.LEADS,
};

export const graphSelectSlice = createSlice({
  name: "graphSelect",
  initialState,
  reducers: {
    selectCard: (state, data) => {
      state.selectedCard = data.payload;
    },
  },
});

export const { selectCard } = graphSelectSlice.actions;

export const selectGraphSelectState = (state: RootState) => state.graphSelect;

export default graphSelectSlice.reducer;
