import classNames from "classnames";
import moment from "moment";
import { getImage } from "../../../helpers/assets";
import { useAuth } from "../../../hooks/useAuth";
import { starUnstarMessage } from "../../../services/chat";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IMessages, selectChatState, MESSAGE_TYPE, starUnstarMessageInState, IChatUserInfo, setReplyTo, handleForwardButtonClicked, selectMessageForForward, unSelectMessageFromForward } from "../../../store/reducers/chat/chatSlice";
import DeleteMessageModal from "./DeleteMessageConfirmModal";

const SenderTextMessageComponent = ({ message, senderInfo }: { message?: IMessages, senderInfo: IChatUserInfo }) => {
  const dispatch = useAppDispatch();
  const { token } = useAuth();
  const { currentRoom, isMessageSelectorEnabled, selectedMessages } = useAppSelector(selectChatState);

  const handleStarMessageClick = () => {
    if (message) {
      dispatch(starUnstarMessageInState({
        messageId: message?.id, starred: !message.starred
      }));
      starUnstarMessage(token, currentRoom?.id, message?.id, !message.starred).catch(error => {
        dispatch(starUnstarMessageInState({
          messageId: message?.id, starred: message.starred
        }));
      })
    }
  }

  const handleForwardCheckbox = () => {
    if (message && message?.id) {
      if (selectedMessages?.includes(message?.id)) {
        dispatch(unSelectMessageFromForward(message.id))
      } else {
        dispatch(selectMessageForForward(message.id))
      }
    }
  }

  return (
    <div
      className={classNames("message-receiver-side with-more-action", {
        "message-mean-to-forward": isMessageSelectorEnabled,
      })} id={`Message-${message?.id}`} >
      {isMessageSelectorEnabled && <div className="checkbox-aside">
        <input type="checkbox" className="form-check-input" checked={message?.id ? selectedMessages?.includes(message?.id) : false} onChange={handleForwardCheckbox}/>
      </div>}
      <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute ">
        {senderInfo.shortUsername}
      </span>
      <div className="more-action-wrap">
        <div className=" dropdown d-inline-block ">
          <span
            className="btn-dots dropdown-toggle d-inline-block"

            id="dropdonw-more"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div className="btn-moreaction">
              <img src={getImage("icon-more-verticale.svg")} alt="" />
            </div>
          </span>
          <div
            className="dropdown-menu dropdown-menu-right shadow dropdown-items-with-icon"
            aria-labelledby="dropdonw-more"
          >
            <span
              className="btn-dots dropdown-toggle"

              id="dropdonw-more"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            ></span>
            <span className="dropdown-item" onClick={() => {
              dispatch(setReplyTo(message))
            }}>
              Reply
            </span>
            <span className="dropdown-item" onClick={() => {
              // Open Forward Message Modal
              dispatch(handleForwardButtonClicked())
              dispatch(selectMessageForForward(message?.id))
            }}>
              Forward Message
            </span>
            <span className="dropdown-item" onClick={handleStarMessageClick}>
              {message?.starred ? 'Unstar Message' : 'Star Message'}
            </span>
            <span
              className="dropdown-item"
              onClick={() => {
                $(`#deleteChatModal${message?.id}`).modal('show');
              }}
            >
              Delete
            </span>
          </div>
        </div>
      </div>
      {
        Object.keys(message?.reply_to || {}).length > 0 &&
        <div className="quote-message-to-forward">
          <h4>
            {`${message?.reply_to?.user?.first_name.charAt(0).toUpperCase()}${message?.reply_to?.user?.last_name.charAt(0).toUpperCase()}`}
          </h4>
          <p>
            {message?.reply_to?.message}
          </p>
        </div>
      }

      <div className="message-bubbles">
        {Object.keys(message?.reply_to || {}).length > 0 && <span className="form-text">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 8.00026V6.41026C12 5.52026 13.08 5.07026 13.71 5.70026L19.3 11.2903C19.69 11.6803 19.69 12.3103 19.3 12.7003L13.71 18.2903C13.08 18.9203 12 18.4803 12 17.5903V16.0003H5C4.45 16.0003 4 15.5503 4 15.0003V9.00026C4 8.45026 4.45 8.00026 5 8.00026H12Z" fill="currentColor" />
          </svg>
          Replied  </span>}
        {message?.message}
      </div>
      <p>
        {moment(message?.created_at).format('hh : mm A')} {message?.starred && <img src={getImage("star-gray.svg")} alt="" />}
      </p>
      <DeleteMessageModal messageId={message?.id} />
    </div >
  );
}

const SenderOutBoundCallComponent = ({ message, senderInfo }: { message?: IMessages, senderInfo: IChatUserInfo }) => {
  return (
    <div className="message-receiver-side" id={`Message-${message?.id}`}>
      <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute ">
        {senderInfo.shortUsername}
      </span>
      <div className="outbond-call">
        <img src={getImage("phone_in_talk.svg")} alt="" /> Outbond Call
        <span> {message?.duration} </span>
      </div>
      <p> {moment(message?.created_at).format('hh : mm A')} </p>
    </div>
  );
}



const SenderMessageComponent = ({ message, senderInfo }: { message?: IMessages, senderInfo: IChatUserInfo }) => {
  switch (message?.message_type) {
    case MESSAGE_TYPE.TEXT:
      return <SenderTextMessageComponent message={message} senderInfo={senderInfo} />
    case MESSAGE_TYPE.OUTBOUND_CALL:
      return <SenderOutBoundCallComponent message={message} senderInfo={senderInfo} />
    default:
      return <></>
  }
}

export default SenderMessageComponent;
