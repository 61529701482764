import { getImage } from "../../../helpers/assets";
import { useAuth } from "../../../hooks/useAuth";
import { useCalling } from "../../../hooks/useCall";
import { clearAllChatsForRoom } from "../../../services/chat";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { initializeChatRoom, selectChatState, selectRoom } from "../../../store/reducers/chat/chatSlice";

const ChatHeaderComponent = () => {
  const dispatch = useAppDispatch();
  const { token, currentUser } = useAuth();
  const { currentRoom } = useAppSelector(selectChatState);
  const { handleCallClick } = useCalling();
  let username: string | undefined;
  let shortUsername;
  let email;
  let phoneNo: string | undefined;
  let userId: number | undefined;
  if (currentRoom?.user.id === currentUser?.id) {
    username = `${currentRoom?.contact?.first_name} ${currentRoom?.contact?.last_name}`;
    shortUsername = `${currentRoom?.contact?.first_name.charAt(0).toUpperCase()}${currentRoom?.contact?.last_name.charAt(0).toUpperCase()}`;  
    email = currentRoom?.contact?.email;
    phoneNo = currentRoom?.contact.phone_no;
    userId = currentRoom?.contact.id;
  } else {
    username = `${currentRoom?.user?.first_name} ${currentRoom?.user?.last_name}`;
    shortUsername = `${currentRoom?.user?.first_name.charAt(0).toUpperCase()}${currentRoom?.user?.last_name.charAt(0).toUpperCase()}`;  
    email = currentRoom?.user?.email;
    phoneNo = currentRoom?.user.phone_no;
    userId = currentRoom?.user.contact?.id;
  }

  const handleClearMessage = () => {
    clearAllChatsForRoom(token, currentRoom?.id).then(response => {
      dispatch(initializeChatRoom());
      dispatch(selectRoom(currentRoom));
    })
  }

  return (
    <ul className="message-sender header-sender ">
      <li>
        <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute ">
          {shortUsername}
        </span>
        <div className="left-details">
          <h4 className=" d-inline-block v-center font-semibold">
            <strong>
              {username}
            </strong>
          </h4>
          <span>{phoneNo}</span>
          <p className="text-nowrap">
            {email}
          </p>
        </div>
        <div className="action-right   ">
          <div className=" d-inline-block list-action">
            <span>
              <img src={getImage("message-text.svg")} alt="" />
            </span>
            <span>
              <img src={getImage("message-email.svg")} alt="" />
            </span>
            <span onClick={() => {
              if (phoneNo && username && userId) {
                handleCallClick(phoneNo, username, userId);
              }
            }}>
              <img src={getImage("message-call.svg")} alt="" />
            </span>
          </div>
          <div className=" dropdown d-inline-block ">
            <span
              className="btn-dots dropdown-toggle d-inline-block"
              id="dropdonw-more"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="btn-moreaction">
                <img src={getImage("icon-more-verticale.svg")} alt="" />
              </div>
            </span>
            <div
              className="dropdown-menu dropdown-menu-right shadow dropdown-items-with-icon"
              aria-labelledby="dropdonw-more"
              style={{}}
            >
              <span
                className="btn-dots dropdown-toggle"

                id="dropdonw-more"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              ></span>
              <span className="dropdown-item" >
                <img src={getImage("icon-contact-info.svg")} alt="" />
                Contact Info
              </span>
              <span className="dropdown-item" >
                <img src={getImage("icon-link.svg")} alt="" /> Media,
                Link, and Docs
              </span>
              <span className="dropdown-item" onClick={handleClearMessage} >
                <img src={getImage("icon-clearmessage.svg")} alt="" />
                Clear Message
              </span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
}

export default ChatHeaderComponent;
