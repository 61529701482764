import { getImage } from "../../../helpers/assets";
import { useAppDispatch } from "../../../store/hooks";

const RenderActiveAppointments = () => {
  const dispatch = useAppDispatch();
  return (
    <div className="card-block uniform-space-b-4 ">
      <div className="accordion" id="accordionnotesAttachments">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingAppointments">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseheadingAppointments" aria-expanded="true" aria-controls="collapseheadingAppointments">
              Active Appointments
            </button>
          </h2>
          <div id="collapseheadingAppointments" className="accordion-collapse collapse show" aria-labelledby="headingAppointments" data-bs-parent="#accordionnotesAttachments">
            <div className="accordion-body">
              <div className=" table-custome">
                <table className="table datatable responsive-mobile-table">
                  <thead>
                    <tr>
                      <th scope="col"> <span> PATIENT NAME </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i>
                      </th>
                      <th scope="col"> <span> REVENUE </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i>
                      </th>
                      <th scope="col"> <span> DATE &amp; TIME </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i>
                      </th>
                      <th scope="col"> <span> LOCATION </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i>
                      </th>
                      <th scope="col"><span> ASSIGNED TO </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-cell="PATIENT NAME"> <span className="text-primary font-semibold">
                        <span className="number-badge badege-primary me-1">
                          MT </span>
                        Mitsue Tollner </span> </td>
                      <td data-cell="REVENUE"> <span className="number-badge badege-success ms-1"> $1,780
                      </span> </td>
                      <td data-cell="LOCATION ">Jun 27, 2022 12:10 PM </td>
                      <td data-cell="Status"> <span className="text-primary font-semibold">
                        BASS Medical Group. Welnut Greek </span> </td>
                      <td data-cell="assigned to"> <span className="number-badge badege-primary ms-1"> Jane
                        Cooper </span> <span className="number-badge badege-primary ms-1"> +2
                        </span> </td>
                    </tr>
                    <tr>
                      <td data-cell="PATIENT NAME"> <span className="text-primary font-semibold">
                        <span className="number-badge badege-primary me-1">
                          MT </span>
                        Mitsue Tollner </span> </td>
                      <td data-cell="REVENUE"> <span className="number-badge badege-success ms-1"> $1,780
                      </span> </td>
                      <td data-cell="LOCATION ">Jun 27, 2022 12:10 PM </td>
                      <td data-cell="Status"> <span className="text-primary font-semibold">
                        BASS Medical Group. Welnut Greek </span> </td>
                      <td data-cell="assigned to"> <span className="number-badge badege-primary ms-1"> Jane
                        Cooper </span> <span className="number-badge badege-primary ms-1"> +2
                        </span> </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderActiveAppointments;
