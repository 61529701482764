import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import graphSelectReducer from "./reducers/graphSelect/graphSelectSlice";
import taskSliceReducer from "./reducers/task/taskSlice";
import emailSliceReducer from "./reducers/email/emailSlice";
import chatSliceReducer from "./reducers/chat/chatSlice";
import callSliceReducer from "./reducers/call/callSlice";
import dashboardSliceReducer from "./reducers/dashboard/dashboardSlice";
import contactSliceReducer from "./reducers/contact/contactSlice";
import userSliceReducer from "./reducers/user/userSlice";


export const store = configureStore({
  reducer: {
    graphSelect: graphSelectReducer,
    task: taskSliceReducer,
    email: emailSliceReducer,
    chat: chatSliceReducer,
    call: callSliceReducer,
    contact: contactSliceReducer,
    user: userSliceReducer,
    dashboard: dashboardSliceReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
