import { useEffect } from "react";
import AuthNavbarComponent from "../../components/AuthNavbarComponent";
import { login, sdk } from "../../integrations/voximplant";
import SignupContainerComponent from "./components/SignupContainer";
import "./index.css";

const SignupComponent = () => {
  console.log(sdk);

  useEffect(() => {
    login().then((anything: any) => {
      console.log(anything);
    }).catch((error: any) => {
      console.log(error);
    });
  }, [])
  return (
    <section className="section-auth">
      <AuthNavbarComponent />
      <SignupContainerComponent />
    </section>
  );
};

export default SignupComponent;
