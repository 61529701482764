import { getImage } from "../helpers/assets";

const AuthPageCommonComponent = () => {
  return (
    <div className="col-xl-5 col-lg-6">
      <div className="left-img-frame relative">
        <div className="rating-block bg-blur absolute d-none">
          <ul>
            <li><img src={getImage("icon-starfilled.svg")} alt="" /></li>
            <li><img src={getImage("icon-starfilled.svg")} alt="" /></li>
            <li><img src={getImage("icon-starfilled.svg")} alt="" /></li>
            <li><img src={getImage("icon-starfilled.svg")} alt="" /></li>
            <li><img src={getImage("icon-starfilled.svg")} alt="" /></li>
          </ul>
          <p>
            This CRM really helps to manage my healthcare business, is
            easy to access, user-friendly and has excellent features.
          </p>
          <div className="d-flex align-items-center media-block">
            <div className="flex-shrink-0">
              <img src={getImage("img-ellipse.png")} alt="..." />
            </div>
            <div className="flex-grow-1 ms-3">
              <h4>Jennifer Williamson</h4>
              <p>Vascular Surgeon</p>
            </div>
          </div>
        </div>
        <img src={getImage("left-img-auth-with-text.png")} alt="" />
      </div>
    </div>
  );
};

export default AuthPageCommonComponent;
