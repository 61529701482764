import classNames from "classnames";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, Location } from "react-router-dom";
import { ESelectedMenu, ESelectedMenuRoutePath } from "../pages/Dashboard/enums";

const SideOtherMenuComponent = () => {
  let navigate = useNavigate();
  let location: Location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState<ESelectedMenu>(ESelectedMenu.DASHBOARD);

  useEffect(() => {
    if (location?.state?.selectedMenu) {
      setSelectedMenu(location.state.selectedMenu)
    }
  }, [location])

  return (
    <div className="side-menu">
      <h3> OTHERS </h3>
      <ul className="nav flex-column">
        <li className="nav-item" onClick={() => {
          navigate(ESelectedMenuRoutePath.INTEGRATIONS, { state: { selectedMenu: ESelectedMenu.INTEGRATIONS }, replace: true });
        }}>
          <div className={
            classNames('nav-link cursor-pointer', {
              'active': selectedMenu === ESelectedMenu.INTEGRATIONS,
            })} aria-current="page" > <i className="icon-help">
              <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.88 16.6C15.88 17.9255 14.8055 19 13.48 19C12.1545 19 11.08 17.9255 11.08 16.6C11.08 15.2745 12.1545 14.2 13.48 14.2C14.8055 14.2 15.88 15.2745 15.88 16.6Z" fill="#AAAAAA" fillOpacity="0.15" />
                <path d="M7.48 10C7.48 11.3255 6.40548 12.4 5.08 12.4C3.75451 12.4 2.68 11.3255 2.68 10C2.68 8.67452 3.75451 7.6 5.08 7.6C6.40548 7.6 7.48 8.67452 7.48 10Z" fill="#AAAAAA" fillOpacity="0.15" />
                <path d="M15.88 3.4C15.88 4.72548 14.8055 5.8 13.48 5.8C12.1545 5.8 11.08 4.72548 11.08 3.4C11.08 2.07452 12.1545 1 13.48 1C14.8055 1 15.88 2.07452 15.88 3.4Z" fill="#AAAAAA" fillOpacity="0.15" />
                <path d="M16.48 16.5368H19M2.68 10L1 10.044M2.68 10C2.68 11.3255 3.75451 12.4 5.08 12.4C6.40548 12.4 7.48 11.3255 7.48 10C7.48 8.67452 6.40548 7.6 5.08 7.6C3.75451 7.6 2.68 8.67452 2.68 10ZM8.16898 10.0441H19M10.801 3.55124L1 3.55124M19 3.55124H16.48M1 16.5368H10.801M15.88 16.6C15.88 17.9255 14.8055 19 13.48 19C12.1545 19 11.08 17.9255 11.08 16.6C11.08 15.2745 12.1545 14.2 13.48 14.2C14.8055 14.2 15.88 15.2745 15.88 16.6ZM15.88 3.4C15.88 4.72548 14.8055 5.8 13.48 5.8C12.1545 5.8 11.08 4.72548 11.08 3.4C11.08 2.07452 12.1545 1 13.48 1C14.8055 1 15.88 2.07452 15.88 3.4Z" stroke="#AAAAAA" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
            </i>
            <span> Integrations </span>
          </div>
        </li>
        <li className="nav-item" onClick={() => {
          navigate(ESelectedMenuRoutePath.SETTINGS, { state: { selectedMenu: ESelectedMenu.SETTINGS }, replace: true });
        }}>
          <div className={
            classNames('nav-link cursor-pointer', {
              'active': selectedMenu === ESelectedMenu.SETTINGS,
            })} aria-current="page" style={{
              paddingLeft: '38px',
            }}> <i className="icon-help"> <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.7517 4.69329C13.7517 3.85839 13.1416 3.14883 12.3161 3.0238C12.1066 2.99207 11.8935 2.99207 11.6839 3.0238C10.8584 3.14883 10.2482 3.85839 10.2482 4.6933V5.87397C9.77662 6.00858 9.32734 6.19618 8.90726 6.42992L8.07205 5.59471C7.48168 5.00435 6.54849 4.93407 5.87637 5.42937C5.70578 5.55509 5.55509 5.70578 5.42937 5.87637C4.93407 6.54849 5.00434 7.4817 5.59471 8.07207L6.42992 8.90728C6.19617 9.32735 6.00857 9.77663 5.87397 10.2483H4.6933C3.85839 10.2483 3.14883 10.8584 3.0238 11.6839C2.99207 11.8935 2.99207 12.1066 3.0238 12.3161C3.14883 13.1416 3.85839 13.7518 4.69329 13.7518H5.87396C6.00857 14.2234 6.19617 14.6727 6.42992 15.0927L5.59471 15.9279C5.00434 16.5183 4.93407 17.4515 5.42937 18.1236C5.55509 18.2942 5.70578 18.4449 5.87638 18.5706C6.5485 19.0659 7.48169 18.9957 8.07205 18.4053L8.90726 17.5701C9.32734 17.8038 9.77662 17.9914 10.2482 18.126V19.3067C10.2482 20.1416 10.8584 20.8512 11.6839 20.9762C11.8934 21.0079 12.1066 21.0079 12.3161 20.9762C13.1416 20.8512 13.7517 20.1416 13.7517 19.3067V18.1261C14.2234 17.9914 14.6727 17.8038 15.0927 17.5701L15.9279 18.4053C16.5183 18.9957 17.4515 19.0659 18.1236 18.5706C18.2942 18.4449 18.4449 18.2942 18.5706 18.1236C19.0659 17.4515 18.9957 16.5183 18.4053 15.928L17.5701 15.0928C17.8038 14.6727 17.9914 14.2234 18.1261 13.7518H19.3067C20.1416 13.7518 20.8512 13.1416 20.9762 12.3161C21.0079 12.1066 21.0079 11.8935 20.9762 11.6839C20.8512 10.8584 20.1416 10.2483 19.3067 10.2483H18.126C17.9914 9.77662 17.8038 9.32734 17.5701 8.90726L18.4053 8.07205C18.9957 7.48168 19.0659 6.54849 18.5706 5.87637C18.4449 5.70578 18.2942 5.55509 18.1236 5.42937C17.4515 4.93407 16.5183 5.00434 15.9279 5.59471L15.0927 6.42992C14.6727 6.19617 14.2234 6.00857 13.7517 5.87396V4.69329Z" fill="#AAAAAA" fillOpacity="0.15" stroke="#AAAAAA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M9.5 12C9.5 10.6193 10.6193 9.5 12 9.5C13.3807 9.5 14.5 10.6193 14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12Z" stroke="#AAAAAA" strokeWidth="1.5" />
            </svg>
            </i>
            <span> Settings </span>
          </div>
        </li>
        <li className="nav-item" onClick={() => {
          navigate(ESelectedMenuRoutePath.HELP_CENTER, { state: { selectedMenu: ESelectedMenu.HELP_CENTER }, replace: true });
        }}>
          <div className={
            classNames('nav-link cursor-pointer', {
              'active': selectedMenu === ESelectedMenu.HELP_CENTER,
            })} aria-current="page"><i className="icon-help">
              <span className=" pathshape path1" />
              <span className="pathshape path2" />
              <span className="pathshape path3" />
              <span className="pathshape path4" />
              <span className="pathshape path5" />
            </i>
            <span> Help Center </span>
          </div>
        </li>
      </ul>
    </div>
  )
}


export default SideOtherMenuComponent;
