import { MESSAGE_TYPE } from "../store/reducers/chat/chatSlice";
import httpClient from "../helpers/httpClient";

export const createRoom = (token: string | null | undefined, userId: string | number) => {
  return httpClient.post(
    `/chat/room/`,
    {
      contact: userId,
    },
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const searchUsersForChat = (token: string | null | undefined, q: string) => {
  return httpClient.get(`/chat/users/?search=${q}`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllChatRooms = (token: string) => {
  return httpClient.get(`/chat/room/`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getStarredMessages = (token: string) => {
  return httpClient.get(`/chat/starred/`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getArchivedChatRooms = (token: string) => {
  return httpClient.get(`/chat/archived/`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};


export const getAllMessagesForRoom = (token: string | null | undefined, roomId: string | undefined) => {
  return httpClient.get(`/chat/room/${roomId}/messages/`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const clearAllChatsForRoom = (token: string | null | undefined, roomId: string | undefined) => {
  return httpClient.delete(`/chat/room/${roomId}/messages/`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const sendMessage = (
  token: string | null | undefined,
  roomId: string | undefined,
  message: string,
  messageType?: MESSAGE_TYPE,
  replyTo?: number
) => {
  return httpClient.post(
    `/chat/room/${roomId}/messages/`,
    {
      message,
      message_type: messageType,
      reply_to: replyTo,
    },
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const sendFileMessage = (
  token: string | null | undefined,
  roomId: string | undefined,
  file: File,
  messageType?: MESSAGE_TYPE,
  replyTo?: number
) => {
  return httpClient.post(
    `/chat/room/${roomId}/messages/file`,
    {
      file,
      type: messageType,
      reply_to: replyTo,
    },
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const deleteMessage = (
  token: string | null | undefined,
  roomId: string | undefined,
  messageId: number | undefined
) => {
  return httpClient.delete(`/chat/room/${roomId}/messages/${messageId}`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const starUnstarMessage = (
  token: string | null | undefined,
  roomId: string | undefined,
  messageId: number | undefined,
  starred: boolean
) => {
  return httpClient.post(
    `/chat/room/${roomId}/messages/${messageId}/star-unstar`,
    {
      starred,
    },
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const forwardMessages = (
  token: string | null | undefined,
  payload: {
    messages?: number[],
    contacts?: string[],
  }
) => {
  return httpClient.post(
    `/chat/forward/`,
    payload,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
