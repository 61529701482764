import { createSlice } from "@reduxjs/toolkit";
import { ACTION_TYPE, EMAIL_LIST_TYPE } from "../../../components/EmailBodyComponent";
import { RootState } from "../../configure";
import { IUserMinDetail } from "../task/taskSlice";

export interface IAttachment {
  id: number;
  filename: string;
  file: string;
  size: number;
  created_at: string;
}

export interface IParticipants {
  email: string;
  name: string;
}

export interface IEmailDetails {
  id?: string;
  content?: string;
  subject?: string;
  to?: IParticipants[];
  fromlist?: IParticipants[];
  cc?: IParticipants[];
  bcc?: IParticipants[];
  created_at?: Date;
  updated_at?: Date;
  last_message_received_timestamp?: Date;
  has_attachments?: boolean;
  snippet?: string;
  read?: boolean;
  starred?: boolean;
  is_draft?: boolean;
  is_deleted?: boolean;
  labels?: any;
}

export interface IEmailDraft {
  body?: string;
  cc?: string[];
  to?: string[];
  bcc?: string[]
  subject?: string;
  attachments?: IAttachment[],
}

export interface IEmailState {
  id?: string;
  body?: string;
  cc?: string[];
  to?: string[];
  bcc?: string[];
  subject?: string;
  showEmailEditor?: boolean;
  attachments?: IAttachment[];
  saveCreateEmailFlag?: boolean;
  isLoading?: boolean;
  isLoaded?: boolean;
  filterType?: EMAIL_LIST_TYPE;
  emails?: IEmailDetails[];
  currentPage?: number;
  emailCount?: number;
  metaInfo?: any;
  showCC?: boolean;
  showBCC?: boolean;
}

export const initialState: IEmailState = {
  body: "",
  cc: [],
  to: [],
  bcc: [],
  subject: "",
  showEmailEditor: false,
  attachments: [],
  saveCreateEmailFlag: false,
  filterType: 'DRAFT' as EMAIL_LIST_TYPE,
  emails: [],
  currentPage: 1,
  showCC: false,
  showBCC: false,
};

export const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    setShowEmailEditor: (state, data) => {
      state.showEmailEditor = data.payload;
    },
    updateEmailTo: (state, data) => {
      state.to = data.payload;
      state.saveCreateEmailFlag = true;
    },
    updateEmailCc: (state, data) => {
      state.cc = data.payload;
      state.saveCreateEmailFlag = true;
    },
    updateEmailBcc: (state, data) => {
      state.bcc = data.payload;
      state.saveCreateEmailFlag = true;
    },
    updateEmailSubject: (state, data) => {
      state.subject = data.payload;
      state.saveCreateEmailFlag = true;
    },
    updateEmailContent: (state, data) => {
      state.body = data.payload;
      state.saveCreateEmailFlag = true;
    },
    addEmailAttachmentToState: (state, data) => {
      if (state.attachments) {
        state.attachments.push(data.payload.attachment);
        state.id = data.payload.email_id;
      }
    },
    removeEmailAttachmentFromState: (state, data) => {
      // TODO: fix remove email attachments
      console.log(state.attachments);
      if (state.attachments) {
        state.attachments = state.attachments.filter(
          (attachment) => attachment.id === data.payload
        );
      }
    },
    starUnStarEmail: (state, data) => {
      const emailId = data.payload.emailId;
      const action = data.payload.action;
      state.emails = state.emails?.map(email => {
        let localEmail = { ...email }
        if (email.id === emailId) {
          localEmail.starred = action === ACTION_TYPE.STAR;
        }
        return localEmail;
      })
    },
    setFilterType: (state, data) => {
      state.filterType = data.payload;
    },
    setEmails: (state, data) => {
      state.emails = data.payload.emails;
      state.emailCount = data.payload.count;
    },
    setCurrentPage: (state, data) => {
      state.currentPage = data.payload;
    },
    setEmailListMeta: (state, data) => {
      state.metaInfo = data.payload;
    },
    resetEmailState: () => initialState,
    setEmailId: (state, data) => {
      state.id = data.payload;
    },
    resetSaveCreateEmailFlag: (state) => {
      state.saveCreateEmailFlag = false;
    },
    updateStarUnstarEmail: (state, data) => {
      const { emailId, action } = data.payload;
    },
    updateIsLoading: (state, data) => {
      state.isLoading = data.payload;
    },
    updateIsLoaded: (state, data) => {
      state.isLoaded = data.payload;
    },
    resetState: (state) => {
      state = initialState;
    },
    setShowCC: (state, data) => {
      state.showCC = data.payload;
    },
    setShowBCC: (state, data) => {
      state.showBCC = data.payload;
    },
    initializeEmail: (state, data) => {
      state.id = data.payload?.id;
      state.body = data.payload?.content || '';
      state.subject = data.payload?.subject || '';
      state.to = data.payload?.to.map((item: IParticipants) => item.email) || [];
      state.cc = data.payload?.cc.map((item: IParticipants) => item.email) || [];
      state.bcc = data.payload?.bcc.map((item: IParticipants) => item.email) || [];
      state.showCC = data.payload?.cc.length > 0;
      state.showBCC = data.payload?.bcc.length > 0
      state.attachments = data.payload?.attachments || [];
    },
  },
});

export const {
  setShowEmailEditor,
  updateEmailBcc,
  updateEmailCc,
  updateEmailTo,
  updateEmailSubject,
  updateEmailContent,
  addEmailAttachmentToState,
  removeEmailAttachmentFromState,
  setEmailId,
  resetSaveCreateEmailFlag,
  initializeEmail,
  resetEmailState,
  updateIsLoading,
  updateIsLoaded,
  setFilterType,
  setEmails,
  setCurrentPage,
  setEmailListMeta,
  starUnStarEmail,
  setShowCC,
  setShowBCC
} = emailSlice.actions;

export const selectEmailState = (state: RootState) => state.email;

export default emailSlice.reducer;
