import classNames from "classnames";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { getImage } from "../../../helpers/assets";
import { useAuth } from "../../../hooks/useAuth";
import { login } from "../../../services/auth";

enum LoginFormEnum {
  email = "email",
  password = "password",
  rememberMe = "rememberMe",
}

export type LoginForm = {
  [LoginFormEnum.email]: string;
  [LoginFormEnum.password]: string;
  [LoginFormEnum.rememberMe]: boolean;
};

const LoginFormConfig = {
  [LoginFormEnum.email]: {
    required: "Please enter your email address",
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: "Please enter a valid email",
    },
  },
  [LoginFormEnum.password]: {
    required: "Please enter password",
  },
  [LoginFormEnum.rememberMe]: {
    required: false,
  },
};

const LoginWithEmailFormComponent = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<LoginForm>();
  const { loginUser } = useAuth();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loginInProgress, setLoginInProgress] = useState<boolean>(false);
  const [serverError, setServerError] = useState();

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const onSubmit: SubmitHandler<LoginForm> = (data: LoginForm) => {
    setLoginInProgress(true);
    login(data).then(response => {
      const { token, user } = response.data;
      loginUser && loginUser(token, user, data.rememberMe);
      window.location.href = "/";
    }).catch((error) => {
      setServerError(error)
      setTimeout(() => {
        setServerError(undefined)
      }, 4000)
    }).finally(() => {
      setLoginInProgress(false);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={
        classNames('form-group uniform-space-b-5', {
          'validate-error': errors.email,
        })}>
        <label className="form-label d-flex justify-content-between">
          &nbsp;Email address&nbsp;
          {errors.email && (
            <span className="error-label">{errors.email.message}</span>
          )}
        </label>
        <div className="control-w-icon">
          <i className="icon-position icon-email absolute v-transform-center" />
          <input
            {...register(
              LoginFormEnum.email,
              LoginFormConfig[LoginFormEnum.email]
            )}
            type="email"
            placeholder="Write you email here..."
            className="styled-control"
          />
        </div>
      </div>
      <div className={
        classNames('form-group uniform-space-b-5', {
          'validate-error': errors.password,
        })}>
        <label className="form-label d-flex justify-content-between">
          &nbsp;Password&nbsp;
          {errors.password && (
            <span className="error-label"> {errors.password.message} </span>
          )}
        </label>
        <div className="control-w-icon">
          <i className="icon-position icon-password absolute v-transform-center" />
          <span className="password-icon absolute right-15 v-transform-center" onClick={handleShowPassword}>
            <img src={getImage("show-password.svg")} alt="show-pwd-icon" />
          </span>
          <input
            type={showPassword ? 'text' : 'password'}
            {...register(
              LoginFormEnum.password,
              LoginFormConfig[LoginFormEnum.password]
            )}
            className="styled-control"
            placeholder="Write you password here..."
          />
        </div>
      </div>
      <div className="form-group d-flex justify-content-between">
        <div className="form-check d-inline-block">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="rememberme"
            {...register(
              LoginFormEnum.rememberMe,
              LoginFormConfig[LoginFormEnum.rememberMe]
            )}
          />
          <label className="form-check-label remember" htmlFor="rememberme">
            Remember me
          </label>
        </div>

        <div className="form-check d-inline-block ms-3">
          <Link className="text-link text-underline" to="/forgot-password">
            <p className="text-link"> Forgot Password? </p>
          </Link>
        </div>
      </div>

      <div className="form-group btn-space-tb-100 mt-5">
        {serverError && <label className="form-label d-flex justify-content-between validate-error">
          <span className="error-label"> <i className="fa fa-exclamation-circle" />&nbsp;&nbsp;Invalid username password, Try again or click Forgot password to reset it.</span>
        </label>}

        <button className="btn btn-primary w-full" disabled={loginInProgress}>
          Login to My account
        </button>

        <p className="text-center mt-4 font-medium">
          Don’t have an account?
          <Link className="text-link text-underline" to="/signup">
            &nbsp;Register&nbsp;
          </Link>
        </p>
      </div>
    </form>
  );
};

export default LoginWithEmailFormComponent;
