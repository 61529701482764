import { useState } from "react";
import { Link } from "react-router-dom";
import { getImage } from "../../../helpers/assets";
import { useAuth } from "../../../hooks/useAuth";
import { addNote, deleteNote } from "../../../services/tasks";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectTaskState, addNoteToState, removeNoteFromState, setDeleteNote } from "../../../store/reducers/task/taskSlice";

const RenderLinkSection = () => {
  const dispatch = useAppDispatch();
  const { token, currentUser } = useAuth();
  const taskDetail = useAppSelector(selectTaskState);
  const { id: taskId, links } = taskDetail;

  // const addNewLink = () => {
  //   if (!taskId || !token || !newLink) return;
  //   addNote(token, taskId, {
  //     message: newNote,
  //     user: currentUser?.id,
  //   }).then((response) => {
  //     dispatch(addNoteToState({
  //       id: response.data.note_id,
  //       message: newNote,
  //       user: currentUser
  //     }));
  //     setNewNote('');
  //   });
  // }

  const handleDeleteLink = (noteId: number | undefined) => {
    dispatch(setDeleteNote(noteId));
  }


  return (
    <div className="card-block uniform-space-b-4 ">
      <div className="accordion" id="accordionnoteslinks">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFive">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseTwo"> Links </button>
          </h2>
          <div id="collapseFive" className="accordion-collapse collapse show" aria-labelledby="headingFive" data-bs-parent="#accordionnoteslinks">
            <div className="accordion-body">
              <ul className="attached-link">
                {links && links.map((link, index) =>
                  <li>
                    <img src={getImage("icon-link.svg")} alt="" />
                    <div onClick={() => {
                      window.open(link.link, "_blank") || window.location.replace(link.link);
                    }}> {link.label} </div>
                  </li>
                )}
              </ul>
              <div className="justify-content-end d-flex">
                <button className="btn btn-outline-primary btn-smaller  min-w-120" data-bs-toggle="modal" data-bs-target="#creatlinkModal"> Add </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderLinkSection;
