import AuthNavbarComponent from "../../components/AuthNavbarComponent";
import ResetPasswordContainer from "./components/ResetPasswordContainer";
import "./index.css";


const ResetPasswordComponent = () => {    
    return (
        <section className="section-auth">
            <AuthNavbarComponent />
            <ResetPasswordContainer />
        </section>
    );
};

export default ResetPasswordComponent;
