import moment from "moment";
import { useEffect } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { getStarredMessages } from "../../../services/chat";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IMessages, selectChatState, selectRoom, setShowStarredMessages, setStarredMessages } from "../../../store/reducers/chat/chatSlice";


const RenderStarredMessage = ({ message }: { message: IMessages }) => {
  const dispatch = useAppDispatch();
  const contact = message.room?.contact;

  const onStarredMessageClicked = async (message: IMessages) => {
    await dispatch(selectRoom(message.room))
    setTimeout(() => {
      const messageEl = document.getElementById(`Message-${message?.id}`);
      const topPos = messageEl?.offsetTop;
      if (topPos) {
        const chatBox = document.getElementsByClassName('chat-box')
        if (chatBox) {
          chatBox[0].scrollTop = topPos - 100
        }
      }
    }, 1000)
  }

  return (
    <ul className="message-sender unread-message star-message" onClick={() => onStarredMessageClicked(message)} >
      <li>
        <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute ">
          {`${contact?.first_name.charAt(0).toUpperCase()}${contact?.last_name.charAt(0).toUpperCase()}`}
        </span>
        <div className="left-details">
          <h4 className=" d-inline-block v-center font-semibold">
            <strong> {`${contact?.first_name} ${contact?.last_name}`} </strong>
            <span className="d-inline-block ms-1"> {moment(message?.created_at).fromNow()} </span>
          </h4>
        </div>
      </li>
      <div className="stared-message-outlook">
        <div className="stared-message  d-inline-block">
          {message?.message}
          <p className="text-right p-0 m-0 text-white">
            <i className="fa fa-star" />
          </p>
        </div>
        <p className="message-time mb-3"> {moment(message?.created_at).format('hh : mm A')} </p>
      </div>
    </ul>
  )
}

const StarredMessagesComponent = () => {
  const dispatch = useAppDispatch();
  const { token } = useAuth();
  const { starredMessages } = useAppSelector(selectChatState);

  useEffect(() => {
    if (token) {
      getStarredMessages(token).then(response => {
        dispatch(setStarredMessages(response.data));
      })
    }
  }, []);

  if (!starredMessages) {
    return <></>
  }
  return (
    <div className="col-xl-4 col-lg-5">
      <div className="card-block message-userlist ">
        <div className="card-header d-flex align-w-icon" onClick={() => dispatch(setShowStarredMessages(false))}>
          <button className="btn btn-action text-primary btn-sm btn-back btn-w-icon font-bold x-space ">
            <i className=" fa fa-angle-left" /> Starred Messaged{" "}
          </button>
        </div>
        {
          starredMessages.map((message: IMessages) => {
            // const username = `${message?.contact?.first_name} ${currentRoom?.contact?.last_name}`;
            return (
              <RenderStarredMessage message={message} key={`starred-message-${message.id}`} />
            )
          })
        }
      </div>
    </div>
  )
};

export default StarredMessagesComponent;
