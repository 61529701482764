export enum ESelectedMenu {
  DASHBOARD = "DASHBOARD",
  TASKS = "TASKS",
  EMAIL = "EMAIL",
  TEXTS = "TEXTS",
  CALLS = "CALLS",
  CONTACTS = "CONTACTS",
  USER_MANAGEMENT = "USER_MANAGEMENT",
  INTEGRATIONS = "INTEGRATIONS",
  SETTINGS = "SETTINGS",
  HELP_CENTER = "HELP_CENTER",
  EMAIL_DETAILS = "EMAIL_DETAILS",
}

export enum ESelectedMenuRoutePath {
  DASHBOARD = "/",
  TASKS = "/tasks",
  EMAIL = "/emails",
  TEXTS = "/texts",
  CALLS = "/calls",
  CONTACTS = "/contacts",
  USER_MANAGEMENT = "/user-management",
  INTEGRATIONS = "/integrations",
  SETTINGS = "/settings",
  HELP_CENTER = "/help-center",
}

export const RouteToMenuMap = {
  [ESelectedMenuRoutePath.DASHBOARD]: ESelectedMenu.DASHBOARD,
  [ESelectedMenuRoutePath.TASKS]: ESelectedMenu.TASKS,
  [ESelectedMenuRoutePath.EMAIL]: ESelectedMenu.EMAIL,
  [ESelectedMenuRoutePath.TEXTS]: ESelectedMenu.TEXTS,
  [ESelectedMenuRoutePath.CALLS]: ESelectedMenu.CALLS,
  [ESelectedMenuRoutePath.CONTACTS]: ESelectedMenu.CONTACTS,
  [ESelectedMenuRoutePath.USER_MANAGEMENT]: ESelectedMenu.USER_MANAGEMENT,
  [ESelectedMenuRoutePath.INTEGRATIONS]: ESelectedMenu.INTEGRATIONS,
  [ESelectedMenuRoutePath.SETTINGS]: ESelectedMenu.SETTINGS,
  [ESelectedMenuRoutePath.HELP_CENTER]: ESelectedMenu.HELP_CENTER,
}



export enum ESelectedGraphCard {
  LEADS = "leads",
  APPOINTMENTS = "appointments",
  SURGERIES = "surgeries",
  REVENUE = "revenue",
  TASKS = "tasks",
}
