import { useRef } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { deleteMessage } from "../../../services/chat";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { deleteMessageFromState, selectChatState } from "../../../store/reducers/chat/chatSlice";

const DeleteMessageModal = ({ messageId }: { messageId: number | undefined }) => {
  const dispatch = useAppDispatch();
  const { token } = useAuth();
  const { client, currentRoom } = useAppSelector(selectChatState);
  const closeRef = useRef<HTMLButtonElement>(null)

  const handleDeleteClick = () => {
    deleteMessage(token, currentRoom?.id, messageId).then((response) => {
      if (client) {
        client.send(
          JSON.stringify({
            action: "DELETE_MESSAGE",
            message: {
              id: messageId
            }
          })
        );
      }
      dispatch(deleteMessageFromState(messageId))
    })
    if (closeRef.current) {
      closeRef.current.click();
    }
  }

  return (
    <div
      className="modal fade"
      id={`deleteChatModal${messageId}`}
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered  ">
        <div className="modal-content ">
          <div className="modal-body text-center">
            <h3> Would you like to delete this message? </h3>
            <div className="form-group  mb-xl-3  mb-2 text-center btn-space  ">
              <button
                type="button"
                className="btn btn-dark border-0 btn-sm w-140 "
                data-bs-dismiss="modal"
                ref={closeRef}
                onClick={() => {
                  $(`deleteChatModal${messageId}`).modal('hide');
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger border-0 btn-sm w-140 "
                onClick={handleDeleteClick}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
export default DeleteMessageModal;
