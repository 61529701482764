import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../configure";

export interface IContactDetail {
  id?: number;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  email?: string;
  tags?: string[];
  phone_no?: string;
  doctor_name?: string;
  insurance?: string;
  medical_report_no?: string;
  month_schedule?: string;
  lead_in?: string;
  zip_code?: string;
  user?: number;
}

export interface contactState {
  id?: number;
  first_name?: string;
  full_name?: string;
  last_name?: string;
  email?: string;
  tags?: string[];
  phone_no?: string;
  doctor_name?: string;
  insurance?: string;
  medical_report_no?: string;
  month_schedule?: string;
  lead_in?: string;
  zip_code?: string;
  isLoading?: boolean;
}

export const initialState: contactState = {
  first_name: "",
  last_name: "",
  email: "",
  tags: [],
  phone_no: "",
  isLoading: false,
};

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    addTag: (state, data) => {
      if (state?.tags && !state.tags.includes(data.payload)) {
        state?.tags.push(data.payload);
      }
    },
    removeLastTag: (state) => {
      if (state?.tags) {
        state?.tags.pop();
      }
    },
    updateIsLoading: (state, data) => {
      state.isLoading = data.payload;
    },
    resetState: (state) => {
      state = initialState;
    },
    updateGeneralInformation: (state, data) => {
      if (Object.keys(data.payload).includes('first_name')) {
        state.first_name = data.payload.first_name;
      }
      if (Object.keys(data.payload).includes('last_name')) {
        state.last_name = data.payload.last_name;
      }
      if (Object.keys(data.payload).includes('email')) {
        state.email = data.payload.email;
      }
      if (Object.keys(data.payload).includes('phone_no')) {
        state.phone_no = data.payload.phone_no;
      }
    },
    updateAdditionalInformation: (state, data) => {
      if (Object.keys(data.payload).includes('doctor_name')) {
        state.doctor_name = data.payload.doctor_name;
      }
      if (Object.keys(data.payload).includes('insurance')) {
        state.insurance = data.payload.insurance;
      }
      if (Object.keys(data.payload).includes('medical_report_no')) {
        state.medical_report_no = data.payload.medical_report_no;
      }
      if (Object.keys(data.payload).includes('month_schedule')) {
        state.month_schedule = data.payload.month_schedule;
      }
      if (Object.keys(data.payload).includes('lead_in')) {
        state.lead_in = data.payload.lead_in;
      }
      if (Object.keys(data.payload).includes('zip_code')) {
        state.zip_code = data.payload.zip_code;
      }
    },
    initializeContact: (state, data) => {
      state.id = data.payload.id;
      state.tags = data.payload.tags;
      state.first_name = data.payload.first_name;
      state.last_name = data.payload.last_name;
      state.email = data.payload.email;
      state.phone_no = data.payload.phone_no;
      state.doctor_name = data.payload.doctor_name;
      state.insurance = data.payload.insurance;
      state.medical_report_no = data.payload.medical_report_no;
      state.month_schedule = data.payload.month_schedule;
      state.lead_in = data.payload.lead_in;
      state.zip_code = data.payload.zip_code;
    },
  },
});

export const {
  addTag,
  removeLastTag,
  initializeContact,
  resetState,
  updateGeneralInformation,
  updateAdditionalInformation,
} = contactSlice.actions;

export const selectContactState = (state: RootState) => state.contact;

export default contactSlice.reducer;
