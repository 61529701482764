import classNames from "classnames";
import { useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAuth } from "../../../hooks/useAuth";
import { addLink } from "../../../services/tasks";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { addLinkToState, selectTaskState } from "../../../store/reducers/task/taskSlice";

enum AddLinkFormEnum {
  label = "label",
  description = "description",
  link = "link",
}

export type AddLinkForm = {
  [AddLinkFormEnum.label]: string;
  [AddLinkFormEnum.description]: string;
  [AddLinkFormEnum.link]: string;
  user?: number;
};

const AddLinkFormConfig = {
  [AddLinkFormEnum.label]: {
    required: "Please enter label",
  },
  [AddLinkFormEnum.description]: {
    required: "Please enter description",
  },
  [AddLinkFormEnum.link]: {
    required: "Please add link",
  },
};

const AddLinkModal = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddLinkForm>();
  const dispatch = useAppDispatch();
  const { id } = useAppSelector(selectTaskState);
  const { token, currentUser } = useAuth();
  const closeRef = useRef<HTMLButtonElement>(null)

  const onSubmit: SubmitHandler<AddLinkForm> = (data: AddLinkForm) => {
    if (token && id && currentUser) {
      data['user'] = currentUser?.id
      addLink(token, id, data).then((response) => {
        dispatch(addLinkToState(response.data))
        if (closeRef.current) {
          closeRef.current.click();
        }
      });
    };
  }

  return (
    <div className="modal fade" id="creatlinkModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered  diloge-650  ">
        <div className="modal-content  ">
          <div className="modal-body ">
            <h3 className="text-center"> Create New Link </h3>
            <div className={
              classNames('form-group small-form', {
                'validate-error': errors.label,
              })}>
              <label> Label
                <br />
                {errors.label && (
                  <span className="error-label">{errors.label.message}</span>
                )}
              </label>
              <input
                {...register(
                  AddLinkFormEnum.label,
                  AddLinkFormConfig[AddLinkFormEnum.label]
                )}
                type="text" placeholder=" link" className="styled-control control-sm" />
            </div>
            <div className={
              classNames('form-group small-form', {
                'validate-error': errors.description,
              })}>
              <label> Description
                <br />
                {errors.description && (
                  <span className="error-label">{errors.description.message}</span>
                )}
              </label>
              <input
                {...register(
                  AddLinkFormEnum.description,
                  AddLinkFormConfig[AddLinkFormEnum.description]
                )}
                type="text" placeholder="Description" className="styled-control control-sm" />
            </div>
            <div className={
              classNames('form-group small-form', {
                'validate-error': errors.link,
              })}>
              <label> Build Your Link
                <br />
                {errors.link && (
                  <span className="error-label">{errors.link.message}</span>
                )}
              </label>
              <input
                {...register(
                  AddLinkFormEnum.link,
                  AddLinkFormConfig[AddLinkFormEnum.link]
                )}
                type="text" placeholder="" className="styled-control control-sm" />
            </div>
            <div className="form-group mt-xl-5  mb-xl-3  mb-2 justify-content-end  text-right btn-space  ">
              <button type="button" ref={closeRef} className="btn btn-outline-primary  btn-sm  min-w-10" data-bs-dismiss="modal">Cancel </button>
              <button type="button" className="btn btn-primary btn-sm min-w-100 " onClick={handleSubmit(onSubmit)}>Attach </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddLinkModal;
