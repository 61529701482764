import { useEffect } from "react";
import { useAuth } from "../../../hooks/useAuth";
import usePrevious from "../../../hooks/usePrevious";
import { updateContact } from "../../../services/contacts";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectContactState, IContactDetail, updateAdditionalInformation } from "../../../store/reducers/contact/contactSlice";

const RenderAdditionalDetail = () => {
  const dispatch = useAppDispatch();
  const { token } = useAuth();
  const contactDetail = useAppSelector(selectContactState);

  const { id, phone_no, doctor_name, insurance, medical_report_no, month_schedule, lead_in, zip_code } = contactDetail;
  const previousPhoneNo = usePrevious(phone_no);

  useEffect(() => {
    if (!previousPhoneNo) return;
    if (!token) return;
    let payload: IContactDetail = {
      doctor_name, insurance, medical_report_no, month_schedule, lead_in, zip_code
    };
    let updatedPayload: any;
    Object.entries(payload).forEach((item) => {
      const [key, value] = item;
      if (value) {
        updatedPayload = {
          ...updatedPayload,
          [key]: value,
        };
      }
    });

    if (id && token && updatedPayload) {
      updateContact(token, id, updatedPayload);
    };
  }, [doctor_name, insurance, medical_report_no, month_schedule, lead_in, zip_code])

  if (!phone_no) {
    return <>Loading...</>
  }

  return (
    <div className="card-block uniform-space-b-4 mb-4">
      <div className="accordion" id="accordionTaskInformation">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseOne">
              Additional Detail
            </button>
          </h2>
          <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionTaskInformation">
            <div className="accordion-body">
              <div className="form-group row align-items-center uniform-space-b-5">
                <div className="col-sm-4 ">
                  <label className="form-label ">
                    Doctor
                    <span className="d-block sub-label"> This will be displayed on
                      This will be displayed on your profile
                    </span>
                  </label>
                </div>
                <div className="col-sm-8">
                  <input type="text" className="styled-control control-sm"
                    onChange={(e) => {
                      dispatch(updateAdditionalInformation({
                        doctor_name: e.target.value
                      }))
                    }}
                    key={`${doctor_name} ${Math.random()}`}
                    value={doctor_name}
                  />
                </div>
              </div>
              <div className="form-group row align-items-center uniform-space-b-5">
                <div className="col-sm-4 ">
                  <label className="form-label ">
                    Insurance
                    <span className="d-block sub-label"> This will be displayed on
                      your profile</span>
                  </label>
                </div>
                <div className="col-sm-8">
                  <input type="text" className="styled-control control-sm"
                    onChange={(e) => {
                      dispatch(updateAdditionalInformation({
                        insurance: e.target.value
                      }))
                    }}
                    key={`${insurance} ${Math.random()}`}
                    value={insurance}
                  />
                </div>
              </div>
              <div className="form-group row align-items-center uniform-space-b-5">
                <div className="col-sm-4 ">
                  <label className="form-label ">
                    Medical Record Number
                    <span className="d-block sub-label"> This will be displayed on
                      your profile</span>
                  </label>
                </div>
                <div className="col-sm-8">
                  <input type="text" className="styled-control control-sm"
                    onChange={(e) => {
                      dispatch(updateAdditionalInformation({
                        medical_report_no: e.target.value
                      }))
                    }}
                    key={`${medical_report_no} ${Math.random()}`}
                    value={medical_report_no}
                  />
                </div>
              </div>
              <div className="form-group row align-items-center uniform-space-b-5">
                <div className="col-sm-4 ">
                  <label className="form-label ">
                    Month Schedule
                    <span className="d-block sub-label"> This will be displayed on
                      your profile</span>
                  </label>
                </div>
                <div className="col-sm-8">
                  <input type="date"
                    // className="styled-control control-sm"
                    onChange={(e) => {
                      dispatch(updateAdditionalInformation({
                        month_schedule: e.target.value
                      }))
                    }}
                    key={`${month_schedule} ${Math.random()}`}
                    value={month_schedule && new Date(month_schedule).toISOString().substring(0, 10)}
                  />
                </div>
              </div>
              <div className="form-group row align-items-center uniform-space-b-5">
                <div className="col-sm-4 ">
                  <label className="form-label ">
                    Lead In
                    <span className="d-block sub-label"> This will be displayed on
                      your profile</span>
                  </label>
                </div>
                <div className="col-sm-8">
                  <input
                    type="date"
                    // className="styled-control control-sm"
                    onChange={(e) => {
                      dispatch(updateAdditionalInformation({
                        lead_in: e.target.value
                      }))
                    }}
                    key={`${lead_in} ${Math.random()}`}
                    value={lead_in && new Date(lead_in).toISOString().substring(0, 10)}
                  />
                </div>
              </div>
              <div className="form-group row align-items-center uniform-space-b-5">
                <div className="col-sm-4 ">
                  <label className="form-label ">
                    Zip Code
                    <span className="d-block sub-label"> This will be displayed on
                      your profile</span>
                  </label>
                </div>
                <div className="col-sm-8">
                  <input type="text" className="styled-control control-sm"
                    onChange={(e) => {
                      dispatch(updateAdditionalInformation({
                        zip_code: e.target.value
                      }))
                    }}
                    key={`${zip_code} ${Math.random()}`}
                    value={zip_code}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderAdditionalDetail;
