import { useEffect, useRef, useState } from "react";
import makeAnimated from "react-select/animated";
import AsyncSelect from "react-select/async";
import { useAuth } from "../../../hooks/useAuth";
import { forwardMessages, searchUsersForChat } from "../../../services/chat";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IChatUserInfo, selectChatState, updateMessageSelector, updateRefreshChat } from "../../../store/reducers/chat/chatSlice";
import { IOption } from "../../TaskDetails/components/renderTaskInformation";
import RecieverMessageComponent from "./ReceiverMessageComponent";
import SenderMessageComponent from "./SenderMessageComponent";


const ForwardMessageModal = () => {
  const dispatch = useAppDispatch();
  const { token } = useAuth();
  const [query, setQuery] = useState("");
  const closeRef = useRef<HTMLButtonElement>(null)
  const [selectedUsers, setSelectedUsers] = useState<IOption[]>();
  const [isForwarded, setIsForwarded] = useState<boolean>(false);
  const { selectedMessages } = useAppSelector(selectChatState);
  const animatedComponents = makeAnimated()

  const asyncSelectStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: '10px',
      borderColor: '#E7E7E7',
      padding: '6px',
      marginBottom: '10px',
      background: 'rgba(245, 247, 251, 0.2)',
    }),
  }

  const loadOptions = (inputValue: any, callback: any) => {
    return searchUsersForChat(token, query).then((response) => callback(response.data.results));
  };

  const handleForwardSend = () => {
    forwardMessages(token, {
      messages: selectedMessages,
      contacts: selectedUsers?.map(option => option.value)
    }).then((response) => {
      setIsForwarded(true);
      if (closeRef.current) {
        closeRef.current.click();
      }
      setTimeout(() => {
        if (closeRef.current) {
          closeRef.current.click();
        }
        setIsForwarded(false)
        dispatch(updateMessageSelector(false));
        dispatch(updateRefreshChat(true));
      }, 1000);
    }).catch(() => {
      if (closeRef.current) {
        closeRef.current.click();
      }
      setIsForwarded(false)
      dispatch(updateMessageSelector(false));
      dispatch(updateRefreshChat(true));
    })
  }

  return (
    <div className="modal fade" id="selectedMessagesModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered  ">
        <div className="modal-content ">
          <div className="modal-body ">
            {isForwarded ? (
              <h3 className="formward-modal"> Message Forwarded Successfully </h3>
            ) : (
              <>
                <h3 className="formward-modal"> <img src="images/icon-close-dark.png" alt="" /> Forward message to</h3>
                <AsyncSelect
                  isMulti
                  cacheOptions
                  styles={asyncSelectStyles}
                  components={animatedComponents}
                  getOptionLabel={(e: IOption) => {
                    return e?.label;
                  }}
                  getOptionValue={(e: IOption) => {
                    return e?.value;
                  }}
                  loadOptions={loadOptions}
                  defaultValue={null}
                  onInputChange={(value) => setQuery(value)}
                  onChange={(value: any) => {
                    setSelectedUsers(value);
                  }}
                />
                {/* 
                  <div className="searcharea ">
                  <i className="v-transform-center"> <img src="images/icon-search.svg" alt="" /> </i>
                      <input type="text" className="input-control" placeholder="Search keyword..." aria-label="Search" aria-describedby="basic-addon2" />
                  </div>
                  <ul className="forward-list">
                    <li className="form-check">
                      <input className="form-check-input" type="checkbox" id="flexCheckChecked" defaultChecked />
                      <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute ">
                        SM </span>    <label className="form-check-label" htmlFor="flexCheckChecked">
                        Simmon Moraska
                      </label>
                    </li>
                    <li className="form-check">
                      <input className="form-check-input" type="checkbox" id="flexCheckChecked2" defaultChecked />
                      <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute ">
                        SM </span>    <label className="form-check-label" htmlFor="flexCheckChecked2">
                        Simmon Moraska
                      </label>
                    </li>
                    <li className="form-check">
                      <input className="form-check-input" type="checkbox" id="flexCheckChecked3" defaultChecked />
                      <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute ">
                        SM </span>    <label className="form-check-label" htmlFor="flexCheckChecked3">
                        Simmon Moraska
                      </label>
                    </li>
                    <li className="form-check">
                      <input className="form-check-input" type="checkbox" id="flexCheckChecked4" defaultChecked />
                      <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute ">
                        SM </span>    <label className="form-check-label" htmlFor="flexCheckChecked4">
                        Simmon Moraska
                      </label>
                    </li>
                    <li className="form-check">
                      <input className="form-check-input" type="checkbox" id="flexCheckChecked5" defaultChecked />
                      <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute ">
                        SM </span>    <label className="form-check-label" htmlFor="flexCheckChecked5">
                        Simmon Moraska
                      </label>
                    </li>
                  </ul>
                 */}
                <div className="form-group  mb-xl-3  mb-2 justify-content-end  text-right btn-space  ">
                  <button type="button" ref={closeRef} className="btn btn-outline-primary  btn-sm  w-40" data-bs-dismiss="modal">Cancel </button>
                  <button className="btn btn-primary btn-sm  w-40" onClick={handleForwardSend}> Send </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForwardMessageModal;
