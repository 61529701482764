import AuthPageCommonComponent from "../../../components/AuthPageCommonComponent";
import LoginWithEmailFormComponent from "./LoginWithEmailForm";

const LoginContainerComponent = () => {
  return (
    <div className="container-fulid content-space">
      <div className="row">
        <AuthPageCommonComponent />
        <div className="col-xl-7 col-lg-6 d-flex justify-content-center login-form-box">
          <div className="align-form-center">
            <h2 className="section-title"> Welcome Back!</h2>
            <p className="sub-title uniform-space-b-5 "> Let’s go! Login to your account to started </p>
            <LoginWithEmailFormComponent />
            <div className="copyright-text">
              <p>© 2022 Patient10X. All right reserved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginContainerComponent;
