import AuthNavbarComponent from "../../components/AuthNavbarComponent";
import LoginContainerComponent from "./components/LoginContainer";
import "./index.css";


const LoginComponent = () => {    
    return (
        <section className="section-auth">
            <AuthNavbarComponent />
            <LoginContainerComponent />
        </section>
    );
};

export default LoginComponent;
