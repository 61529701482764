import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LogoComponent from "../../components/LogoComponent";
import NavbarComponent from "../../components/NavbarComponent";
import SideMainMenuComponent from "../../components/SideMainMenuComponent";
import SideOtherMenuComponent from "../../components/SideOtherMenuComponent";
import { getImage } from "../../helpers/assets";
import { useAuth } from "../../hooks/useAuth";
import { getContactDetail } from "../../services/contacts";
import { useAppDispatch } from "../../store/hooks";
import { initializeContact } from "../../store/reducers/contact/contactSlice";
import { updateIsLoading } from "../../store/reducers/email/emailSlice";
import { ESelectedMenu } from "../Dashboard/enums";
import RenderActiveAppointments from "./components/renderActiveAppointments";
import RenderActiveSurgeries from "./components/renderActiveSurgeries";
import RenderAdditionalDetail from "./components/renderAdditionalDetail";
import RenderCommunicationsList from "./components/renderCommunicationsList";
import RenderContactsTabs from "./components/renderContactsTabs";
import RenderFilesList from "./components/renderFilesList";
import RenderGeneralInformation from "./components/renderGeneralInformation";
import RenderMainSection from "./components/renderMainSection";
import RenderPastAppointments from "./components/renderPastAppointments";
import RenderRelatedCalls from "./components/renderRelatedCalls";
import RenderRelatedEmails from "./components/renderRelatedEmails";
import RenderRelatedTasks from "./components/renderRelatedTasks";
import RenderRevenueBreakdown from "./components/renderRevenueBreakdown";
import "./index.css";

const RenderContactDetailForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { token } = useAuth();
  const { contactId } = useParams();
  const [serverError, setServerError] = useState<AxiosError>();

  useEffect(() => {
    if (contactId && token) {
      dispatch(updateIsLoading(true));
      getContactDetail(token, contactId).then((response) => {
        dispatch(updateIsLoading(false));
        dispatch(initializeContact(response.data));
      }).catch((error) => {
        dispatch(updateIsLoading(false));
        setServerError(error);
      });
    }
  }, [contactId, token]);

  if (serverError) {
    return <h1>Something went wrong</h1>
  }

  return (
    <div className="form-content-area">
      <div className="row uniform-space-b-4 align-items-center group-control">
        <div className="col-xl-2 task-all">
          <div className="card-block">
            <button className="btn-link btn-all-task" style={{ padding: 0 }} onClick={() => {
              navigate('/contacts', { state: { selectedMenu: ESelectedMenu.CONTACTS }, replace: true });
            }}> <img src={getImage("arrow-left.svg")} alt="" /> <span>
                All Contacts</span> </button>
          </div>
        </div>
        <RenderMainSection />
      </div>
      <RenderContactsTabs />
      <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade show active" id="contactInfo" role="tabpanel" aria-labelledby="contactInfo-tab">
          <RenderGeneralInformation />
          <RenderAdditionalDetail />
          <RenderRelatedTasks />
          <RenderRelatedEmails />
          <RenderRelatedCalls />
        </div>
        <div className="tab-pane fade" id="appointments" role="tabpanel" aria-labelledby="appointments-tab">
          <div className=" fixed-bottom ">
            <button className="btn btn-primary d-inline-block btn-shadow"> <i className="fa fa-plus" /> Create a new appointmnet</button>
          </div>
          <RenderPastAppointments />
        </div>
        <div className="tab-pane fade" id="surgeries" role="tabpanel" aria-labelledby="surgeries-tab">
          <div className=" fixed-bottom ">
            <button className="btn btn-primary d-inline-block btn-shadow"> <i className="fa fa-plus" /> Create a new appointmnet</button>
          </div>
          <RenderActiveAppointments />
          <RenderActiveSurgeries />
        </div>
        <div className="tab-pane fade" id="revenue" role="tabpanel" aria-labelledby="revenue-tab">
          <div className="card-block uniform-space-b-4 text-center ">
            <h3 className="text-primary"> $ 12,450 </h3>
            <p> TOTOAL REVENUE</p>
          </div>
          <RenderRevenueBreakdown />
        </div>
        <div className="tab-pane fade" id="tasks" role="tabpanel" aria-labelledby="tasks-tab">
          <div className=" fixed-bottom ">
            <button className="btn btn-primary d-inline-block btn-shadow"> <i className="fa fa-plus" /> Create a new appointmnet</button> </div>
          <RenderActiveAppointments />
          <RenderActiveSurgeries />
        </div>
        <div className="tab-pane fade" id="communications" role="tabpanel" aria-labelledby="communications-tab">
          <div className="card-block uniform-space-b-4 text-center ">
            <h3 className="text-primary">78 </h3>
            <p> TOUCH POINT </p>
          </div>
          <RenderCommunicationsList />
        </div>
        <div className="tab-pane fade" id="files" role="tabpanel" aria-labelledby="files-tab">
          <RenderFilesList />
        </div>
      </div>
    </div>
  );
}

const ContactDetailsComponent = () => {
  return <div className="wrapper bg-base view-full">
    <div className="d-flex">
      <div className="sidebar bg-white">
        <LogoComponent />
        <SideMainMenuComponent />
        <SideOtherMenuComponent />
      </div>
      <div className="right-part">
        <NavbarComponent />
        <RenderContactDetailForm />
      </div>
    </div>
  </div>
};

export default ContactDetailsComponent;
