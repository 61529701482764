import { useRef, useState } from "react";
import { getImage } from "../../../helpers/assets";
import { useAuth } from "../../../hooks/useAuth";
import { uploadAttachment } from "../../../services/tasks";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { addAttachmentToState, selectTaskState } from "../../../store/reducers/task/taskSlice";

const AddAttachmentModal = () => {
  const dispatch = useAppDispatch();
  const { id } = useAppSelector(selectTaskState);

  const { token, currentUser } = useAuth();
  const [file, setFile] = useState<FileList | null>();
  const closeRef = useRef<HTMLButtonElement>(null)

  const uploadFile = async (): Promise<void> => {
    if (token && file && id && currentUser) {
      const response = await uploadAttachment(token, id, {
        file: file[0],
        user: currentUser.id
      });
      dispatch(addAttachmentToState(response.data))
      return Promise.resolve();
    }
    return Promise.resolve()
  };

  return (
    <div className="modal fade" id="attachedModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered  diloge-650  ">
        <div className="modal-content  ">
          <div className="modal-body text-center">
            <h3> Attach File </h3>
            <form className="box" method="post" encType="multipart/form-data">
              <div className="box__input">
                <img src={getImage("uploadicon.png")} alt="" />
                <p> Click here or drop files here to attach. </p>
                <input className="box__file" type="file" name="files[]" id="file" data-multiple-caption="{count} files selected" onChange={(e) => {
                  setFile(e.target.files);
                }} />
              </div>
            </form>
            <div className="form-group  mb-xl-3  mb-2 justify-content-end  text-right btn-space  ">
              <button type="button" ref={closeRef} className="btn btn-outline-primary  btn-sm  min-w-10" data-bs-dismiss="modal">Cancel </button>
              <button type="button" className="btn btn-primary btn-sm min-w-100 " onClick={async () => {
                if (file) {
                  await uploadFile();
                }
                if (closeRef.current) {
                  closeRef.current.click();
                }
              }}>Attach </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAttachmentModal;
