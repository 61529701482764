import { E164Number } from "libphonenumber-js/types";
import { useCallback, useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useAuth } from "../../../hooks/useAuth";
import usePrevious from "../../../hooks/usePrevious";
import { updateContact } from "../../../services/contacts";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IContactDetail, selectContactState, updateGeneralInformation } from "../../../store/reducers/contact/contactSlice";

const RenderGeneralInformation = () => {
  const dispatch = useAppDispatch();
  const { token } = useAuth();
  const contactDetail = useAppSelector(selectContactState);
  const { id, first_name, last_name, phone_no, email } = contactDetail;
  const previousPhoneNo = usePrevious(phone_no);

  useEffect(() => {
    if (!previousPhoneNo) return;
    if (!token) return; const payload: IContactDetail = {
      first_name,
      last_name,
      email,
      phone_no,
    };
    if (id && token) {
      updateContact(token, id, payload).then((response) => {
      }).catch((error) => {
      });
    };
  }, [first_name, last_name, email, phone_no])

  if (!phone_no) {
    return <>Loading...</>
  }

  return (
    <div className="card-block uniform-space-b-4 mb-4">
      <div className="accordion" id="accordionTaskInformation">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              General Information
            </button>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionTaskInformation">
            <div className="accordion-body">
              <div className="form-group row align-items-center uniform-space-b-5">
                <div className="col-sm-4 ">
                  <label className="form-label ">
                    First Name
                    <span className="d-block sub-label"> This will be displayed on
                      your profile
                    </span>
                  </label>
                </div>
                <div className="col-sm-8"
                >
                  <input type="text" className="styled-control control-sm" placeholder="Full Name"
                    onChange={(e) => {
                      dispatch(updateGeneralInformation({
                        first_name: e.target.value
                      }))
                    }}
                    value={first_name}
                  />
                </div>
              </div>
              <div className="form-group row align-items-center uniform-space-b-5">
                <div className="col-sm-4 ">
                  <label className="form-label ">
                    Last Name
                    <span className="d-block sub-label"> This will be displayed on
                      your profile
                    </span>
                  </label>
                </div>
                <div className="col-sm-8"> <input type="text" className="styled-control control-sm" placeholder="Full Name"
                  onChange={(e) => {
                    dispatch(updateGeneralInformation({
                      last_name: e.target.value
                    }))
                  }}
                  value={last_name}
                />
                </div>
              </div>
              <div className="form-group row align-items-center uniform-space-b-5">
                <div className="col-sm-4 ">
                  <label className="form-label ">
                    Email
                    <span className="d-block sub-label"> This will be displayed on
                      your profile</span>
                  </label>
                </div>
                <div className="col-sm-8">
                  <input type="email" className="styled-control control-sm" placeholder="Write you email here..."
                    onChange={(e) => {
                      dispatch(updateGeneralInformation({
                        email: e.target.value
                      }))
                    }}
                    key={`${email} ${Math.random()}`}
                    value={email}
                  />
                </div>
              </div>
              <div className="form-group row align-items-center uniform-space-b-5">
                <div className="col-sm-4 ">
                  <label className="form-label ">
                    Phone Number
                    <span className="d-block sub-label"> This will be displayed on
                      your profile
                    </span>
                  </label>
                </div>
                <div className="col-sm-8">
                  <div className="phone-code">
                    <PhoneInput
                      className="styled-control control-sm"
                      onChange={(value) => {
                        dispatch(updateGeneralInformation({
                          phone_no: value
                        }))
                      }}
                      key={`${phone_no} ${Math.random()}`}
                      value={phone_no as E164Number}
                      defaultCountry="IN"
                      placeholder="Enter Phone Number"
                      id="phone_no"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderGeneralInformation;
