import classNames from 'classnames';
import codes from 'country-calling-code';
import { useEffect, useRef } from 'react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { useAuth } from '../hooks/useAuth';
import { addContact } from '../services/contacts';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { ESelectedMenu, ESelectedMenuRoutePath } from '../pages/Dashboard/enums';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

enum AddContactFormEnum {
  phoneNo = "phone_no",
  name = "name",
  email = "email",
}

export type AddContactForm = {
  [AddContactFormEnum.phoneNo]: string;
  [AddContactFormEnum.name]: string;
  [AddContactFormEnum.email]: string;
};

const AddContactFormConfig = {
  [AddContactFormEnum.phoneNo]: {
    required: "Please enter phone number",
  },
  [AddContactFormEnum.name]: {
    required: "Please enter name",
  },
};

const ContactCreateModal = () => {
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm<AddContactForm>();
  const { token, currentUser } = useAuth();
  const closeRef = useRef<HTMLButtonElement>(null)

  const onSubmit: SubmitHandler<AddContactForm> = (data: AddContactForm) => {
    if (token && currentUser) {
      addContact(token, data).then((response) => {
        if (closeRef.current) {
          closeRef.current.click();
        }
        const { contact } = response.data;
        navigate(`${ESelectedMenuRoutePath.CONTACTS}/detail/${contact.id}`, { state: { selectedMenu: ESelectedMenu.CONTACTS }, replace: true });
      }).catch((error: AxiosError) => {
        const status = error.response?.status;
        if (error.code === "ERR_BAD_REQUEST" && status === 400) {
          const data: { [key: string]: any } = error.response?.data as { [key: string]: any };
          if (data) {
            Object.keys(data).map(key => {
              let message = data[key][0];
              message = message.replaceAll('.', '');
              message = message[0].toUpperCase() + message.slice(1);
              setError(key as AddContactFormEnum, {
                message
              });
            })
          }
        }
      });
    };
  }

  return (
    <div className="modal fade" id="creat-contact" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered diloge-650  ">
        <div className="modal-content">
          <div className="modal-body ">
            <h3 className="text-center mb-2"> Create a New Contact </h3>
            <p className="text-center text-gray-light"> The new contact will automatically be saved in your <br /> contact library. </p>
            <div className={
              classNames('form-group small-form mt-5', {
                'validate-error': errors.phone_no,
              })}>
              <label> Phone Number
                <br />
                {errors.phone_no && (
                  <span className="error-label">{errors.phone_no.message}</span>
                )}
              </label>
            </div>
            <Controller
              control={control}
              name={AddContactFormEnum.phoneNo}
              rules={AddContactFormConfig[AddContactFormEnum.phoneNo]}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  className="styled-control2 control-sm"
                  value={value}
                  onChange={onChange}
                  defaultCountry="IN"
                  placeholder="Enter Phone Number"
                  id={AddContactFormEnum.phoneNo}
                />
              )}
            />
            <div className={
              classNames('form-group small-form mt-4', {
                'validate-error': errors.name,
              })}>
              <label> Name
                <br />
                {errors.name && (
                  <span className="error-label">{errors.name.message}</span>
                )}
              </label>
              <input type="text" placeholder="Enter name" className="styled-control2 control-sm"
                {...register(
                  AddContactFormEnum.name,
                  AddContactFormConfig[AddContactFormEnum.name]
                )}
              />
            </div>
            <div className={
              classNames('form-group small-form', {
                'validate-error': errors.email,
              })}>
              <label> Email
                <br />
                {errors.email && (
                  <span className="error-label">{errors.email.message}</span>
                )}
              </label>
              <input type="email" placeholder="Enter email address" className="styled-control2 control-sm" {...register(
                AddContactFormEnum.email,
              )} />
            </div>
            <div className="form-group mt-xl-5  mb-xl-3  mb-2 justify-content-end  text-right btn-space row  ">
              <div className="col-sm-6">
                <button type="button" ref={closeRef} className="btn btn-outline-primary  btn-sm  w-full" data-bs-dismiss="modal">Cancel </button> </div>
              <div className="col-sm-6">   <button type="button" className="btn btn-primary btn-sm w-full " onClick={handleSubmit(onSubmit)}>Add </button> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default ContactCreateModal;
