import { useEffect, useRef } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { useAppSelector } from "../../../store/hooks";
import { IChatUserInfo, selectChatState } from "../../../store/reducers/chat/chatSlice";
import RecieverMessageComponent from "./ReceiverMessageComponent";
import SenderMessageComponent from "./SenderMessageComponent";


const ChatBodyComponent = () => {
  const { currentUser } = useAuth();
  const { messages, currentRoom } = useAppSelector(selectChatState);
  const chatBox = useRef(null);

  useEffect(() => {
    const chatBox = $('.chat-box');
    chatBox.scrollTop(chatBox.prop("scrollHeight"));
  }, [messages])

  let senderInfo: IChatUserInfo;
  let receiverInfo: IChatUserInfo;
  if (currentRoom?.user.id === currentUser?.id) {
    senderInfo = {
      username: `${currentRoom?.contact?.first_name} ${currentRoom?.contact?.last_name}`,
      shortUsername: `${currentRoom?.contact?.first_name.charAt(0).toUpperCase()}${currentRoom?.contact?.last_name.charAt(0).toUpperCase()}`,
      email: currentRoom?.contact?.email,
      phoneNo: currentRoom?.contact.phone_no,
      userId: currentRoom?.contact.id,
    }
    receiverInfo = {
      username: `${currentRoom?.user?.first_name} ${currentRoom?.user?.last_name}`,
      shortUsername: `${currentRoom?.user?.first_name.charAt(0).toUpperCase()}${currentRoom?.user?.last_name.charAt(0).toUpperCase()}`,
      email: currentRoom?.user?.email,
      phoneNo: currentRoom?.user.phone_no,
      userId: currentRoom?.user.contact?.id,
    }
  } else {
    senderInfo = {
      username: `${currentRoom?.user?.first_name} ${currentRoom?.user?.last_name}`,
      shortUsername: `${currentRoom?.user?.first_name.charAt(0).toUpperCase()}${currentRoom?.user?.last_name.charAt(0).toUpperCase()}`,
      email: currentRoom?.user?.email,
      phoneNo: currentRoom?.user.phone_no,
      userId: currentRoom?.user.contact?.id,
    }
    receiverInfo = {
      username: `${currentRoom?.contact?.first_name} ${currentRoom?.contact?.last_name}`,
      shortUsername: `${currentRoom?.contact?.first_name.charAt(0).toUpperCase()}${currentRoom?.contact?.last_name.charAt(0).toUpperCase()}`,
      email: currentRoom?.contact?.email,
      phoneNo: currentRoom?.contact.phone_no,
      userId: currentRoom?.contact.id,
    }
  }

  return (
    <div className="chat-box" ref={chatBox}>
      {/* <div className="message-circulat-date">
        <span> Today </span>
      </div> */}
      {currentUser && messages && messages.map((message, index) => {
        if (index > 30) {
          console.log('Wait');
        }
        if (message) {
          if (!message.user) {
            console.log(message, currentUser);
          }
          if (message.user.id === currentUser?.id) {
            return <SenderMessageComponent key={`sender-${index}`} message={message} senderInfo={senderInfo} />
          } else {
            return <RecieverMessageComponent key={`receiver-${index}`} message={message} receiverInfo={receiverInfo} />
          }
        }
      })}
    </div>
  );
}

export default ChatBodyComponent;
