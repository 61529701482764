import AuthNavbarComponent from "../../components/AuthNavbarComponent";
import ForgotPasswordContainer from "./components/ForgotPasswordContainer";
import "./index.css";


const ForgotPasswordComponent = () => {    
    return (
        <section className="section-auth">
            <AuthNavbarComponent />
            <ForgotPasswordContainer />
        </section>
    );
};

export default ForgotPasswordComponent;
