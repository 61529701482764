import SignupWithEmailFormComponent from "./SignupWithEmailForm";
import AuthPageCommonComponent from "../../../components/AuthPageCommonComponent";
import OtpVerificationModal from "../../../components/OtpVerficationModal";

const SignupContainerComponent = () => {
  return (
    <div className="container-fulid content-space">
      <div className="row">
        <AuthPageCommonComponent />
        <div className="col-xl-7 col-lg-6 d-flex justify-content-center login-form-box">
          <div className="align-form-center">
            <h2 className="section-title">Get Started with Patient10X</h2>
            <p className="sub-title uniform-space-b-5">
              Let’s create an account to start
            </p>
            <SignupWithEmailFormComponent />
            <div className="copyright-text">
              <p>© 2022 Patient10X. All right reserved</p>
            </div>
          </div>
        </div>
      </div>
      <OtpVerificationModal />
    </div>
  );
};

export default SignupContainerComponent;
