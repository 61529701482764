import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "./hooks/useAuth";
import DashboardComponent from "./pages/Dashboard";
import EmailDetailsComponent from "./pages/EmailDetails";
import LoginComponent from "./pages/Login";
import SignupComponent from "./pages/Signup";
import TaskDetailComponent from "./pages/TaskDetails";
import ContactDetailsComponent from "./pages/ContactDetails";

import App from "./App";
import ForgotPasswordComponent from "./pages/ForgotPassword";
import ResetPasswordComponent from "./pages/ResetPassword";

export const RouterComponent = () => {

  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return (
      <Routes>
        <Route path="/signup" element={<SignupComponent />} />
        <Route path="/login" element={<LoginComponent />} />
        <Route path="/forgot-password" element={<ForgotPasswordComponent />} />
        <Route path="/reset-password" element={<ResetPasswordComponent />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    )
  }
  return (
    <Routes>
      <Route path="/tasks/detail/new" element={<TaskDetailComponent />} />
      <Route path="/tasks/detail/:taskId" element={<TaskDetailComponent />} />
      <Route path="/emails/detail/:emailId" element={<EmailDetailsComponent />} />
      <Route path="/contacts/detail/:contactId" element={<ContactDetailsComponent />} />
      <Route path="/app" element={<App />} />
      <Route path="/*" element={<DashboardComponent />} />
    </Routes>
  );
};
