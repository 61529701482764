import { getInstance } from 'voximplant-websdk';

export const sdk = getInstance();
let currentCall;

export const login = async () => {
  try {
    await sdk.init({
      enableTrace: true,
      showDebugInfo: true,
      micRequired: true,
    });
    console.log("SDK is ready!");
    try {
      await sdk.connect();
      console.log("Connected");
      // alert('Connected')
    } catch (e) {
      console.log("Connection failed!");
      // alert('Connection failed!')
    }
    const username = "aadim@crm10x.aezidro.voximplant.com";
    try {
      const response = await sdk.login(username, "0GKvh2D&");
      // alert('Logged in!')
      console.log("Logged in!", response);
    } catch (e) {
      // alert('Login failure!')
      console.log("Login failure!", username, e);
    }
    const callSettings = { number: '+918097734431' };
    try {
      currentCall = sdk.call(callSettings);
      console.log("currentCall", currentCall);
    } catch (e) {
      // alert('Login failure!')
      console.log("currentCall failure!", callSettings, e);
    }
  } catch (e) {
    console.log("SDK init failure!");
  }
};