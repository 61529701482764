import AuthPageCommonComponent from "../../../components/AuthPageCommonComponent";
import ForgotPasswordEmailFormComponent from "./ForgotPasswordEmailForm";

const ForgotPasswordContainer = () => {
  return (
    <div className="container-fulid content-space">
      <div className="row">
        <AuthPageCommonComponent />
        <div className="col-xl-7 col-lg-6 d-flex justify-content-center login-form-box">
          <div className="align-form-center">
            <h2 className="section-title"> Forgot your password?</h2>
            <p className="sub-title uniform-space-b-5 "> Confirm your email and we'll send the instructions </p>
            <ForgotPasswordEmailFormComponent />
            <div className="copyright-text">
              <p>© 2022 Patient10X. All right reserved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordContainer;
