import { getImage } from "../../../helpers/assets";
import { useAppSelector } from "../../../store/hooks";
import { selectTaskState } from "../../../store/reducers/task/taskSlice";

const RenderPatientInformation = () => {
  const { selectedPatient } = useAppSelector(selectTaskState);

  return (
    <div className="col-xl-5">
      <div className="card-block p-0 over-hidden ">
        <h3 className="text-center title-spaced mb-3"> Patient Information </h3>
        <ul className="nav nav-tabs tabs-custom" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="info-tab" data-bs-toggle="tab" data-bs-target="#info" type="button" role="tab" aria-controls="info" aria-selected="true">Info</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="conversations-tab" data-bs-toggle="tab" data-bs-target="#conversations" type="button" role="tab" aria-controls="conversations" aria-selected="false">Conversations</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="timeline-tab" data-bs-toggle="tab" data-bs-target="#timeline" type="button" role="tab" aria-controls="timeline" aria-selected="false">Timeline</button>
          </li>
        </ul>
        <div className="tab-content tab-content-spaced" id="myTabContent">
          <div className="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">
            <div className="profile-info text-center">
              <span className="number-badge name-placeholder lg-placeholder badege-pink ms-1 text-uppercase  ">
                {`${selectedPatient?.first_name.charAt(0).toUpperCase()}${selectedPatient?.last_name.charAt(0).toUpperCase()}`}
              </span>
            </div>
            {selectedPatient && <ul className="patient-info-list">
              <li>
                <i>
                  <img src={getImage("icon-User.svg")} alt="" />
                </i> {`${selectedPatient?.first_name} ${selectedPatient?.last_name}`}
              </li>
              {selectedPatient?.email && 
                <li>
                  <i>
                    <img src={getImage("icon-email.svg")} alt="" />
                  </i>
                  <div> {selectedPatient?.email} </div>
              </li>
              }
              {selectedPatient?.phone_no &&
                <li>
                  <i>
                    <img src={getImage("icon-phone.svg")} alt="" />
                  </i>
                  <div> {selectedPatient?.phone_no} </div>
                </li>
              }
              {
                selectedPatient?.additional_info?.doctor_name &&
                <li>
                  <i>
                    <img src={getImage("icon-doctor.svg")} alt="" />
                  </i> {selectedPatient?.additional_info?.doctor_name}
                </li>
              }
            </ul>}

          </div>
          <div className="tab-pane fade" id="conversations" role="tabpanel" aria-labelledby="conversations-tab">Conversations</div>
          <div className="tab-pane fade" id="timeline" role="tabpanel" aria-labelledby="contact-tab">Timeline</div>
        </div>
        <div className="spaceholder-padding">
          <button className="btn btn-outline-primary w-full "> More Info </button>
        </div>
        <div className="activities-placeholder">
          <h3 className="  mb-3"> Open Activities <div> +Add New </div>
          </h3>
          <div className="accordion align-with-activites" id="accordionnotesActivities">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingActivities">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseactivities" aria-expanded="false" aria-controls="collapseTwo"> Tasks (1) </button>
              </h2>
              <div id="collapseactivities" className="accordion-collapse collapse " aria-labelledby="headingActivities" data-bs-parent="#accordionnotesActivities">
                <div className="accordion-body">
                  <div className="form-group row  uniform-space-b-5">
                    <ul className="list-notes">
                      <li>
                        <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute "> SM </span>
                        <div className="left-details">
                          <h4 className="text-primary d-inline-block v-center font-semibold"> Simmon Moraska <span className="d-inline-block ms-1"> 4 hours ago </span>
                          </h4>
                          <p className="badge-cpsule"> Spoke to him today. wants a call after 3 months </p>
                        </div>
                      </li>
                      <li>
                        <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute "> SM </span>
                        <div className="left-details">
                          <h4 className="text-primary d-inline-block v-center font-semibold"> Simmon Moraska <span className="d-inline-block ms-1"> 4 hours ago </span>
                          </h4>
                          <p className="badge-cpsule"> Spoke to him today. wants a call after 3 months </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingMeetings">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseamessage" aria-expanded="false" aria-controls="collapseTwo"> Meetings (0) </button>
              </h2>
              <div id="collapseamessage" className="accordion-collapse collapse " aria-labelledby="headingMeetings" data-bs-parent="#collapseamessage">
                <div className="accordion-body">
                  <div className="form-group row  uniform-space-b-5">
                    <ul className="list-notes">
                      <li>
                        <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute "> SM </span>
                        <div className="left-details">
                          <h4 className="text-primary d-inline-block v-center font-semibold"> Simmon Moraska <span className="d-inline-block ms-1"> 4 hours ago </span>
                          </h4>
                          <p className="badge-cpsule"> Spoke to him today. wants a call after 3 months </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RenderPatientInformation;
