import httpClient from "../helpers/httpClient";
import { ITaskDetail, TASK_CATEGORY } from "../store/reducers/task/taskSlice";

export const getTaskMetaInfo = () => {
  return httpClient.get("/tasks/meta/", {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getTaskDetail = (token: string, taskId: string) => {
  return httpClient.get(`/tasks/${taskId}/`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const saveTask = (
  token: string,
  id: string | number,
  payload: ITaskDetail
) => {
  return httpClient.patch(`/tasks/${id}/`, payload, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createTask = (token: string, payload: ITaskDetail) => {
  return httpClient.post(`/tasks/`, payload, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllTasks = (token: string | null | undefined, page?: number, category?: TASK_CATEGORY) => {
  if (!page) {
    page = 1;
  }
  let url = `/tasks/?page=${page}&ordering=updated_at`;
  if (category) {
    url += `&category=${category}`
  }
  return httpClient.get(url, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const searchTasks = (token: string | null | undefined, q: string, page?: number,category?: TASK_CATEGORY) => {
  if (!page) {
    page = 1;
  }
  let url = `/tasks/?search=${q}&page=${page}&ordering=updated_at`;
  if (category) {
    url += `&category=${category}`
  }
  return httpClient.get(url, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addNote = (
  token: string,
  id: string | number,
  payload: {
    message: string;
    user?: number;
  }
) => {
  return httpClient.post(`/tasks/${id}/notes/`, payload, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteNote = (
  token: string,
  id: string | number,
  noteId: string | number
) => {
  return httpClient.delete(`/tasks/${id}/notes/${noteId}/`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const uploadAttachment = (
  token: string,
  id: string | number,
  payload: {
    file: File;
    user: number;
  }
) => {
  return httpClient.post(`/tasks/${id}/files/`, payload, {
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteAttachment = (
  token: string,
  id: string | number,
  attachmentId: string | number
) => {
  return httpClient.delete(`/tasks/${id}/files/${attachmentId}/`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addLink = (
  token: string,
  id: string | number,
  payload: {
    label: string;
    description: string;
    link: string;
    user?: number;
  }
) => {
  return httpClient.post(`/tasks/${id}/links/`, payload, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteLink = (
  token: string,
  id: string | number,
  linkId: string | number
) => {
  return httpClient.delete(`/tasks/${id}/links/${linkId}/`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
