import axios from "axios";

const httpClient = axios.create();

console.log(process.env)

httpClient.defaults.baseURL = process.env.REACT_APP_PATIENT_BE_BASE_URL;

httpClient.defaults.timeout = 50000;

httpClient.defaults.withCredentials = true;

export default httpClient;
