import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../configure";

export interface ICallState {
  showOngoingCall?: boolean;
  number?: string;
  startTime?: string;
  isMicAllowed?: boolean;
  name?: string;
}

export const initialState: ICallState = {
  showOngoingCall: false,
};

export const callSlice = createSlice({
  name: "call",
  initialState,
  reducers: {
    initiateCall: (state, data) => {
      state.showOngoingCall = data.payload.showOngoingCall;
      state.number = data.payload.number;
      state.startTime = data.payload.startTime;
      state.name = data.payload.name;
    },
    setShowOngoing: (state, data) => {
      state.showOngoingCall = data.payload;
    },
    endCall: (state) => {
      state.showOngoingCall = false;
      state.number = undefined;
      state.startTime = undefined;
      state.name = undefined;
    }
  },
});

export const { 
  initiateCall,
  endCall,
  setShowOngoing
} = callSlice.actions;

export const selectCallState = (state: RootState) => state.call;

export default callSlice.reducer;
