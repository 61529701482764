import { createSlice } from "@reduxjs/toolkit";
import { IOption } from "../../../pages/TaskDetails/components/renderTaskInformation";
import { RootState } from "../../configure";
import { IUserDetails } from "../user/userSlice";


export enum TASK_CATEGORY {
  APPOINTMENT = 'Appointment',
  SURGERY = 'Surgery',
  LEAD = 'Lead',
}

export interface INote {
  id?: number;
  message: string;
  user?: IUserMinDetail | null;
}
export interface INote {
  id?: number;
  message: string;
  user?: IUserMinDetail | null;
}

export interface IAttachment {
  id: number;
  user: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
  };
  filename: string;
  file: string;
  size:  number;
  created_at: string; // "2022-11-01T13:07:29.658712Z"
}

export interface ILink {
  id?: number;
  label: string;
  description: string;
  link: string;
  user?: IUserDetails | null;
}

export interface IAssignedBy {
  first_name:        string;
  last_name:         string;
  email:             string;
  tags:              any[];
  phone_no:          string;
  doctor_name:       string;
  insurance:         string;
  medical_report_no: string;
  month_schedule:    null;
  lead_in:           null;
  zip_code:          string;
  user:              null;
}

export interface ITaskDetail {
  id?: number;
  title?: string;
  due_date?: string;
  hidden_details?: string;
  tags?: string[];
  category?: TASK_CATEGORY;
  price?: number;
  related_to?: string;
  status?: string;  
  priority?: string;
  patient?: IAssignedBy;
  created_at?: Date;
  updated_at?: Date;
  assigned_to?: IAssignedBy;
  assigned_by?: IAssignedBy;
  owner?: number;
  notes?: INote[];
  attachments?: IAttachment[];
  links?: ILink[];
}

export interface IUserMinDetail {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_no?: string;
  contact?: IContact;
}

export interface IContact {
  id: number;
  additional_info: {
    doctor_name?: string;
  };
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  tags: string[];
  phone_no: string;
}
export interface taskState {
  id?: number;
  title?: string;
  tags?: string[];
  category?: string;
  status?: string;
  priority?: string;
  dueDate?: string;
  assignedToOption?: IOption;
  assignedTo?: number;
  patientOption?: IOption;
  selectedPatient?: IContact;
  patientId?: IContact;
  ownerOption?: IOption;
  owner?: number;
  notes?: INote[];
  relatedTo?: string;
  attachments?: IAttachment[];
  links?: ILink[];
  isLoading?: boolean;
  isLoaded?: boolean;
  hiddenDetails?: string;
  deleteNote?: number;
}

export const initialState: taskState = {
  title: "Untitled",
  tags: [],
  notes: [],
  attachments: [],
  links: [],
};

export const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    addTag: (state, data) => {
      if (state?.tags && !state.tags.includes(data.payload)) {
        state?.tags.push(data.payload);
      }
    },
    removeLastTag: (state) => {
      if (state?.tags) {
        state?.tags.pop();
      }
    },
    updateTitle: (state, data) => {
      state.title = data.payload;
    },
    updateCategory: (state, data) => {
      state.category = data.payload;
    },
    updateDueDate: (state, data) => {
      state.dueDate = data.payload;
    },
    updateStatus: (state, data) => {
      state.status = data.payload;
    },
    updatePriority: (state, data) => {
      state.priority = data.payload;
    },
    updateAssignedTo: (state, data) => {
      state.assignedToOption = data.payload;
      state.assignedTo = data.payload.value;
    },
    updatePatient: (state, data) => {
      state.patientOption = data.payload;
      state.selectedPatient = data.payload.value;
      state.patientId = data.payload.value?.id;
    },
    updateOwner: (state, data) => {
      state.ownerOption = data.payload;
      state.owner = data.payload.value;
    },
    updateHiddenDetails: (state, data) => {
      state.hiddenDetails = data.payload;
    },
    addNoteToState: (state, data) => {
      if (state.notes) {
        state.notes.push(data.payload);
      }
    },
    removeNoteFromState: (state, data) => {
      if (state.notes) {
        state.notes = state.notes.filter((note) => note.id !== data.payload);
      }
    },
    setDeleteNote: (state, data) => {
      state.deleteNote = data.payload;
    },
    addAttachmentToState: (state, data) => {
      if (state.attachments) {
        state.attachments.push(data.payload);
      }
    },
    removeAttachmentFromState: (state, data) => {
      if (state.attachments) {
        state.attachments = state.attachments.filter(
          (attachment) => attachment.id === data.payload
        );
      }
    },
    addLinkToState: (state, data) => {
      if (state.links) {
        state.links.push(data.payload);
      }
    },
    removeLinkFromState: (state, data) => {
      if (state.links) {
        state.links = state.links.filter((link) => link.id === data.payload);
      }
    },
    updateIsLoading: (state, data) => {
      state.isLoading = data.payload;
    },
    updateIsLoaded: (state, data) => {
      state.isLoaded = data.payload;
    },
    updateRelatedTo: (state, data) => {
      state.relatedTo = data.payload;
    },
    resetState: (state) => {
      state = initialState;
    },
    initializeTask: (state, data) => {
      state.id = data.payload.id;
      state.title = data.payload.title;
      state.tags = data.payload.tags;
      state.category = data.payload.category;
      state.status = data.payload.status;
      state.priority = data.payload.priority;
      state.dueDate = data.payload.due_date;
      if (data.payload.assigned_to) {
        state.assignedToOption = {
          label: `${data.payload.assigned_to.first_name} ${data.payload.assigned_to.last_name}`,
          value: data.payload.assigned_to.id,
        };
        state.assignedTo = data.payload.assigned_to.id;
      }
      if (data.payload.patient) {
        state.patientOption = {
          label: `${data.payload.patient.first_name} ${data.payload.patient.last_name}`,
          value: data.payload.patient,
        };
        state.selectedPatient = data.payload.patient;
        state.patientId = data.payload.patient.id;
      }
      if (data.payload.owner) {
        state.ownerOption = {
          label: `${data.payload.owner.first_name} ${data.payload.owner.last_name}`,
          value: data.payload.owner.id,
        };
        state.owner = data.payload.owner.id;
      }
      state.hiddenDetails = data.payload.hidden_details;
      state.notes = data.payload.notes;
      state.attachments = data.payload.attachments;
      state.links = data.payload.links;
      state.relatedTo = data.payload.related_to;
    },
  },
});

export const {
  addTag,
  updateTitle,
  removeLastTag,
  updateCategory,
  updateAssignedTo,
  updateDueDate,
  updateOwner,
  updatePriority,
  updatePatient,
  updateStatus,
  updateHiddenDetails,
  updateIsLoading,
  updateIsLoaded,
  addNoteToState,
  removeNoteFromState,
  setDeleteNote,
  addAttachmentToState,
  removeAttachmentFromState,
  addLinkToState,
  updateRelatedTo,
  removeLinkFromState,
  initializeTask,
  resetState,
} = taskSlice.actions;

export const selectTaskState = (state: RootState) => state.task;

export default taskSlice.reducer;
