import { getImage } from "../../../helpers/assets";
import { useAppDispatch } from "../../../store/hooks";

const RenderFilesList = () => {
  const dispatch = useAppDispatch();
  return (
    <div className="card-block uniform-space-b-4">
      <div className=" table-custome">
        <table className="table datatable responsive-mobile-table">
          <thead>
            <tr>
              <th scope="col"> <span>  FIle Name </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i> </th>
              <th scope="col"> <span>  Attached By </span>   <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i> </th>
              <th scope="col"> <span>  Date Added</span>   <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i> </th>
              <th scope="col"> <span>  Size </span>   <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i> </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-cell="FIle Name"> <span className="text-primary d-inline-block v-center font-semibold">  Screen share file....PDF</span> </td>
              <td data-cell="Attached By"> <span className="text-primary d-inline-block v-center font-semibold">  Jenny Wilson </span> </td>
              <td data-cell="Date Added ">  Apr 14, 2022 </td>
              <td data-cell="Size ">  12.50 MB </td>
              <td className="py-2 text-right">    <div className="action-right dropdown  ">
                <a className="btn-dots dropdown-toggle" href="#" id="dropdonw-more" data-bs-toggle="dropdown" aria-expanded="false">
                  <div className="btn-moreaction btn-sm">
                    <img src={getImage("three-dots.svg")} alt="" /></div>
                </a><div className="dropdown-menu dropdown-menu-right shadow dropdown-items-with-icon " aria-labelledby="dropdonw-more"><a className="btn-dots dropdown-toggle" href="#" id="dropdonw-more" data-bs-toggle="dropdown" aria-expanded="false">
                </a><a className="dropdown-item" href="#">
                    <img src="images/icon-edit.png" alt="" />
                    Edit
                  </a>
                  <a className="dropdown-item" href="#">
                    <img src="images/icon-delete.png" alt="" />           Delete
                  </a>
                  <a className="dropdown-item" href="#">
                    <img src={getImage("icon-link.svg")} alt="" />       Copy
                  </a>
                </div>
              </div> </td>
            </tr>
            <tr>
              <td> <span className="text-primary d-inline-block v-center font-semibold">  Screen share file....PDF</span> </td>
              <td> <span className="text-primary d-inline-block v-center font-semibold">  Jenny Wilson </span> </td>
              <td>  Apr 14, 2022 </td>
              <td>  12.50 MB </td>
              <td className="py-2 text-right">    <div className="action-right dropdown   ">
                <a className="btn-dots dropdown-toggle" href="#" id="dropdonw-more" data-bs-toggle="dropdown" aria-expanded="false">
                  <div className="btn-moreaction btn-sm">
                    <img src={getImage("three-dots.svg")} alt="" /></div>
                </a><div className="dropdown-menu dropdown-menu-right shadow dropdown-items-with-icon " aria-labelledby="dropdonw-more"><a className="btn-dots dropdown-toggle" href="#" id="dropdonw-more" data-bs-toggle="dropdown" aria-expanded="false">
                </a><a className="dropdown-item" href="#">
                    <img src="images/icon-edit.png" alt="" />
                    Edit
                  </a>
                  <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#deleteModal" /> <img src="images/icon-delete.png" alt="" /> Delete
                  <a className="dropdown-item" href="#">
                    <img src={getImage("icon-link.svg")} alt="" />       Copy
                  </a>
                </div>
              </div></td>
            </tr>
            <tr>
              <td> <span className="text-primary d-inline-block v-center font-semibold">  Screen share file....PDF</span> </td>
              <td> <span className="text-primary d-inline-block v-center font-semibold">  Jenny Wilson </span> </td>
              <td>  Apr 14, 2022 </td>
              <td>  12.50 MB </td>
              <td className="py-2 text-right">    <div className="action-right dropdown   ">
                <a className="btn-dots dropdown-toggle" href="#" id="dropdonw-more" data-bs-toggle="dropdown" aria-expanded="false">
                  <div className="btn-moreaction btn-sm">
                    <img src={getImage("three-dots.svg")} alt="" /></div>
                </a><div className="dropdown-menu dropdown-menu-right shadow dropdown-items-with-icon " aria-labelledby="dropdonw-more"><a className="btn-dots dropdown-toggle" href="#" id="dropdonw-more" data-bs-toggle="dropdown" aria-expanded="false">
                </a><a className="dropdown-item" href="#">
                    <img src="images/icon-edit.png" alt="" />
                    Edit
                  </a>
                  <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#deleteModal" /> <img src="images/icon-delete.png" alt="" /> Delete
                  <a className="dropdown-item" href="#">
                    <img src={getImage("icon-link.svg")} alt="" />       Copy
                  </a>
                </div>
              </div></td>
            </tr>
            <tr>
              <td> <span className="text-primary d-inline-block v-center font-semibold">  Screen share file....PDF</span> </td>
              <td> <span className="text-primary d-inline-block v-center font-semibold">  Jenny Wilson </span> </td>
              <td>  Apr 14, 2022 </td>
              <td>  12.50 MB </td>
              <td className="py-2 text-right">    <div className="action-right dropdown   ">
                <a className="btn-dots dropdown-toggle" href="#" id="dropdonw-more" data-bs-toggle="dropdown" aria-expanded="false">
                  <div className="btn-moreaction btn-sm">
                    <img src={getImage("three-dots.svg")} alt="" /></div>
                </a><div className="dropdown-menu dropdown-menu-right shadow dropdown-items-with-icon " aria-labelledby="dropdonw-more"><a className="btn-dots dropdown-toggle" href="#" id="dropdonw-more" data-bs-toggle="dropdown" aria-expanded="false">
                </a><a className="dropdown-item" href="#">
                    <img src="images/icon-edit.png" alt="" />
                    Edit
                  </a>
                  <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#deleteModal" /> <img src="images/icon-delete.png" alt="" /> Delete
                  <a className="dropdown-item" href="#">
                    <img src={getImage("icon-link.svg")} alt="" />       Copy
                  </a>
                </div>
              </div></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RenderFilesList;
