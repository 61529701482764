
import { useEffect, useState } from "react";
import makeAnimated from "react-select/animated";
import AsyncSelect from "react-select/async";
import { useAuth } from "../../../hooks/useAuth";
import { searchUsers } from "../../../services/auth";
import { searchContacts } from "../../../services/contacts";
import { getTaskMetaInfo } from "../../../services/tasks";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IContact, selectTaskState, TASK_CATEGORY, updateAssignedTo, updateCategory, updateDueDate, updateOwner, updatePatient, updatePriority, updateRelatedTo, updateStatus } from "../../../store/reducers/task/taskSlice";
import { format } from "date-fns";

export interface IOption {
  label: string;
  value: string;
}

export interface ITaskMetaInfo {
  categories: string[];
  statuses: string[];
  priorities: string[];
}


export const RenderTaskInformation = () => {
  const { token } = useAuth();
  const dispatch = useAppDispatch();
  const animatedComponents = makeAnimated();
  const { category, status, priority, patientOption, assignedToOption, ownerOption, dueDate, isLoading, relatedTo } = useAppSelector(selectTaskState);
  const [metaInfo, setMetaInfo] = useState<ITaskMetaInfo>();
  const [query, setQuery] = useState("");


  const loadOptions = (inputValue: any, callback: any) => {
    return searchContacts(token, query).then((response) => callback(response.data.results));
  };

  useEffect(() => {
    getTaskMetaInfo().then((response) => {
      setMetaInfo(response.data);
    })
  }, []);

  if (isLoading) {
    return <h1>Loading...</h1>
  }

  const asyncSelectStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: '10px',
      borderColor: '#E7E7E7',
      padding: '6px',
      background: 'rgba(245, 247, 251, 0.2)',
    }),
  }

  return (
    <div className="card-block uniform-space-b-4 ">
      <div className="accordion" id="accordionTaskInformation">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> Task Information </button>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionTaskInformation">
            <div className="accordion-body">
              <div className="form-group row align-items-center uniform-space-b-5">
                <div className="col-sm-4 ">
                  <label className="form-label "> Category <span className="d-block sub-label"> Stay organized by categorizing this task </span>
                  </label>
                </div>
                <div className="col-sm-8">
                  <select className="styled-control control-sm" onChange={(e) => {
                    dispatch(updateCategory(e.target.value))
                  }} defaultValue={category}>
                    <option value="" disabled selected hidden>Select category</option>
                    {metaInfo?.categories.map((item, index) => <option key={`category${index}`}> {item}</option>)}
                  </select>
                </div>
              </div>
              {category === TASK_CATEGORY.LEAD && 
                <div className="form-group row align-items-center uniform-space-b-5">
                  <div className="col-sm-4 ">
                    <label className="form-label "> Related To
                      <span className="d-block sub-label"> Define task relation </span>
                    </label>
                  </div>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      placeholder="Add Related to text Eg: Bariatric Campaign"
                      className="styled-control control-sm chat-input"
                      defaultValue={relatedTo}
                      onChange={(e) => {
                        dispatch(updateRelatedTo(e.target.value))
                      }}
                    />
                  </div>
                </div>
              }
              <div className="form-group row align-items-center uniform-space-b-5">
                <div className="col-sm-4 ">
                  <label className="form-label "> Due Date <span className="d-block sub-label"> Set a due date to stay accountable</span>
                  </label>
                </div>
                <div className="col-sm-8">
                  <input type="date"
                    defaultValue={dueDate ? format(new Date(dueDate), 'yyyy-MM-dd') : 'NA'}
                    onChange={(e) => {
                      dispatch(updateDueDate(e.target.value))
                    }}
                  />
                </div>
              </div>
              <div className="form-group row align-items-center uniform-space-b-5">
                <div className="col-sm-4 ">
                  <label className="form-label "> Status <span className="d-block sub-label"> Current status of this task </span>
                  </label>
                </div>
                <div className="col-sm-8">
                  <select className="styled-control control-sm" onChange={(e) => {
                    dispatch(updateStatus(e.target.value))
                  }} defaultValue={status}>
                    <option value="" disabled selected hidden>Select Status</option>
                    {metaInfo?.statuses.map((item, index) => <option key={`status${index}`}> {item}</option>)}
                  </select>
                </div>
              </div>
              <div className="form-group row align-items-center uniform-space-b-5">
                <div className="col-sm-4 ">
                  <label className="form-label "> Set the Priority <span className="d-block sub-label"> How important is this task? </span>
                  </label>
                </div>
                <div className="col-sm-8">
                  <select className="styled-control control-sm" onChange={(e) => {
                    dispatch(updatePriority(e.target.value))
                  }} defaultValue={priority}>
                    <option value="" disabled selected hidden>Select Priority</option>
                    {metaInfo?.priorities.map((item, index) => <option key={`priority${index}`} > {item}</option>)}
                  </select>
                </div>
              </div>
              <div className="form-group row align-items-center uniform-space-b-5">
                <div className="col-sm-4 ">
                  <label className="form-label "> Assigned to <span className="d-block sub-label"> Who is responsible for this task? </span>
                  </label>
                </div>
                <div className="col-sm-8 async-select-container">
                  <AsyncSelect
                    cacheOptions
                    styles={asyncSelectStyles}
                    components={animatedComponents}
                    getOptionLabel={(e: IOption) => {
                      return e?.label;
                    }}
                    getOptionValue={(e: IOption) => {
                      return e?.value;
                    }}
                    loadOptions={loadOptions}
                    defaultValue={assignedToOption}
                    onInputChange={(value) => setQuery(value)}
                    onChange={(value: any) => {
                      dispatch(updateAssignedTo(value))
                    }}
                  />
                </div>
              </div>
              <div className="form-group row align-items-center uniform-space-b-5">
                <div className="col-sm-4">
                  <label className="form-label "> Patient Name <span className="d-block sub-label"> Connect this task to a patient </span>
                  </label>
                </div>
                <div className="col-sm-8 async-select-container">
                  <AsyncSelect
                    cacheOptions
                    styles={asyncSelectStyles}
                    placeholder="Select Patient"
                    components={animatedComponents}
                    getOptionLabel={(e: IOption) => {
                      return e?.label;
                    }}
                    getOptionValue={(e: IOption) => {
                      return e?.value;
                    }}
                    loadOptions={loadOptions}
                    defaultValue={patientOption}
                    onInputChange={(value) => setQuery(value)}
                    onChange={(option: any) => {
                      dispatch(updatePatient(option))
                    }}
                  />
                </div>
              </div>
              <div className="form-group row align-items-center uniform-space-b-5">
                <div className="col-sm-4 ">
                  <label className="form-label "> Task Owner <span className="d-block sub-label"> Is the person who created this task </span>
                  </label>
                </div>
                <div className="col-sm-8 async-select-container">
                  <AsyncSelect
                    cacheOptions
                    styles={asyncSelectStyles}
                    components={animatedComponents}
                    getOptionLabel={(e: IOption) => {
                      return e?.label;
                    }}
                    getOptionValue={(e: IOption) => {
                      return e?.value;
                    }}
                    loadOptions={loadOptions}
                    defaultValue={ownerOption}
                    onInputChange={(value) => setQuery(value)}
                    onChange={(value: any) => dispatch(updateOwner(value))}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

