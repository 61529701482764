import classNames from "classnames";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getImage } from "../../../helpers/assets";
import { useAuth } from "../../../hooks/useAuth";
import { createTask, saveTask } from "../../../services/tasks";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { addTag, ITaskDetail, removeLastTag, selectTaskState, TASK_CATEGORY, updateTitle } from "../../../store/reducers/task/taskSlice";
import { ESelectedMenu } from "../../Dashboard/enums";


const RenderTaskTitle = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { token } = useAuth();
  const taskDetail = useAppSelector(selectTaskState);
  const { id, tags, title } = taskDetail;
  const [titleEditable, setTitleEditable] = useState<boolean>(false);
  const [tagEditable, setTagEditable] = useState<boolean>(false);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [newTag, setNewTag] = useState<string>();
  const tagColors = ['badege-orange-dark', 'badege-green-dark', 'badege-orange-dark']

  const saveOrCreateArticle = useCallback(() => {
    if (!token) return;
    const { title, dueDate, hiddenDetails, tags, category, status, priority, patientId, assignedTo, owner, relatedTo } = taskDetail;
    let payload: ITaskDetail = {
      title,
      tags,
      category,
      status,
      priority,
      owner,
      due_date: dueDate,
      hidden_details: hiddenDetails,
      patient: patientId,
      assigned_to: assignedTo,
    } as ITaskDetail;

    if (category === TASK_CATEGORY.LEAD) {
      payload.related_to = relatedTo;
    }
    if (id && token) {
      setSaveInProgress(true);
      saveTask(token, id, payload).then((response) => {
        setSaveInProgress(false);
      }).catch((error) => {
        setSaveInProgress(false);
      });
    } else {
      setSaveInProgress(true);
      createTask(token, payload).then((response) => {
        const { task_id: taskId } = response.data;
        setSaveInProgress(false);
        navigate(`/tasks/detail/${taskId}`, { state: { selectedMenu: ESelectedMenu.TASKS }, replace: true });
      }).catch((error) => {
        setSaveInProgress(false);
      });
    }
  }, [token, taskDetail, id, navigate]);

  return (
    <div className="col-xl-10">
      <div className="card-block">
        <div className="subaction-with-title d-flex justify-content-between align-items-center">
          <div className="title-with-edit title-editable-input w-full-65">
            <h3>
              {titleEditable ? <span className=""><input
                autoFocus
                type="text"
                defaultValue={title}
                placeholder="Untitled"
                className={classNames('w-full-65', { 'title-input-editable': titleEditable })}
                onChange={(e) => {
                  dispatch(updateTitle(e.target.value))
                }}
                onBlur={() => {
                  setTitleEditable(!titleEditable)
                }}
              /></span> : title}
              {!titleEditable &&
                <div className="d-inline-block cursor-pointer" onClick={() => {
                  if (title) {
                    setTitleEditable(!titleEditable)
                  }
                }}>
                  <img src={getImage("icon-edit.svg")} alt="" />
                </div>
              }

            </h3>
            <div className="space-x-2 mt-3">
              {tags && tags.map((tag, index) => <span key={`tag - ${index}`} className={`number-badge ms-1 text-uppercase ${tagColors[index]}`}>&nbsp;{tag}&nbsp;</span>)}
              {tagEditable && <span className={`number-badge ms-1 text-uppercase tag-editable-input ${tags && tagColors[tags?.length] || 'badege-orange-dark'}`}>&nbsp;<input
                autoFocus
                onChange={(e) => {
                  setNewTag(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    dispatch(addTag(newTag))
                    setTagEditable(false);
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value) {
                    dispatch(addTag(e.target.value))
                    setTagEditable(false);
                  } else {
                    setTagEditable(!tagEditable);
                  }
                }}
                type="text"
                placeholder="Untitled"
              />&nbsp;</span>}
              {(!tags || (tags && tags.length < 3)) && <button className="btn btn-outline-primary btn-xs ms-1" onClick={() => {
                setTagEditable(!tagEditable);
              }}>
                <i className="fa fa-plus"></i>
              </button>}
              {tags && tags.length >= 1 && <button className="btn btn-outline-primary btn-xs ms-1" onClick={() => {
                dispatch(removeLastTag());
              }}>
                <i className="fa fa-minus"></i>
              </button>}
            </div>
          </div>
          <div className="action-right dropdown  ">
            <button className="btn btn-primary btn-sm padd-x-20 mx-3" onClick={saveOrCreateArticle}> {id ? (saveInProgress ? 'Saving ...' : 'Save Changes') : 'Create Task'} </button>
            {id &&
              <>
                <span className="btn-dots dropdown-toggle" id="dropdonw-more" data-bs-toggle="dropdown" aria-expanded="false">
                  <div className="btn-moreaction">
                    <img src={getImage('three-dots.svg')} alt="" />
                  </div>
                </span>
                <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in mt-3" aria-labelledby="dropdonw-more">
                  <span className="dropdown-item"> Delete </span>
                </div>
              </>
            }
          </div>
        </div>
        {saveInProgress && <span className="float-right mb-10">Saving ...</span>}
      </div>
    </div>
  )
}

export default RenderTaskTitle;
