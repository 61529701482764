import { AxiosError } from "axios";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LogoComponent from "../../components/LogoComponent";
import NavbarComponent from "../../components/NavbarComponent";
import SideMainMenuComponent from "../../components/SideMainMenuComponent";
import SideOtherMenuComponent from "../../components/SideOtherMenuComponent";
import { getImage } from "../../helpers/assets";
import { useAuth } from "../../hooks/useAuth";
import { deleteEmail, getEmailDetail } from "../../services/email";
import { useAppDispatch } from "../../store/hooks";
import { IEmailDetails, updateIsLoaded, updateIsLoading } from "../../store/reducers/email/emailSlice";
import { ESelectedMenu } from "../Dashboard/enums";
import "./index.css";

const RenderEmailDetailSection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { token } = useAuth();
  const { emailId } = useParams();
  const [serverError, setServerError] = useState<AxiosError>();
  const [email, setEmail] = useState<IEmailDetails>();

  useEffect(() => {
    if (emailId && token) {
      dispatch(updateIsLoading(true));
      getEmailDetail(token, emailId).then((response) => {
        dispatch(updateIsLoading(false));
        dispatch(updateIsLoaded(true));
        setEmail(response.data)
      }).catch((error) => {
        dispatch(updateIsLoading(false));
        setServerError(error);
      });
    }
  }, [emailId, token]);

  if (serverError) {
    return <h1>Something went wrong</h1>
  }
  const fullName = email?.fromlist ? email?.fromlist[0].name : '';
  const [firstName, lastName] = fullName?.split(' ');
  const badge = `${firstName ? firstName.charAt(0).toUpperCase() : ''}${lastName ? lastName.charAt(0).toUpperCase() : ''}`;
  const fromlist = email?.fromlist;
  let fromEmail;
  if (fromlist) {
    fromEmail = fromlist[0].email;
  }

  const handleDeleteEmail = () => {
    if (emailId && token) {
      deleteEmail(token, emailId).then((response) => {
        navigate(`/emails/`, { state: { selectedMenu: ESelectedMenu.EMAIL }, replace: true });
      }).catch((error) => {
        // Alert - Error While deleting
      });
    }
  }

  const copyToClipBoard = (link: string) => {
  }

  return (
    <div className="form-content-area">
      <div className="row statics-row uniform-space-b-5">
        <div className="col-sm-12">
          <div className="card-block ">

            <div className="mail-wrapper">
              <div className="mail-header-wrapper with-border px-0">
                <div className=" d-inline-block v-center">
                  <button className="btn btn-light btn-sm btn-back btn-w-icon " onClick={() => {
                    navigate(`/emails/`, { state: { selectedMenu: ESelectedMenu.EMAIL }, replace: true });
                  }}>
                    <i className=" fa fa-angle-left" /> Back </button>
                </div>
              </div>
              {
                email ?
                  <div className="email-detail-body">
                    <h3 className="align-w-action"> {email?.subject} <div className="action-right dropdown  ">
                      <div className="email-date d-inline-block"> {`${format(new Date(email.created_at || 0), 'MMM dd, yyyy. hh:mm a')}`}</div>
                      <a className="btn-dots dropdown-toggle" href="#" id="dropdonw-more" data-bs-toggle="dropdown" aria-expanded="false">
                        <div className="btn-moreaction">
                          <img src={getImage("icon-more-verticale.svg")} />
                        </div>
                      </a>
                      <div className="dropdown-menu dropdown-menu-right shadow dropdown-items-with-icon" aria-labelledby="dropdonw-more" style={{}}>
                        <a className="btn-dots dropdown-toggle" href="#" id="dropdonw-more" data-bs-toggle="dropdown" aria-expanded="false" />
                        <span className="dropdown-item" onClick={handleDeleteEmail}>
                          <img src={getImage("icon-delete.png")} alt="" /> Delete Email </span>
                        <span className="dropdown-item" onClick={() => copyToClipBoard(location.pathname)}>
                          <img src={getImage("icon-link.svg")} alt="" /> Copy link to email
                        </span>
                      </div>
                    </div>
                    </h3>
                    <ul className="email-detiled">
                      <li>
                        <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute "> {badge} </span>
                        <div className="left-details" style={{ width: '-webkit-fill-available' }}>
                          <h4 className="text-primary d-inline-block v-center font-semibold"> {`${fullName}`} <span className="d-inline-block ms-1">
                            &lt;{fromEmail}&gt;
                          </span>
                          </h4>
                          <p className="sender-details"> To : {email.to?.map(item => item.name || item.email).join(', ')}</p>
                          <div className="email-body" dangerouslySetInnerHTML={{
                            __html: email?.content || ''
                          }}>
                          </div>
                        </div>
                      </li>
                      {/* <li>
                        <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute "> SM </span>
                        <div className="left-details">
                          <h4 className="text-primary d-inline-block v-center font-semibold"> Simmon Moraska <span className="d-inline-block ms-1">
                            &lt; simmonmoraska@patient10x.com&gt;
                          </span>
                          </h4>
                          <p className="sender-details"> To : Capla Paprocki, Esther Howard, Ronald Richards </p>
                        </div>
                        <div className="email-body">
                          <p> Dear Ms.Chapman, </p>
                          <p> Following your request, we have recently sent you our new catalogue. We are convinced that it will enable you to see the quality of our products. Our local agent will contact you soon to arrange a meeting on a day and time that suits you in order to discuss in detail how our products can be of benefit to your company’s needs. For further information, please do not hesitate to contact us.
                          </p><p> Yours sincerely, </p>
                          <div className="button-wrapper space-x-20">
                            <button className="btn btn-primary btn-sm btn-w-icon min-w-138 cta-write-email">
                              <i className="fa fa-mail-reply" /> Replay </button>
                            <button className="btn btn-outline-primary  btn-w-icon btn-sm min-w-138">
                              <svg width={16} height={14} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 3.00026V1.41026C8 0.520262 9.08 0.0702616 9.71 0.700262L15.3 6.29026C15.69 6.68026 15.69 7.31026 15.3 7.70026L9.71 13.2903C9.08 13.9203 8 13.4803 8 12.5903V11.0003H1C0.45 11.0003 0 10.5503 0 10.0003V4.00026C0 3.45026 0.45 3.00026 1 3.00026H8Z" fill="currentColor" />
                              </svg> Forward </button>
                          </div>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                  : <h6>Loading ...</h6>
              }
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

const EmailDetailsComponent = () => {

  return <div className="wrapper bg-base view-full">
    <div className="d-flex">
      <div className="sidebar bg-white">
        <LogoComponent />
        <SideMainMenuComponent />
        <SideOtherMenuComponent />
      </div>
      <div className="right-part">
        <NavbarComponent />
        <RenderEmailDetailSection />
      </div>
    </div>
    <div>
    </div>
  </div>
};

export default EmailDetailsComponent;
