import classNames from "classnames";
import { useState, useEffect } from "react";
import { useNavigate, Location, useLocation } from "react-router-dom";
import { ESelectedMenu, ESelectedMenuRoutePath } from "../pages/Dashboard/enums";

const SideMainMenuComponent = () => {
  let navigate = useNavigate();
  let location: Location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState<ESelectedMenu>();

  useEffect(() => {
    if (location?.state?.selectedMenu) {
      setSelectedMenu(location.state.selectedMenu)
    }
  }, [location])

  return (
    <div className="side-menu">
      <h3> MAIN MENU </h3>
      <ul className="nav flex-column">
        <li className="nav-item" onClick={() => {
          navigate(ESelectedMenuRoutePath.DASHBOARD, { state: { selectedMenu: ESelectedMenu.DASHBOARD }, replace: true });
        }}>
          <div className={
            classNames('nav-link cursor-pointer', {
              'active': selectedMenu === ESelectedMenu.DASHBOARD,
            })} aria-current="page">
            <i className="icon-dashboard">
              <span className=" pathshape path1" />
              <span className="pathshape path2" />
              <span className=" pathshape path3" />
              <span className="pathshape path4" />
              <span className="pathshape path5" />
              <span className="pathshape path6" />
              <span className="pathshape path7" />
              <span className="pathshape path8" />
            </i>
            <span> Dashboard </span>
          </div>
        </li>


        <li className="nav-item" onClick={() => {
          navigate(ESelectedMenuRoutePath.TASKS, { state: { selectedMenu: ESelectedMenu.TASKS }, replace: true });
        }}>
          <div className={
            classNames('nav-link cursor-pointer', {
              'active': selectedMenu === ESelectedMenu.TASKS,
            })} >
            <i className="icon-task">
              <span className="path1 pathshape" />
              <span className="path2 pathshape" />
            </i>
            <span> Task </span>
          </div>
        </li>



        <li className="nav-item" onClick={() => {
          navigate(ESelectedMenuRoutePath.EMAIL, { state: { selectedMenu: ESelectedMenu.EMAIL }, replace: true });
        }}>
          <div className={
            classNames('nav-link cursor-pointer', {
              'active': selectedMenu === ESelectedMenu.EMAIL,
            })}>
            <i className="icon-nav-email">
              <span className="path1 pathshape" />
              <span className="path2 pathshape" />
            </i>
            <span> Email </span>
          </div>
        </li>

        <li className="nav-item" onClick={() => {
          navigate(ESelectedMenuRoutePath.TEXTS, { state: { selectedMenu: ESelectedMenu.TEXTS }, replace: true });
        }}>
          <div className={
            classNames('nav-link cursor-pointer', {
              'active': selectedMenu === ESelectedMenu.TEXTS,
            })}>
            <i className="icon-text">
              <span className="path1 pathshape" />
              <span className="path2 pathshape" />
            </i>
            <span>  Text </span>
          </div>
        </li>

        <li className="nav-item" onClick={() => {
          navigate(ESelectedMenuRoutePath.CALLS, { state: { selectedMenu: ESelectedMenu.CALLS }, replace: true });
        }}>
          <div className={
            classNames('nav-link cursor-pointer', {
              'active': selectedMenu === ESelectedMenu.CALLS,
            })}>
            <i className="icon-call">
              <span className="path1 pathshape" />
              <span className="path2 pathshape" />
              <span className="path3 pathshape" />
              <span className="path4 pathshape" />
            </i>
            <span> Call </span>
          </div>
        </li>

        <li className="nav-item" onClick={() => {
          navigate(ESelectedMenuRoutePath.CONTACTS, { state: { selectedMenu: ESelectedMenu.CONTACTS }, replace: true });
        }}>
          <div className={
            classNames('nav-link cursor-pointer', {
              'active': selectedMenu === ESelectedMenu.CONTACTS,
            })}>
            <i className="icon-contact">
              <span className="path1 pathshape" />
              <span className="path2 pathshape" />
              <span className="path3 pathshape" />
              <span className="path4 pathshape" />
            </i>
            <span> Contacts </span>
          </div>
        </li>

        <li className="nav-item" onClick={() => {
          navigate(ESelectedMenuRoutePath.USER_MANAGEMENT, { state: { selectedMenu: ESelectedMenu.USER_MANAGEMENT }, replace: true });
        }}>
          <div className={
            classNames('nav-link cursor-pointer', {
              'active': selectedMenu === ESelectedMenu.USER_MANAGEMENT,
            })}>
            <i className="icon-user-nav">
              <span className="path1 pathshape" />
              <span className="path2 pathshape" />
            </i>
            <span> Users management </span>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default SideMainMenuComponent;
