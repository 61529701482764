import { AddContactForm } from "../components/CreateContactModal";
import httpClient from "../helpers/httpClient";
import { IContactDetail } from "../store/reducers/contact/contactSlice";

export const searchContacts = (token: string | null | undefined, q: string) => {
  return httpClient.get(`/contacts/search/?search=${q}`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getContactDetail = (token: string, contactId: string) => {
  return httpClient.get(`/contacts/${contactId}/`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateContact = (token: string, contactId: string | number | undefined,
  payload: IContactDetail
) => {
  return httpClient.patch(`/contacts/${contactId}/`, payload, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllContacts = (token: string | null | undefined, searchTerm: string | undefined) => {
  httpClient.defaults.timeout = 20000;
  return httpClient.get(`/contacts/?searchTerm=${searchTerm}`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addContact = (token: string, data: AddContactForm) => {
  return httpClient.post('/contacts/', data, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
}
