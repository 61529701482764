import { createSlice } from "@reduxjs/toolkit";
import { ESelectedGraphCard } from "../../../pages/Dashboard/enums";
import { RootState } from "../../configure";

interface IMetaInfo {
  count: number;
  change: 'positive' | 'negative';
  diff: number;
}

export interface IDashboardMetaInfo {
  [ESelectedGraphCard.LEADS]: IMetaInfo;
  [ESelectedGraphCard.APPOINTMENTS]: IMetaInfo;
  [ESelectedGraphCard.SURGERIES]: IMetaInfo;
  [ESelectedGraphCard.REVENUE]: IMetaInfo;
  [ESelectedGraphCard.TASKS]: IMetaInfo;
}

export interface IDashbaordState {
  metaInfo?: IDashboardMetaInfo
  selectedCard: ESelectedGraphCard;
}

export const initialState: IDashbaordState = {
  selectedCard: ESelectedGraphCard.LEADS,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setMetaInfo: (state, data) => {
      state.metaInfo = data.payload;
    },
    selectCard: (state, data) => {
      state.selectedCard = data.payload;
    },
  },
});

export const { 
  setMetaInfo,
  selectCard,
} = dashboardSlice.actions;

export const selectDashboardState = (state: RootState) => state.dashboard;

export default dashboardSlice.reducer;
