import { useAppSelector } from "../../../store/hooks";
import { selectTaskState } from "../../../store/reducers/task/taskSlice";

const DeleteCommentModal = () => {
  const taskDetail = useAppSelector(selectTaskState);
  return (
    <div className="modal fade" id="deleteModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered  ">
        <div className="modal-content ">
          <div className="modal-body text-center">
            <h3> Would you like to delete this comment? </h3>
            <div className="form-group  mb-xl-3  mb-2 text-center btn-space  ">
              <button type="button" className="btn btn-dark border-0 btn-sm w-140 ">Cancel </button>
              <button type="button" className="btn btn-danger border-0 btn-sm w-140 ">Delete </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default DeleteCommentModal;
