import classNames from "classnames";
import { format } from "date-fns";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getImage } from "../helpers/assets";
import { useAuth } from "../hooks/useAuth";
import { useDebounce } from "../hooks/useDebounce";
import { ESelectedMenu } from "../pages/Dashboard/enums";
import { getAllTasks, searchTasks } from "../services/tasks";
import { useAppDispatch } from "../store/hooks";
import { ITaskDetail, resetState, TASK_CATEGORY } from "../store/reducers/task/taskSlice";

const LeadListTableComponent = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { token } = useAuth();
  const [tasks, setTasks] = useState<ITaskDetail[]>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSizeArray, setPageSizeArray] = useState<number[]>([1, 2]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        setIsSearching(true);
        searchTasks(token, debouncedSearchTerm, currentPage, TASK_CATEGORY.LEAD).then((response) => {
          setIsSearching(false);
          setTasks(response.data.results);
          const pageArray = [];
          const add = response.data.count % 2 === 0 ? 0 : 1;
          for (var i = 1; i <= response.data.count / 5 + add; i++) {
            pageArray.push(i);
          }
          setPageSizeArray(pageArray);
        });
      } else {
        getAllTasks(token, currentPage, TASK_CATEGORY.LEAD).then((response) => {
          setTasks(response.data.results);
          const pageArray = [];
          const add = response.data.count % 2 === 0 ? 0 : 1;
          for (var i = 1; i <= response.data.count / 5 + add; i++) {
            pageArray.push(i);
          }
          setPageSizeArray(pageArray);
          setIsSearching(false);
        });
      }
    },
    [currentPage, debouncedSearchTerm, token] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    if (token) {
      getAllTasks(token, currentPage, TASK_CATEGORY.LEAD).then((response) => {
        setTasks(response.data.results);
        const pageArray = [];
        const add = response.data.count % 2 === 0 ? 0 : 1;
        for (var i = 1; i <= response.data.count / 5 + add; i++) {
          pageArray.push(i);
        }
        setPageSizeArray(pageArray);
      });
    }
  }, [token, currentPage])



  return (
    <div className="row statics-row uniform-space-b-5 ">
      <div className="col-sm-12">
        <div className="card-block ">
          <div className="align-with-leftright">
            <div>
              <h3> Today’s Leads </h3>
            </div>
            <div className="search-with-button">
              <button className="btn btn-primary btn-sm padd-x-20" onClick={() => {
                dispatch(resetState());
                navigate('/tasks/detail/new', { state: { selectedMenu: ESelectedMenu.TASKS }, replace: true });
              }}><i className="fa fa-plus"></i>  Add New</button>
              <div className="searcharea ">
                <i className="v-transform-center"> <img src={getImage("icon-search.svg")} alt="" />
                </i>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Search keyword..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="table-responsive table-custome">
            <table className="table datatable responsive-mobile-table">
              <thead>
                <tr>
                  <th scope="col"> <span> Leads </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i> </th>
                  <th scope="col"> <span> Contact name </span> <i className="short-arrow">
                    <img src={getImage("short-arrow.svg")} alt="" /> </i> </th>
                  <th scope="col"> <span> Phone Number </span> <i className="short-arrow">
                    <img src={getImage("short-arrow.svg")} alt="" /> </i> </th>
                  <th scope="col"> <span> Email </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i> </th>
                  <th scope="col"><span> Date </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i> </th>
                  <th scope="col"> <span> Related to </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i> </th>
                </tr>
              </thead>
              <tbody>
                {!isSearching && tasks && tasks.map((task, index) =>
                  <tr key={`task-${index}`}>
                    <td data-cell="Leads">
                      <Link to={`/tasks/detail/${task.id}`}>
                        <span className="text-primary d-inline-block v-center font-semibold">
                          {task.title}</span>
                      </Link>
                    </td>
                    <td data-cell="Contact name">
                      {task?.assigned_to &&
                        <span className="text-primary font-semibold">
                          <span className="number-badge badege-primary me-1">
                            {`${task?.assigned_to?.first_name.charAt(0).toUpperCase()} ${task?.assigned_to?.last_name.charAt(0).toUpperCase()}`}
                          </span>
                          {`${task?.assigned_to?.first_name} ${task?.assigned_to?.last_name}`}
                        </span>
                      }
                    </td>
                    <td data-cell="Phone Number">{task?.assigned_to && task.assigned_to?.phone_no} </td>
                    <td data-cell="Email">{task?.assigned_to && task.assigned_to?.email} </td>
                    <td data-cell="Date">{task.due_date ? format(new Date(task.due_date), 'MMM d, yyyy') : ''}</td>
                    <td data-cell="Related to">
                      <span className="number-badge badege-primary ms-1">
                        {task.related_to}
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <nav aria-label="Page">
            <ul className="pagination justify-content-center">
              <li className={classNames("page-item", { "disabled": currentPage === 1 })} onClick={() => {
                if (currentPage > 1) {
                  setCurrentPage(currentPage - 1)
                }
              }}>
                <span className="page-link prev" tabIndex={-1} aria-disabled="true">
                  <img src={getImage("angle-left.svg")} alt="" />
                </span>
              </li>
              {pageSizeArray.map((page, index) =>
                <li key={index} className={classNames("page-item", { "active": index + 1 === currentPage })}
                  onClick={() => {
                    setCurrentPage(index + 1)
                  }}>
                  <span className="page-link">{index + 1}</span>
                </li>
              )}
              <li className={classNames("page-item", { "disabled": currentPage === pageSizeArray.length })} onClick={() => {
                if (currentPage !== pageSizeArray.length) {
                  setCurrentPage(currentPage + 1)
                }
              }}>
                <span className="page-link next">
                  <img src={getImage("angle-right.svg")} alt="" />
                </span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}


export default LeadListTableComponent;
