import { IUserMinDetail } from "../store/reducers/task/taskSlice";
import httpClient from "../helpers/httpClient";
import { CALL_STATES } from "../hooks/useCall";

export const initialCallToUser = (
  roomName: string,
  from: IUserMinDetail,
  to: IUserMinDetail
) => {
  return Promise.resolve({});
};

export const disconnectCallUser = (
  roomName: string,
  from: IUserMinDetail,
  to: IUserMinDetail
) => {
  return Promise.resolve({});
};

export const callLog = (
  token: string | null | undefined,
  initiator: string | number,
  receiver: string | number | undefined,
  status: CALL_STATES = CALL_STATES.INITIATED,
  payload?: {
    start_time?: string;
    end_time?: string;
  },
  roomId?: string | number,
) => {
  return httpClient.post(
    `/calls/log/`,
    {
      "initiator": initiator,
      "receiver": receiver,
      "status": status,
      "start_time": payload?.start_time,
      "end_time": payload?.end_time,
      "room_id": roomId
    },
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

