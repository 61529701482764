import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LogoComponent from "../../components/LogoComponent";
import NavbarComponent from "../../components/NavbarComponent";
import SideMainMenuComponent from "../../components/SideMainMenuComponent";
import SideOtherMenuComponent from "../../components/SideOtherMenuComponent";
import { getImage } from "../../helpers/assets";
import { useAuth } from "../../hooks/useAuth";
import { getTaskDetail } from "../../services/tasks";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { initializeTask, selectTaskState, updateIsLoaded, updateIsLoading } from "../../store/reducers/task/taskSlice";
import { ESelectedMenu } from "../Dashboard/enums";
import AddAttachmentModal from "./components/addAttachmentModal";
import AddLinkModal from "./components/addLinkModal";
import DeleteCommentModal from "./components/deleteCommentModal";
import RenderAttachmentSection from "./components/renderAttachmentSection";
import RenderHiddenSection from "./components/renderHiddenSection";
import RenderLinkSection from "./components/renderLinkSection";
import RenderNotesSection from "./components/renderNotesSection";
import RenderPatientInformation from "./components/renderPatientInformation";
import { RenderTaskInformation } from "./components/renderTaskInformation";
import RenderTaskTitle from "./components/renderTitle";
import "./index.css";

const RenderTaskDetailForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { token } = useAuth();
  const { taskId } = useParams();
  const [serverError, setServerError] = useState<AxiosError>();
  const { selectedPatient } = useAppSelector(selectTaskState);

  useEffect(() => {
    if (taskId && token) {
      dispatch(updateIsLoading(true));
      getTaskDetail(token, taskId).then((response) => {
        dispatch(updateIsLoading(false));
        dispatch(updateIsLoaded(true));
        dispatch(initializeTask(response.data));
      }).catch((error) => {
        dispatch(updateIsLoading(false));
        setServerError(error);
      });
    }
  }, [taskId, token]);

  if (serverError) {
    return <h1>Something went wrong</h1>
  }

  return (
    <div className="form-content-area">
      <div className="row uniform-space-b-4 align-items-center group-control">
        <div className="col-xl-2 task-all">
          <div className="card-block">
            <button className="btn-link btn-all-task" onClick={() => {
              navigate('/tasks', { state: { selectedMenu: ESelectedMenu.TASKS }, replace: true });
            }}>
              <img src={getImage("arrow-left.svg")} alt="" />
              <span> All Task </span>
            </button>
          </div>
        </div>
        <RenderTaskTitle />
      </div>
      <div className="row statics-row uniform-space-b-5 ">
        <div className="col-xl-7">
          <RenderTaskInformation />
          <RenderHiddenSection />
          <RenderNotesSection />
          <RenderAttachmentSection />
          <RenderLinkSection />
        </div>
        {selectedPatient && <RenderPatientInformation />}
      </div>

    </div>
  );
}

const TaskDetailComponent = () => {

  return <div className="wrapper bg-base view-full">
    <div className="d-flex">
      <div className="sidebar bg-white">
        <LogoComponent />
        <SideMainMenuComponent />
        <SideOtherMenuComponent />
      </div>
      <div className="right-part">
        <NavbarComponent />
        <RenderTaskDetailForm />
      </div>
    </div>
    <div>
      <AddAttachmentModal />
      <AddLinkModal />
      <DeleteCommentModal />
    </div>
  </div>
};

export default TaskDetailComponent;
