import classNames from "classnames";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { forgotPassword } from "../../../services/auth";

enum ForgotPassordFormEnum {
  email = "email",
}

export type ForgotPassordForm = {
  [ForgotPassordFormEnum.email]: string;
};

const ForgotPassordFormConfig = {
  [ForgotPassordFormEnum.email]: {
    required: "Please enter your email address",
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: "Please enter a valid email",
    },
  },
};

const ForgotPasswordEmailFormComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPassordForm>();
  const [apiInProgress, setAPIInProgress] = useState<boolean>(false);
  const [serverError, setServerError] = useState();
  const [mailSent, setMailSent] = useState(false);

  const onSubmit: SubmitHandler<ForgotPassordForm> = (data: ForgotPassordForm) => {
    setAPIInProgress(true);
    forgotPassword(data).then(response => {
      setMailSent(true)
    }).catch((error) => {
      setServerError(error)
      setTimeout(() => {
        setServerError(undefined)
      }, 4000)
    }).finally(() => {
      setAPIInProgress(false);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={
        classNames('form-group uniform-space-b-5', {
          'validate-error': errors.email,
        })}>
        <label className="form-label d-flex justify-content-between">
          &nbsp;Email address&nbsp;
          {errors.email && (
            <span className="error-label">{errors.email.message}</span>
          )}
        </label>
        <div className="control-w-icon">
          <i className="icon-position icon-email absolute v-transform-center" />
          <input
            {...register(
              ForgotPassordFormEnum.email,
              ForgotPassordFormConfig[ForgotPassordFormEnum.email]
            )}
            type="email"
            placeholder="Write you email here..."
            className="styled-control"
          />
        </div>
      </div>

      <div className="form-group btn-space-tb-100 mt-5">
        {serverError && <label className="form-label d-flex justify-content-between validate-error">
          <span className="error-label"> <i className="fa fa-exclamation-circle" />
            &nbsp;&nbsp;
            {serverError ? serverError : 'Something went wrong, please try again in sometime.'}
          </span>
        </label>}
        <button className="btn btn-primary w-full" disabled={apiInProgress || mailSent}>
          {mailSent ? 'Mail Sent! Please Check Your Mail' : 'Reset Password'}
        </button>
        <p className="text-center mt-4 font-medium">
          Don’t have an account?
          <Link className="text-link text-underline" to="/signup">
            &nbsp;Register&nbsp;
          </Link>
        </p>
      </div>
    </form>
  );
};

export default ForgotPasswordEmailFormComponent;
