import classNames from "classnames";
import moment from "moment";
import { useEffect, useState } from "react";
import { getImage } from "../../../helpers/assets";
import { useAuth } from "../../../hooks/useAuth";
import { useDebounce } from "../../../hooks/useDebounce";
import { getAllChatRooms } from "../../../services/chat";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { initializeChatRoom, selectChatState, selectRoom, setRooms, setShowArchivedMessages, setShowStarredMessages, updateSearchedRooms } from "../../../store/reducers/chat/chatSlice";

const RoomSelectComponent = () => {
  const dispatch = useAppDispatch();
  const { token, currentUser } = useAuth();
  const { rooms, searchedRooms, refreshChat } = useAppSelector(selectChatState);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(
    () => {
      dispatch(updateSearchedRooms({
        searchTerm: debouncedSearchTerm,
        currentUser,
      }))
    },
    [debouncedSearchTerm, dispatch, currentUser] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    if (token) {
      getAllChatRooms(token).then(response => {
        dispatch(setRooms(response.data));
      });
    }
  }, [dispatch, token, refreshChat])

  return (
    <div className="col-xl-4 col-lg-5">
      <div className="card-block message-userlist " style={{ paddingBottom: "20px" }}>
        <div className="card-header d-flex align-w-icon">
          <h3> Message</h3>
          <div className="action-right dropdown ">
            <div className="d-inline-block v-center" onClick={() => {
              $("#startNewChatModal").modal('show');
            }}>
              <img src={getImage("icon-message.svg")} alt="" />
            </div>
            <span
              className="btn-dots dropdown-toggle"
              id="dropdonw-more"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="btn-moreaction">
                <img src={getImage("icon-more-verticale.svg")} alt="" />
              </div>
            </span>
            <div
              className="dropdown-menu dropdown-menu-right shadow dropdown-items-with-icon"
              aria-labelledby="dropdonw-more"
              style={{}}
            >
              <span
                className="btn-dots dropdown-toggle"
                id="dropdonw-more"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              ></span>
              <span className="dropdown-item" onClick={() => {
                dispatch(setShowStarredMessages(true));
                dispatch(initializeChatRoom());
              }}>
                <img src={getImage("icon-star.svg")} alt="" />
                Starred Messages
              </span>
              <span className="dropdown-item" onClick={() => {
                dispatch(setShowArchivedMessages(true));
                dispatch(initializeChatRoom());
              }}>
                <img src={getImage("icon-archive.svg")} alt="" /> Delete
                Archived
              </span>
            </div>
          </div>
        </div>
        <div className="searcharea">
          <i className="v-transform-center">
            <img src={getImage("icon-search.svg")} alt="" />
          </i>
          <input
            type="text"
            className="input-control"
            placeholder="Search keyword..."
            aria-label="Search"
            aria-describedby="basic-addon2"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <h4> Today </h4>
        {searchedRooms && searchedRooms.length > 0 && searchedRooms.map((room, index) => {
          const user = room.user.id === currentUser?.id ? room.contact : room.user;
          if (!user) {
            return <></>;
          }
          return (
            <ul key={index} className={classNames("message-sender", {
              "unread-message": room.unread_message > 0,
            })} >
              <li onClick={() => {
                dispatch(selectRoom(room));
              }}>
                <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute ">
                  {`${user?.first_name.charAt(0).toUpperCase()}${user?.last_name.charAt(0).toUpperCase()}`}
                </span>
                <div className="left-details">
                  <h4 className=" d-inline-block v-center font-semibold">
                    <strong>
                      {`${user?.first_name} ${user?.last_name}`}
                    </strong>
                    <span className="d-inline-block ms-1">

                      {room.last_message && moment(room.last_message.created_at).fromNow()}
                    </span>
                  </h4>
                  <p className="text-nowrap">
                    {room.last_message && room.last_message.message?.slice(0, 50)}
                    {room.unread_message && room.unread_message > 0 && <span className="badge">{room.unread_message}</span>}
                  </p>
                </div>
              </li>
            </ul>
          )
        }
        )}
        {!searchedRooms && rooms && rooms.map((room, index) => {
          const user = room.user.id === currentUser?.id ? room.contact : room.user;
          return (
            <ul key={index} className={classNames("message-sender", {
              "unread-message": room.unread_message > 0,
            })} >
              <li onClick={() => {
                dispatch(selectRoom(room));
              }}>
                <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute ">
                  {`${user?.first_name.charAt(0).toUpperCase()}${user?.last_name.charAt(0).toUpperCase()}`}
                </span>
                <div className="left-details" style={{ minHeight: "42px" }}>
                  <h4 className=" d-inline-block v-center font-semibold">
                    <strong>
                      {`${user?.first_name} ${user?.last_name}`}
                    </strong>
                    <span className="d-inline-block ms-1">
                      {/* {room.last_message && moment(room.last_message.created_at).fromNow()} */}
                    </span>
                  </h4>
                  {room.last_message &&
                    <p className="text-nowrap">
                      {room.last_message.message?.slice(0, 50)}
                      {room.unread_message && room.unread_message > 0 && <span className="badge">{room.unread_message}</span>}
                    </p>
                  }
                </div>
              </li>
            </ul>
          )
        }
        )}
        {/* <h4 className="mt-5"> Yasterday </h4>
        <ul className="message-sender">
          <li>
            <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute ">
              SM
            </span>
            <div className="left-details">
              <h4 className=" d-inline-block v-center font-semibold">

                <strong> test6 </strong>
                <span className="d-inline-block ms-1">

                  4 hours ago
                </span>
              </h4>
              <p className="text-nowrap">

                Thank you for telling me t...
              </p>
            </div>
          </li>
        </ul>
        <ul className="message-sender">
          <li>
            <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute ">
              SM
            </span>
            <div className="left-details">
              <h4 className=" d-inline-block v-center font-semibold">

                <strong> test7 </strong>
                <span className="d-inline-block ms-1">

                  4 hours ago
                </span>
              </h4>
              <p className="text-nowrap">

                Thank you for telling me t...
              </p>
            </div>
          </li>
        </ul>
        <ul className="message-sender">
          <li>
            <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute ">
              SM
            </span>
            <div className="left-details">
              <h4 className=" d-inline-block v-center font-semibold">

                <strong> test7 </strong>
                <span className="d-inline-block ms-1">

                  4 hours ago
                </span>
              </h4>
              <p className="text-nowrap">

                Thank you for telling me t...
              </p>
            </div>
          </li>
        </ul> */}
      </div>
    </div >
  );
}




export default RoomSelectComponent;
