import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, Location } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { ESelectedMenu } from "../pages/Dashboard/enums";
import TextBodyComponent from "../pages/Text";
import ContactListComponent from "./ContactList";
import EmailBodyComponent from "./EmailBodyComponent";
import MainDashboardContainer from "./MainDashboardContainer";
import TaskListTableComponent from "./TaskListTableComponent";

const BodyContainer = () => {
  let location: Location = useLocation();
  const googleTokenKey = "google-access-token";
  const [IsNylasConnected, setHasGoogleAccessToken] = useState<boolean>(false);
  const { currentUser } = useAuth();
  const [cookies, setCookie] = useCookies([googleTokenKey]);
  const [selectedMenu, setSelectedMenu] = useState<ESelectedMenu>(ESelectedMenu.DASHBOARD);

  useEffect(() => {
    if (location?.state?.selectedMenu) {
      setSelectedMenu(location.state.selectedMenu)
    }
  }, [location])

  useEffect(() => {
    setHasGoogleAccessToken(cookies[googleTokenKey]);
  }, [cookies])

  if (!currentUser) {
    return <h1>Loading...</h1>
  }

  switch (selectedMenu) {
    case ESelectedMenu.DASHBOARD:
      return <MainDashboardContainer />
    case ESelectedMenu.TASKS:
      return (
        <div className="form-content-area">
          <TaskListTableComponent />
        </div>
      )
    case ESelectedMenu.EMAIL:
      return (
        <EmailBodyComponent IsNylasConnected={currentUser.nylas_connected} />
      )
    case ESelectedMenu.TEXTS:
      return (
        <TextBodyComponent />
      )
    case ESelectedMenu.CONTACTS:
      return (
        <ContactListComponent IsNylasConnected={currentUser.nylas_connected} />
      )
    default:
      return <h1>{selectedMenu} is not implemented</h1>
  }
}


export default BodyContainer;
