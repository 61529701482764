import moment from "moment";
import { useEffect } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { getArchivedChatRooms } from "../../../services/chat";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IChatRoom, selectChatState, selectRoom, setArchivedRooms, setShowArchivedMessages } from "../../../store/reducers/chat/chatSlice";

const RenderArchivedRoom = ({ room }: { room: IChatRoom }) => {
  const dispatch = useAppDispatch();
  const contact = room?.contact;

  const onArchivedRoomClicked = async (room: IChatRoom) => {
    await dispatch(selectRoom(room))
  }

  return (
    <ul className="message-sender unread-message" onClick={() => onArchivedRoomClicked(room)}>
      <li>
        <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute ">
          {`${contact?.first_name.charAt(0).toUpperCase()}${contact?.last_name.charAt(0).toUpperCase()}`}
        </span>
        <div className="left-details">
          <h4 className=" d-inline-block v-center font-semibold">
            <strong> {`${contact?.first_name} ${contact?.last_name}`} </strong>
            <span className="d-inline-block ms-1"> {moment(room.archived_at).fromNow()} </span>
          </h4>
          <p className="text-nowrap">
            {room.last_message.message?.slice(0, 50)}
            {room.unread_message && room.unread_message > 0 && <span className="badge">{room.unread_message}</span>}
          </p>
        </div>
      </li>
    </ul>
  )
}

const ArchivedMessagesComponent = () => {
  const dispatch = useAppDispatch();
  const { token } = useAuth();
  const { archivedRooms } = useAppSelector(selectChatState);

  useEffect(() => {
    if (token) {
      getArchivedChatRooms(token).then(response => {
        dispatch(setArchivedRooms(response.data));
      })
    }
  })

  if (!archivedRooms) {
    return <></>
  }
  return (
    <div className="col-xl-3 col-lg-5">
      <div className="card-block message-userlist ">
        <div className="card-header d-flex align-w-icon" onClick={() => dispatch(setShowArchivedMessages(false))}>
          <button className="btn btn-action text-primary btn-sm btn-back btn-w-icon font-bold x-space ">
            <i className=" fa fa-angle-left" /> Archived Message{" "}
          </button>
        </div>
        
        {
          archivedRooms.map((room: IChatRoom) => {
            return (
              <RenderArchivedRoom room={room} key={`archived-room-${room.id}`} />
            )
          })
        }
      </div>
    </div>
  );
}

export default ArchivedMessagesComponent;
