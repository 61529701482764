import { AxiosError, AxiosResponse, HttpStatusCode } from "axios";
import classNames from "classnames";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import { Link } from "react-router-dom";
import { json } from "stream/consumers";
import { getImage } from "../../../helpers/assets";
import { useAuth } from "../../../hooks/useAuth";
import { sendOtp, signup } from "../../../services/auth";
import { useAppDispatch } from "../../../store/hooks";
import { updateUser, updateToken, updateVerificationType } from "../../../store/reducers/user/userSlice";

enum SignupFormEnum {
  fullName = "fullName",
  email = "email",
  phoneNumber = "phone_no",
  clinicName = "clinic_name",
  password = "password",
  emailCode = "email_code",
  smsCode = "sms_code",
}

export type SignupForm = {
  [SignupFormEnum.fullName]: string;
  [SignupFormEnum.email]: string;
  [SignupFormEnum.phoneNumber]: number;
  [SignupFormEnum.clinicName]: string;
  [SignupFormEnum.password]: string;
  [SignupFormEnum.emailCode]: boolean;
  [SignupFormEnum.smsCode]: boolean;
};

const SignupFormConfig = {
  [SignupFormEnum.fullName]: {
    required: "Please provide full name",
  },
  [SignupFormEnum.email]: {
    required: "Please enter your email address",
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: "Please enter a valid email",
    },
  },
  [SignupFormEnum.phoneNumber]: {
    required: "Please enter your phone number",
  },
  [SignupFormEnum.clinicName]: {
    required: "Please enter clinic name",
  },
  [SignupFormEnum.password]: {
    required: "Please enter password",
    pattern: {
      value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      message: `Password must have minimum eight characters, at least one letter, one number and one special character`,
    },
  },
};

const SignupWithEmailFormComponent = () => {
  const dispatch = useAppDispatch()
  const {
    register,
    getValues,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<SignupForm>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [signupInProgress, setSignupInProgress] = useState<boolean>(false);
  const [serverError, setServerError] = useState<any>();

  const emailCodeConfig = {
    validate: (value: boolean) => {
      const { sms_code } = getValues();
      if (!value && !sms_code) {
        return 'Please select any one mode to get verification code'
      }
    }
  }
  const smsCodeConfig = {
    validate: (value: boolean) => {
      const { email_code } = getValues();
      if (!value && !email_code) {
        return 'Please select any one mode to get verification code'
      }
    }
  }


  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const onSubmit: SubmitHandler<SignupForm> = (data: any) => {
    setSignupInProgress(true)
    signup(data).then((response: AxiosResponse) => {
      const { token, user, verificationType } = response.data;
      dispatch(updateUser(user));
      dispatch(updateToken(token));
      dispatch(updateVerificationType(verificationType));
      sendOtp(token, {
        phone_no: user.phone_no
      }).then(() => {
        $('#modal-otp').modal("show");
      })
    }).catch((error: AxiosError) => {
      const status = error.response?.status;
      if (error.code === "ERR_BAD_REQUEST" && status === 400) {
        const data: { [key: string]: any } = error.response?.data as { [key: string]: any };
        if (data) {
          Object.keys(data).map(key => {
            let message = data[key][0];
            message = message.replaceAll('.', '');
            message = message[0].toUpperCase() + message.slice(1);
            setError(key as SignupFormEnum, {
              message
            });
          });
        }
      }
      setTimeout(() => {
        setServerError(undefined)
      }, 4000)
    }).finally(() => {
      setSignupInProgress(false)
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={
        classNames('form-group uniform-space-b-5', {
          'validate-error': errors.fullName,
        })}>
        <label className="form-label d-flex justify-content-between">
          &nbsp;Full Name&nbsp;
          {errors.fullName && errors.fullName.message && (
            <span className="error-label"> {errors.fullName.message} </span>
          )}
        </label>
        <div className="control-w-icon">
          <i className="icon-position icon-user absolute v-transform-center" />
          <input
            {...register(
              SignupFormEnum.fullName,
              SignupFormConfig[SignupFormEnum.fullName]
            )}
            type="text"
            className="styled-control"
            placeholder="Write you name here..."
          />
        </div>
      </div>
      <div className={
        classNames('form-group uniform-space-b-5', {
          'validate-error': errors.email,
        })}>
        <label className="form-label d-flex justify-content-between">
          &nbsp;Email address&nbsp;
          {errors.email && (
            <span className="error-label">{errors.email.message}</span>
          )}
        </label>
        <div className="control-w-icon">
          <i className="icon-position icon-email absolute v-transform-center" />
          <input
            {...register(
              SignupFormEnum.email,
              SignupFormConfig[SignupFormEnum.email]
            )}
            type="email"
            placeholder="Write you email here..."
            className="styled-control"
          />
        </div>
      </div>
      <div className={
        classNames('form-group uniform-space-b-5', {
          'validate-error': errors.phone_no,
        })}>
        <label className="form-label d-flex justify-content-between">
          &nbsp;Phone Number&nbsp;
          {errors.phone_no && (
            <span className="error-label">{errors.phone_no.message}</span>
          )}
        </label>
        <Controller
          control={control}
          name={SignupFormEnum.phoneNumber}
          rules={SignupFormConfig[SignupFormEnum.phoneNumber]}
          render={({ field: { onChange, value } }) => (
            <PhoneInput
              className="styled-control2 control-sm signup"
              value={value as unknown as string}
              onChange={onChange}
              defaultCountry="IN"
              placeholder="Enter Phone Number"
              id={SignupFormEnum.phoneNumber}
            />
          )}
        />
      </div>
      <div className={
        classNames('form-group uniform-space-b-5', {
          'validate-error': errors.clinic_name,
        })}>
        <label className="form-label d-flex justify-content-between">
          &nbsp;Clinic Name&nbsp;
          {errors.clinic_name && (
            <span className="error-label">{errors.clinic_name.message}</span>
          )}
        </label>
        <div className="control-w-icon">
          <i className="icon-position icon-clinic-name absolute v-transform-center" />
          <input
            {...register(
              SignupFormEnum.clinicName,
              SignupFormConfig[SignupFormEnum.clinicName]
            )}
            type="text"
            className="styled-control"
            placeholder="Write you company name here..."
          />
        </div>
      </div>
      <div className={
        classNames('form-group uniform-space-b-5', {
          'validate-error': errors.password,
        })}>
        <label className="form-label d-flex justify-content-between">
          &nbsp;Password&nbsp;
          {errors.password && (
            <span className="error-label"> {errors.password.message} </span>
          )}
        </label>
        <div className="control-w-icon">
          <i className="icon-position icon-password absolute v-transform-center" />
          <span className="password-icon absolute right-15 v-transform-center" onClick={handleShowPassword}>
            <img src={getImage("show-password.svg")} alt="show-pwd-icon" />
          </span>
          <input
            type={showPassword ? 'text' : 'password'}
            {...register(
              SignupFormEnum.password,
              SignupFormConfig[SignupFormEnum.password]
            )}
            className="styled-control"
            placeholder="Write you password here..."
          />
        </div>
      </div>
      <div className="form-group">
        <p className="heighlited-text">
          For security and 2-factor authentication, do you prefer a code to
          emailed to you or sent via SMS/text to your phone?
        </p>

        <div className="form-check d-inline-block">
          <label className="form-check-label" htmlFor="emailMe">
            <input
              className="form-check-input"
              type="radio"
              id="emailMe"
              {...register(
                SignupFormEnum.emailCode,
                emailCodeConfig
              )}
            />
            Email Me
          </label>
        </div>
        <div className="form-check d-inline-block ms-3">
          <label className="form-check-label" htmlFor="textMe">
            <input
              className="form-check-input"
              type="radio"
              id="textMe"
              {...register(
                SignupFormEnum.smsCode,
                smsCodeConfig
              )}
            />
            &nbsp;Text Me&nbsp;
          </label>
        </div>
        {errors.email_code &&
          <>
            <br />
            <label
              className={
                classNames('form-label', {
                  'validate-error': errors.email,
                })}>
              {errors.password && (
                <span className="error-label"> {errors.email_code?.message}  </span>
              )}
            </label>
          </>
        }
        {errors.sms_code &&
          <>
            <br />
            <label
              className={
                classNames('form-label', {
                  'validate-error': errors.sms_code,
                })}>
              {errors.password && (
                <span className="error-label"> {errors.sms_code?.message}  </span>
              )}
            </label>
          </>
        }
      </div>
      <div className="form-group btn-space-tb-100">
        {serverError && <label className="form-label d-flex justify-content-between validate-error">
          <span className="error-label"> {
            serverError ?
              Object.keys(serverError).map((key: string) => {
                let message = `${(serverError as any)[key]}`;
                return <>
                  {message.charAt(0).toUpperCase() + message.slice(1)}
                  <br />
                </>;
              }) : 'Something Went Wrong'
          } </span>
        </label>}
        <button className="btn btn-primary w-full" disabled={signupInProgress}>Subscribe Now</button>
        <p className="text-center mt-4 font-medium">
          Already have an account?
          <Link className="text-link text-underline" to="/login">
            &nbsp;Login&nbsp;
          </Link>
        </p>
      </div>
    </form>
  );
};

export default SignupWithEmailFormComponent;
