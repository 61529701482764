import './App.css';
import { Link } from 'react-router-dom';

const Break = ({ times }: { times: number }) => {
  const data = [];
  for (let i = 0; i < times; i++) {
    data.push(i);
  }
  return (
    <>{data.map((item) => <br key={item} />)}</ >
  );
}

function App() {
  return (
    <div className="App">
      <Break times={5} />
      <Link className="button" to="/signup">
        <button>
          Signup Component
        </button>
      </Link>
      <Break times={2} />
      <Link className="button" to="/login">
        <button>
          Login Component
        </button>
      </Link>
      <Break times={2} />
      <Link className="button" to="/dashboard">
        <button>
          Dashboard Component
        </button>
      </Link>
    </div>
  );
}

export default App;
