import { useEffect, useRef } from "react";
import { useAuth } from "../../hooks/useAuth";
import { getAllMessagesForRoom } from "../../services/chat";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { addMessage, deleteMessageFromState, IChatRoom, removeLastMessage, selectChatState, setChatMessages, setClient } from "../../store/reducers/chat/chatSlice";
import AddNewChatModal from "./components/AddNewChatModal";
import ChatBodyComponent from "./components/ChatBodyComponent";
import ChatFooterComponent from "./components/ChatFooter";
import ChatHeaderComponent from "./components/ChatHeader";
import RoomSelectComponent from "./components/RoomSelectComponent";
import ArchivedMessagesComponent from "./components/ArchivedMessagesComponent";
import StarredMessagesComponent from "./components/StarredMessagesComponent";
import "./index.css";
import ForwardMessageModal from "./components/ForwardMessageModal";

function usePrevious(value: IChatRoom | undefined | null) {
  const ref = useRef<IChatRoom | undefined | null>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const TextBodyComponent = () => {
  const dispatch = useAppDispatch()
  const { token } = useAuth();
  const { client, currentRoom, showStarredMessages, showArchivedMessages } = useAppSelector(selectChatState);
  let prevCurrentRoom: IChatRoom | null | undefined;
  prevCurrentRoom = usePrevious(currentRoom);

  useEffect(() => {

    if (
      (!prevCurrentRoom && currentRoom) ||
      (prevCurrentRoom && currentRoom && prevCurrentRoom?.id !== currentRoom?.id)
    ) {
      let localClient;
      if (!showStarredMessages && !showArchivedMessages) {
        localClient = new WebSocket(
          `${process.env.REACT_APP_PATIENT_WS_BASE_URL}/chat/${currentRoom?.id}/`
        );
        localClient.onmessage = (e: any) => {
          const data = JSON.parse(e.data);
          const action = data.action;
          const message = data.message;
          if (message) {
            if (action === "ADD_MESSAGE") {
              dispatch(
                addMessage({
                  id: message.id,
                  message: message.message,
                  created_at: message.created_at,
                  user: message.user,
                  starred: message.starred,
                  message_type: message.message_type,
                  room: message.room,
                  reply_to: message.reply_to,
                })
              );
            } else if (action === "REMOVE_LAST_MESSAGE") {
              dispatch(removeLastMessage());
            } else if (action === "DELETE_MESSAGE") {
              dispatch(deleteMessageFromState(message.id));
            }
          }
        };
      }
      if (token && currentRoom) {
        getAllMessagesForRoom(token, currentRoom.id).then(response => {
          dispatch(setChatMessages(response.data));
        });
      }
      if (!showStarredMessages && !showArchivedMessages) {
        dispatch(setClient(localClient));
      }
    }
  }, [currentRoom, dispatch, prevCurrentRoom, showArchivedMessages, showStarredMessages, token])


  return (
    <div className="form-content-area">
      <div className="row">
        {showStarredMessages && <StarredMessagesComponent />}
        {showArchivedMessages && <ArchivedMessagesComponent />}
        {(!showStarredMessages && !showArchivedMessages) && <RoomSelectComponent />}
        <div className="col-xl-8 col-lg-7 message-pannel">
          {currentRoom &&
            <div className="card-block ">
              <ChatHeaderComponent />
              <ChatBodyComponent />
              <ChatFooterComponent />
            </div>
          }
        </div>
      </div>
      <ForwardMessageModal />
      <AddNewChatModal />
    </div>
  );
}

export default TextBodyComponent;
