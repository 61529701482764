import { getImage } from "../../../helpers/assets";
import { useAppDispatch } from "../../../store/hooks";

const RenderRelatedCalls = () => {
  const dispatch = useAppDispatch();
  return (
    <div className="card-block uniform-space-b-4 ">
      <div className="accordion" id="accordionnotesAttachments">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFive">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseTwo">
              Related Calls
            </button>
          </h2>
          <div id="collapseFive" className="accordion-collapse collapse show" aria-labelledby="headingFive" data-bs-parent="#accordionnotesAttachments">
            <div className="accordion-body">
              <div className=" table-custome">
                <table className="table datatable responsive-mobile-table">
                  <thead>
                    <tr>
                      <th scope="col"> <span> Subject </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i>
                      </th>
                      <th scope="col"> <span> Call type </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i>
                      </th>
                      <th scope="col"> <span> CAll start Time </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i>
                      </th>
                      <th scope="col"> <span> duration </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i>
                      </th>
                      <th scope="col"> <span> assigned to </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i>
                      </th>
                      <th scope="col"> <span> patient Name </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-cell="Subject"> <span className="text-primary d-inline-block v-center font-semibold">
                        <a href="#" className="no-underline"> Outgoing call
                          to Kris Marrier (Follow up about Doctors)
                        </a></span> </td>
                      <td data-cell="Call type"> <span className="number-badge badege-gray me-1"> <img src={getImage("icon-phone-dark.svg")} alt="" />
                        Outbound </span> </td>
                      <td data-cell="CAll start Time ">Mar 27, 2022 12:10 PM
                      </td>
                      <td data-cell="Duration">20:00 </td>
                      <td data-cell="assigned to"> <span className="number-badge badege-primary ms-1"> Jane
                        Cooper </span> <span className="number-badge badege-primary ms-1"> +2
                        </span> </td>
                      <td data-cell="patient Name "> <span className="text-primary font-semibold"> <span className="number-badge badege-primary me-1"> MT
                      </span> Mitsue Tollner </span> </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderRelatedCalls;
