import { useState } from "react";
import { Modal } from "../../../components/ModalComponent";
import { getImage } from "../../../helpers/assets";
import { useAuth } from "../../../hooks/useAuth";
import { addNote, deleteNote } from "../../../services/tasks";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { addNoteToState, removeNoteFromState, selectTaskState } from "../../../store/reducers/task/taskSlice";

const RenderNotesSection = () => {
  const dispatch = useAppDispatch();
  const taskDetail = useAppSelector(selectTaskState);
  const { token, currentUser } = useAuth();
  const { id: taskId, notes } = taskDetail;
  const [newNote, setNewNote] = useState<string>();
  const [selectedNoteId, setSelectedNoteId] = useState<number>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);


  const addNewNote = () => {
    if (!taskId || !token || !newNote) return;
    addNote(token, taskId, {
      message: newNote,
      user: currentUser?.id,
    }).then((response) => {
      dispatch(addNoteToState({
        id: response.data.note_id,
        message: newNote,
        user: currentUser
      }));
      setNewNote('');
    });
  }

  const handleDeleteNote = (noteId: number | undefined) => {
    if (!taskId || !token || !noteId) return;
    deleteNote(token, taskId, noteId).then((response) => {
      dispatch(removeNoteFromState(noteId));
    });
  }

  const handleHideModal = () => {
    setShowModal(false);
  }

  const handleShowModal = () => {
    setShowModal(true);
  }


  return (
    <div className="card-block uniform-space-b-4 ">
      <div className="accordion" id="accordionnotesNotes">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseTwo"> Notes </button>
          </h2>
          <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionnotesNotes">
            <div className="accordion-body">
              {/* <button className="btn btn-default btn-block" onClick={handleShowModal}>Open Modal</button>
              {showModal}
              <Modal show={showModal} hideModal={handleHideModal}>Modal content</Modal> */}
              <div className="form-group row  uniform-space-b-5">
                <ul className="list-notes">
                  {notes && notes.map((note, index) =>
                    <li key={index}>
                      <span className="number-badge name-placeholder name-placeholder-position badege-pink ms-1 text-uppercase absolute "> SM </span>
                      <div className="left-details">
                        <h4 className="text-primary d-inline-block v-center font-semibold"> {`${note?.user?.first_name} ${note?.user?.last_name}`} <span className="d-inline-block ms-1"> 4 hours ago </span>
                        </h4>
                        <p className="badge-cpsule"> {note.message} </p>
                      </div>
                      <div className="action-right dropdown  ">
                        <div className="btn-dots dropdown-toggle" id="dropdonw-more" data-bs-toggle="dropdown" aria-expanded="false">
                          <div className="btn-moreaction">
                            <img src={getImage("three-dots.svg")} alt="" />
                          </div>
                        </div>
                        <div className="dropdown-menu dropdown-menu-right shadow dropdown-items-with-icon " aria-labelledby="dropdonw-more">
                          <div className="btn-dots dropdown-toggle" id="dropdonw-more" data-bs-toggle="dropdown" aria-expanded="false"></div>
                          <div className="dropdown-item">
                            <img src={getImage("icon-edit.png")} alt="" /> Edit Comment (WIP)
                          </div>
                          <div onClick={() => handleDeleteNote(note.id)}
                            //  className="dropdown-item" data-bs-toggle="modal" data-bs-target="#deleteModal">
                            className="dropdown-item" data-bs-toggle="modal">
                            <img src={getImage("icon-delete.png")} alt="" /> Delete Comment
                          </div>
                          <div className="dropdown-item">
                            <img src={getImage("icon-link.svg")} alt="" /> Copy link to comment
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
              <div className="form-group row  uniform-space-b-5">
                <div className="col-sm-10 relative">
                  <div className="over-input-icon">
                    <span>
                      <img src={getImage("attach_file.png")} alt="" />
                    </span>
                    <span>
                      <img src={getImage("insert_emoticon.png")} alt="" />
                    </span>
                  </div>
                  <input
                    type="text" placeholder="Write a note here" className="styled-control control-sm chat-input"
                    onChange={(e) => {
                      setNewNote(e.target.value);
                    }} />
                </div>
                <div className="col-sm-2">
                  <button className="btn btn-primary btn-sm padd-x-20  w-full" onClick={addNewNote}> Send </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderNotesSection;
