import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectTaskState, updateHiddenDetails } from "../../../store/reducers/task/taskSlice";

const RenderHiddenSection = () => {
  const dispatch = useAppDispatch();
  const { hiddenDetails } = useAppSelector(selectTaskState);
  return (
    <div className="card-block uniform-space-b-4 ">
      <div className="accordion" id="accordionHidenDetails">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo"> Hiden Details </button>
          </h2>
          <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionHidenDetails">
            <div className="accordion-body">
              <div className="form-group row  uniform-space-b-5">
                <div className="col-sm-4 ">
                  <label className="form-label "> Description <span className="d-block sub-label"> Keep track of notes you don’t want to be visible in other areas </span>
                  </label>
                </div>
                <div className="col-sm-8">
                  <textarea className="styled-control" defaultValue={hiddenDetails} onChange={(e) => {
                    dispatch(updateHiddenDetails(e.target.value))
                  }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderHiddenSection;
