import BodyComponent from "../../components/BodyComponent";
import EmailEditorComponent from "../../components/EmailEditor";
import LogoComponent from "../../components/LogoComponent";
import NavbarComponent from "../../components/NavbarComponent";
import SideMainMenuComponent from "../../components/SideMainMenuComponent";
import SideOtherMenuComponent from "../../components/SideOtherMenuComponent";
import "./index.css";

const DashboardComponent = () => {
  return (
    <div className="wrapper bg-base view-full" style={{
      overscrollBehavior: "contain",
    }}>
      <div className="d-flex">
        <div className="sidebar bg-white">
          <LogoComponent />
          <SideMainMenuComponent />
          <SideOtherMenuComponent />
        </div>
        <div className="right-part">
          <NavbarComponent />
          <BodyComponent />
        </div>
      </div>
      <EmailEditorComponent />
    </div>
  );
};

export default DashboardComponent;
