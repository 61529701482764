import { useEffect } from "react";
import { getImage } from "../helpers/assets";
import { useAuth } from "../hooks/useAuth";
import { useCalling } from "../hooks/useCall";
import { logout } from "../services/auth";

const NavbarComponent = () => {
  const { logoutUser, token, currentUser } = useAuth();
  const { timer, showOngoingCall, callingNumber, callingUser, callingId, handleCallEndClick } = useCalling();


  const handleLogoutUser = () => {
    if (token) {
      logout(token).then(response => {
        logoutUser && logoutUser();
        window.location.href = "/";
      })
    }
  }

  return (
    <nav className="navbar navbar-light bg-white  ">
      <div className="container-fluid">
        <div className="logo-area mobile-show">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button> <img src={getImage('logo.svg')} alt="" />
        </div>
        <div className="searcharea ">
          <i className="v-transform-center"> <img src={getImage('icon-search.svg')} alt="" /> </i>
          <input type="text" className="input-control" placeholder="Search keyword..." aria-label="Search" aria-describedby="basic-addon2" />
        </div>
        <ul className="navbar-nav ml-auto navbar-inline">
          {/* Nav Item - Alerts */}
          {showOngoingCall &&
            <>
              <li className="nav-item call-going ms-5">
                <div className="call-going-in">
                  <h4>{callingUser} </h4>
                  <p> {callingNumber} • {timer || '00:00:00'} </p>
                  <button onClick={() => handleCallEndClick(callingId)}> <img src={getImage('icon-ongoing-call-white.svg')} alt="" /> </button>
                </div>
              </li>
              <li className="nav-item call-going ms-2">
                <button className="active-call-going" disabled> <img src={getImage('active-call-white.svg')} alt="" /> </button>
              </li>
            </>
          }

          <li className="nav-item dropdown no-arrow mx-1">
            <a className="nav-link dropdown-toggle" href="#" id="notifydropdwn" data-bs-toggle="dropdown" aria-expanded="false">
              <span className="round-container relative">
                <img src={getImage('icon-bell.svg')} alt="" />
                <span className="badge badge-danger badge-counter">3+</span>
              </span>
            </a>
            <ul className="dropdown-menu dropdown-menu-right dropdown-list notify-dropdown shadow animated--grow-in " aria-labelledby="notifydropdwn">
              <li> <a className="dropdown-item d-flex align-items-center" href="#">
                <div className="mr-3">
                  <div className="icon-circle bg-primary">
                    <i className="fas fa-file-alt text-white" />
                  </div>
                </div>
                <div>
                  <p className="small text-gray-500">December 12, 2019</p>
                  <p className="font-weight-bold">A new monthly report is ready to
                    download!</p>
                </div>
              </a>
              </li>
            </ul>
          </li>
          {/* Nav Item - User Information */}

          <li className="nav-item dropdown no-arrow media-block">
            <a className="nav-link dropdown-toggle" href="#" id="userDropdown" data-bs-toggle="dropdown" aria-expanded="false">
              <div className="d-inline-flex align-items-center ">
                <div className="flex-shrink-0">
                  <img src={getImage("img-ellipse-empty.png")} alt="profile" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <h4> {currentUser?.first_name} {currentUser?.middle_name} </h4>
                  <p> Admin staff </p>
                </div>
              </div>
            </a>
            {/* Dropdown - User Information */}
            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
              <a className="dropdown-item" href="#">
                Profile
              </a>
              <a className="dropdown-item" href="#">
                Settings
              </a>
              <a className="dropdown-item" href="#">
                Activity Log
              </a>
              <div className="dropdown-divider" />
              <div className="dropdown-item" data-toggle="modal" data-target="#logoutModal" onClick={handleLogoutUser}>
                Logout
              </div>
            </div>
          </li>

        </ul>
      </div>
    </nav>
  );
};

export default NavbarComponent;
