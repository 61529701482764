import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../configure";

export type IUserDetails = {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  phone_no: string;
  clinic_name: string;
  user_type: string;
  registration_type: string;
  nylas_connected: boolean;
  verified: boolean;
};


export interface IUserState {
  user?: IUserDetails;
  token?: string;
  verificationType?: string;
  verificationStatus?: boolean;
}

export const initialState: IUserState = {
  verificationStatus: true,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, data) => {
      state.user = data.payload;
    },
    updateToken: (state, data) => {
      state.token = data.payload;
    },
    updateVerificationType: (state, data) => {
      state.verificationType = data.payload;
    },
    updateVerificationStatus: (state, data) => {
      state.verificationStatus = data.payload;
    },
  },
});

export const { 
  updateUser,
  updateToken,
  updateVerificationType,
  updateVerificationStatus,
} = userSlice.actions;

export const selectUserState = (state: RootState) => state.user;

export default userSlice.reducer;
