import { getImage } from "../helpers/assets";


const AuthNavbarComponent = () => {
    return (
        <nav className="navbar navbar-light bg-transparent">
            <a className="navbar-brand" href="/todo">
                <img src={getImage("logo.svg")} alt="logo" />
            </a>
        </nav>
    );
};

export default AuthNavbarComponent;
