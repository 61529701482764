import moment from "moment";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { endCall, initiateCall, selectCallState } from "../store/reducers/call/callSlice";
import { MESSAGE_TYPE, selectChatState } from "../store/reducers/chat/chatSlice";

import { singletonHook } from 'react-singleton-hook';
import { getInstance } from "voximplant-websdk";
import { Client } from "voximplant-websdk/Client";
import { useAuth } from "./useAuth";
import { callLog } from "../services/calls";
import { sendMessage } from "../services/chat";


export enum CALL_STATES {
  INITIATED = "INITIATED",
  NOT_PICKED = "NOT_PICKED",
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
}

const useVoxImplantSDKImpl = () => {
  const sdk = getInstance();
  useEffect(() => {
    sdk.connect().then(() => console.log('Connected'))
    sdk.init({
      enableTrace: true,
      showDebugInfo: true,
      micRequired: true,
    }).then(() => console.log('Logged In!'));
  }, [])

  return {
    sdk
  };
};

export const useVoxImplantSDK = singletonHook(null, useVoxImplantSDKImpl);

export const useCalling = () => {
  const dispatch = useAppDispatch();
  const { token, currentUser } = useAuth();
  const [callingId, setCallingId] = useState<string | number>();
  const voximplantSDK = useVoxImplantSDK()
  const { client, currentRoom, replyTo } = useAppSelector(selectChatState);
  let sdk: Client;
  if (voximplantSDK) {
    sdk = voximplantSDK.sdk;
  }
  const { showOngoingCall, number: callingNumber, name: callingUser, startTime } = useAppSelector(selectCallState);
  const [timer, setTimer] = useState<string>();

  const handleCallClick = (phoneNo: string, username: string, contactId: string | number) => {
    if (token && currentUser) {
      const callSettings = { number: phoneNo };
      const call = sdk.call(callSettings)
      const startTime = new Date();

      setCallingId(contactId)
      if (currentRoom) {
        callLog(token, currentUser.id, contactId, CALL_STATES.INITIATED, {
          start_time: startTime.toISOString()
        }, currentRoom.id).then((response) => {
          if (client) {
            client.send(
              JSON.stringify({
                action: "ADD_MESSAGE",
                message: response.data.call_log
              })
            );
            dispatch(initiateCall({
              showOngoingCall: true,
              number: phoneNo,
              startTime: startTime,
              name: username.trim()
            }))
          }
        })
      }
    }
  }

  const handleCallEndClick = (contactId?: string | number) => {
    if (token && currentRoom?.contact.phone_no && currentUser) {
      const endTime = new Date();
      callLog(token, currentUser.id, contactId, CALL_STATES.DISCONNECTED, {
        end_time: endTime.toISOString()
      }, currentRoom.id).then((response) => {
        if (client) {
          client.send(
            JSON.stringify({
              action: "REMOVE_LAST_MESSAGE"
            })
          );
          client.send(
            JSON.stringify({
              action: "ADD_MESSAGE",
              message: response.data.call_log
            })
          );
        }
      })
    }
    dispatch(endCall())
  }

  useEffect(() => {
    let timerInterval: NodeJS.Timeout | string | number | undefined;
    if (startTime) {
      timerInterval = setInterval(function () {
        let diff = moment(new Date()).diff(moment(startTime, "YYYY-MM-DDTHH:mm:ss"));
        let time = moment.utc(diff).format("HH:mm:ss");
        setTimer(time);
      }, 1000);
    }
    return () => {
      clearInterval(timerInterval)
    }
  }, [startTime])


  return {
    timer,
    startTime,
    showOngoingCall,
    callingNumber,
    callingUser,
    callingId,
    handleCallClick,
    handleCallEndClick,
  };
};
