import { getImage } from "../../../helpers/assets";
import { useAppSelector } from "../../../store/hooks";
import { selectTaskState } from "../../../store/reducers/task/taskSlice";
import { format } from 'date-fns'
import { formatBytes } from "../../../utils/file";

const RenderAttachmentSection = () => {
  const { attachments } = useAppSelector(selectTaskState);
  return (
    <div className="card-block uniform-space-b-4 ">
      <div className="accordion" id="accordionnotesAttachments">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseTwo"> Attachments </button>
          </h2>
          <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionnotesAttachments">
            <div className="accordion-body">
              <div className=" table-custome">
                <table className="table datatable responsive-mobile-table">
                  <thead>
                    <tr>
                      <th scope="col">
                        <span> FIle Name </span>
                        <i className="short-arrow">
                          <img src={getImage("short-arrow.svg")} alt="" />
                        </i>
                      </th>
                      <th scope="col">
                        <span> Attached By </span>
                        <i className="short-arrow">
                          <img src={getImage("short-arrow.svg")} alt="" />
                        </i>
                      </th>
                      <th scope="col">
                        <span> Date Added </span>
                        <i className="short-arrow">
                          <img src={getImage("short-arrow.svg")} alt="" />
                        </i>
                      </th>
                      <th scope="col">
                        <span> Size </span>
                        <i className="short-arrow">
                          <img src={getImage("short-arrow.svg")} alt="" />
                        </i>
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {attachments && attachments.map((attachment, index) =>
                      <tr>
                        <td data-cell="FIle Name">
                          <span className="text-primary d-inline-block v-center font-semibold"> {attachment.filename}</span>
                        </td>
                        <td data-cell="Attached By">
                          <span className="text-primary d-inline-block v-center font-semibold"> {`${attachment.user.first_name} ${attachment.user.last_name}`} </span>
                        </td>
                        <td data-cell="Date Added "> {format(new Date(attachment.created_at), 'MMM dd, yyyy')} </td>
                        <td data-cell="Size "> {formatBytes(attachment.size)} </td>
                        <td className="py-2 text-right">
                          <div className="action-right dropdown  ">
                            <div className="btn-dots dropdown-toggle" id="dropdonw-more" data-bs-toggle="dropdown" aria-expanded="false">
                              <div className="btn-moreaction btn-sm">
                                <img src={getImage("three-dots.svg")} alt="" />
                              </div>
                            </div>
                            <div className="dropdown-menu dropdown-menu-right shadow dropdown-items-with-icon " aria-labelledby="dropdonw-more">
                              <div className="btn-dots dropdown-toggle" id="dropdonw-more" data-bs-toggle="dropdown" aria-expanded="false"></div>
                              <div className="dropdown-item">
                                <img src={getImage("icon-edit.png")} alt="" /> Edit
                              </div>
                              <div className="dropdown-item">
                                <img src={getImage("icon-delete.png")} alt="" /> Delete
                              </div>
                              <div className="dropdown-item">
                                <img src={getImage("icon-link.svg")} alt="" /> Copy
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="justify-content-end d-flex">
                <button className="btn btn-outline-primary btn-smaller  min-w-120" data-bs-toggle="modal" data-bs-target="#attachedModal">
                  <i className="fa fa-plus " /> Attach </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderAttachmentSection;
