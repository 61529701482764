import classNames from "classnames";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { getImage } from "../../../helpers/assets";
import { useAuth } from "../../../hooks/useAuth";
import { resetPassword } from "../../../services/auth";

enum ResetPasswordFormEnum {
  password = "password",
}

export type ResetPasswordForm = {
  [ResetPasswordFormEnum.password]: string;
};

const ResetPasswordFormConfig = {
  [ResetPasswordFormEnum.password]: {
    required: "Please enter password",
  },
};

const ResetPasswordFormComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ResetPasswordForm>();
  const { loginUser } = useAuth();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>();
  const [serverError, setServerError] = useState();
  const [apiInProgress, setAPIInProgress] = useState<boolean>(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const onSubmit: SubmitHandler<ResetPasswordForm> = (data: ResetPasswordForm) => {
    if (!confirmPassword) {
      setConfirmPasswordError('Please confirm password');
      return;
    }
    setAPIInProgress(true);
    const resetToken = searchParams.get("t")
    resetPassword({
      password: data.password,
      reset_token: resetToken,
    }).then(response => {
      const { token, user } = response.data;
      loginUser && loginUser(token, user);
      window.location.href = "/";
    }).catch((error) => {
      window.location.href = "/login";
    }).finally(() => {
      setAPIInProgress(false);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <div className={
        classNames('form-group uniform-space-b-5', {
          'validate-error': errors.password,
        })}>
        <label className="form-label d-flex justify-content-between">
          &nbsp;Password&nbsp;
          {errors.password && (
            <span className="error-label"> {errors.password.message} </span>
          )}
        </label>
        <div className="control-w-icon">
          <i className="icon-position icon-password absolute v-transform-center" />
          <input
            type={showPassword ? 'text' : 'password'}
            {...register(
              ResetPasswordFormEnum.password,
              ResetPasswordFormConfig[ResetPasswordFormEnum.password]
            )}
            className="styled-control"
            placeholder="Write you password here..."
          />
        </div>
      </div>
      <div className={
        classNames('form-group uniform-space-b-5', {
          'validate-error': confirmPasswordError,
        })}>
        <label className="form-label d-flex justify-content-between">
          &nbsp;Confirm Password&nbsp;
          {confirmPasswordError && (
            <span className="error-label"> {confirmPasswordError} </span>
          )}
        </label>
        <div className="control-w-icon">
          <i className="icon-position icon-password absolute v-transform-center" />
          <span className="password-icon absolute right-15 v-transform-center" onClick={handleShowPassword}>
            <img src={getImage("show-password.svg")} alt="show-pwd-icon" />
          </span>
          <input
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => {
              const { password } = getValues()
              if (e.target.value !== password) {
                setConfirmPasswordError('Password do not match');
              } else {
                setConfirmPasswordError('');
              }
              setConfirmPassword(e.target.value)
            }}
            className="styled-control"
            placeholder="Write you password here..."
          />
        </div>
      </div>


      <div className="form-group btn-space-tb-100 mt-5">
        {serverError && <label className="form-label d-flex justify-content-between validate-error">
          <span className="error-label"> <i className="fa fa-exclamation-circle" />&nbsp;&nbsp;Invalid username password, Try again or click Forgot password to reset it.</span>
        </label>}

        <button className="btn btn-primary w-full">
          Reset Password
        </button>
      </div>
    </form>
  );
};

export default ResetPasswordFormComponent;
