import { useEffect, useRef, useState } from "react";
import { TagsInput } from "react-tag-input-component";
import { getImage } from "../../helpers/assets";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  addEmailAttachmentToState, IEmailDetails, IEmailDraft, removeEmailAttachmentFromState, resetSaveCreateEmailFlag, selectEmailState,
  setEmailId,
  setShowBCC,
  setShowCC,
  setShowEmailEditor, updateEmailBcc, updateEmailCc,
  updateEmailContent, updateEmailSubject, updateEmailTo
} from "../../store/reducers/email/emailSlice";
import { useAuth } from "../../hooks/useAuth";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './index.css';
import { createEmailDraft, saveEmailDraft, sendEmail, uploadEmailAttachment } from "../../services/email";


const EmailEditorComponent = () => {
  const dispatch = useAppDispatch();
  const { token, currentUser } = useAuth();
  const fileSelector = useRef<HTMLInputElement | null>(null);
  const [saveInProgress, setSaveInProgress] = useState<string>('');
  const emailDetail = useAppSelector(selectEmailState);
  const {
    id, showEmailEditor, body, to, cc,
    bcc, subject, attachments, saveCreateEmailFlag, showCC, showBCC
  } = emailDetail;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleSendEmail = async () => {
    if (token && id) {
      sendEmail(token, id).then((response) => {
        setSaveInProgress('Saved');
        dispatch(resetSaveCreateEmailFlag());
        dispatch(setShowEmailEditor(false));
        setTimeout(() => {
          setSaveInProgress('');
        }, 300)
      }).catch((error) => {
        setSaveInProgress('');
        dispatch(resetSaveCreateEmailFlag());
      });
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (saveCreateEmailFlag && token) {
      const { to, cc, bcc, attachments, body } = emailDetail;
      const payload: IEmailDraft = {
        body,
        subject,
        to,
        cc,
        bcc,
        attachments,
      };
      if (id) {
        setSaveInProgress('Saving...');
        saveEmailDraft(token, id, payload).then((response) => {
          setSaveInProgress('Saved');
          dispatch(resetSaveCreateEmailFlag());
          setTimeout(() => {
            setSaveInProgress('');
          }, 300)
        }).catch((error) => {
          setSaveInProgress('');
          dispatch(resetSaveCreateEmailFlag());
        });
      } else {
        setSaveInProgress('Saving...');
        createEmailDraft(token, payload).then((response) => {
          const { email_id: emailId } = response.data;
          if (emailId) {
            dispatch(resetSaveCreateEmailFlag());
            dispatch(setEmailId(emailId));
            setSaveInProgress('Saved');
            setTimeout(() => {
              setSaveInProgress('');
            }, 300)
          }
        }).catch((error) => {
          dispatch(resetSaveCreateEmailFlag());
          setSaveInProgress('');
        });
      }
    }
  }, [dispatch, emailDetail, id, saveCreateEmailFlag, subject, token])

  const uploadAttachmentFile = async (files: FileList): Promise<void> => {
    if (token && currentUser) {
      const newEmailFlag = Boolean(id);
      const response = await uploadEmailAttachment(token, id, {
        file: files[0],
      });
      dispatch(addEmailAttachmentToState(response.data))
      return Promise.resolve();
    }
    return Promise.resolve()
  };

  if (!showEmailEditor) {
    return <></>;
  }

  return (
    <div className="new-email-diologue email-dilogue-position">
      <div className="card" style={isCollapsed ? { height: 56 } : {}}>
        <div className="card-header" onClick={() => {

          setIsCollapsed(!isCollapsed)
        }}>
          <span className="close" onClick={() => {
            setIsCollapsed(!isCollapsed)
          }}>
            <i className="fa fa-times" onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              dispatch(setShowEmailEditor(false))
            }} />
          </span>
          {subject && subject === '' ? subject || 'No Subject' : 'New Email'}{id}
        </div>
        <div className="card-body">
          <div className="mail-input with-css">
            <div className="other-mail-pos">
              {(!showCC && !showBCC) ?
                <>
                  <span className="cursor-pointer" onClick={() => setShowCC(!showCC)}> Cc </span>
                  <span className="cursor-pointer" onClick={() => setShowBCC(!showBCC)}> Bcc </span>
                </>
                : null}
            </div>
            <span className="label-position"> To :</span>
            <div style={{ marginLeft: "40px" }}>
              {to &&
                <TagsInput
                  value={to}
                  beforeAddValidate={(email: string, existingEmails: string[]) => {
                    return Boolean(email.match(emailRegex));
                  }}
                  onChange={(list: string[]) => {
                    if (list.length > 0) {
                      dispatch(updateEmailTo(list));
                    }
                  }}
                  name="To"
                />
              }
            </div>
          </div>
          {showCC &&
            <div className="mail-input with-css">
              <div className="other-mail-pos">
                {!showBCC ?
                  <span className="cursor-pointer" onClick={() => setShowBCC(!showBCC)}> Bcc </span>
                  : null}
              </div>
              <span className="label-position"> Cc :</span>
              <div style={{ marginLeft: "40px" }}>
                {cc &&
                  <TagsInput
                    value={cc}
                    beforeAddValidate={(email: string, existingEmails: string[]) => {
                      return Boolean(email.match(emailRegex));
                    }}
                    onChange={(list: string[]) => {
                      if (list.length > 0) {
                        dispatch(updateEmailCc(list));
                      }
                    }}
                    name="To"
                  />}
              </div>
            </div>
          }
          {showBCC &&
            <div className="mail-input with-css">
              <div className="other-mail-pos">
              </div>
              <span className="label-position"> Bcc :</span>
              <div style={{ marginLeft: "40px" }}>
                {bcc &&
                  <TagsInput
                    value={bcc}
                    beforeAddValidate={(email: string, existingEmails: string[]) => {
                      return Boolean(email.match(emailRegex));
                    }}
                    onChange={(list: string[]) => {
                      if (list.length > 0) {
                        dispatch(updateEmailBcc(list));
                      }
                    }}
                    name="To"
                  />
                }

              </div>
            </div>
          }
          <div className="mail-input for-subject">
            <span className="label-position"> Subject: </span>
            <input type="text" className="form-control" defaultValue={subject} onChange={(e) => { dispatch(updateEmailSubject(e.target.value)) }} />
          </div>
          <div className="mail-body-text d-flex flex-column-reverse" style={{
            height: "296px",
            overflow: "scroll",
          }}>
            <ReactQuill theme="snow" value={body} onChange={(body) => {
              if (body) {
                dispatch(updateEmailContent(body))
              }
            }} />
          </div>
          {attachments && attachments.map((attachment, index) =>
            <span key={index} className="btn btn-link padd-x-20 bg-light w-full-65" style={{ height: 38, fontSize: 14, marginBottom: 2 }}>
              <span className="float-left">{attachment.filename} ({attachment.size})</span>
              <i className="fa fa-times float-right" style={{ marginTop: 3 }} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(removeEmailAttachmentFromState(attachment.id));
              }} />
            </span>
          )}
          <div className="card-footer">
            <div className="left-action">
              <button className="btn-action">
                <img src={getImage("icon-delete.svg")} alt="" />
              </button>

              <button className="btn-action" onClick={() => {
                fileSelector.current?.click();
              }}>
                <input ref={fileSelector} type="file" name="files[]" id="file" hidden onChange={async (e) => {
                  if (e.target.files) {
                    await uploadAttachmentFile(e.target.files);
                  }
                }} />
                <img src={getImage("attach_file.png")} alt="" />
              </button>
              {saveInProgress}
            </div>
            <button className=" btn btn-primary btn-sm" onClick={handleSendEmail}>
              <span> Send Now </span> <img src={getImage("calendars-end.png")} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div >
  )
}


export default EmailEditorComponent;
