import httpClient from "../helpers/httpClient";
import { ForgotPassordForm } from "../pages/ForgotPassword/components/ForgotPasswordEmailForm";
import { LoginForm } from "../pages/Login/components/LoginWithEmailForm";
import { ResetPasswordForm } from "../pages/ResetPassword/components/ResetPasswordForm";
import { SignupForm } from "../pages/Signup/components/SignupWithEmailForm";

export const logout = (token: string) => {
  return httpClient.get("/logout/", {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const authenticateUserToken = (token: string) => {
  return httpClient.get("/auth/authenticate/", {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const login = (data: LoginForm) => {
  return httpClient.post("/auth/login/", data, {
    withCredentials: true,
    headers: { "Content-Type": "application/json" },
  });
};

export const forgotPassword = (data: ForgotPassordForm) => {
  return httpClient.post("/auth/forgot-password/", data, {
    headers: { "Content-Type": "application/json" },
  });
};

export const resetPassword = (data: {
  password: string,
  reset_token: string | null,
}) => {
  return httpClient.post("/auth/reset-password/", data, {
    headers: { "Content-Type": "application/json" },
  });
};

export const signup = (data: SignupForm) => {
  return httpClient.post("/auth/register/", data, {
    withCredentials: true,
    headers: { "Content-Type": "application/json" },
  });
};

export const searchUsers = (token: string | null | undefined, q: string) => {
  return httpClient.get(`/auth/users/?search=${q}`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const sendOtp = (token: string, data: {
  phone_no: string;
}) => {
  return httpClient.post("/auth/otp/send/", data, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const verifyOtp = (token: string, data: {
  phone_no: string;
  otp: string;
}) => {
  return httpClient.post("/auth/otp/verify/", data, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const resendOtp = (token: string, data: {
  phone_no: string;
}) => {
  return httpClient.post("/auth/otp/resend/", data, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

