import { useRef, useState } from "react";
import makeAnimated from "react-select/animated";
import AsyncSelect from "react-select/async";
import { useAuth } from "../../../hooks/useAuth";
import { createRoom, searchUsersForChat } from "../../../services/chat";
// import { useAppDispatch } from "../../../store/hooks";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { addNewRoom, initializeChatRoom, selectChatState, selectRoom } from "../../../store/reducers/chat/chatSlice";
import { IOption } from "../../TaskDetails/components/renderTaskInformation";

const AddNewChatModal = () => {
  const dispatch = useAppDispatch();
  const { token, currentUser } = useAuth();
  // const dispatch = useAppDispatch();
  const animatedComponents = makeAnimated()
  const closeRef = useRef<HTMLButtonElement>(null)
  const [query, setQuery] = useState("");
  const [selectedUser, setSelectedUser] = useState<IOption | null>(null);
  const { rooms } = useAppSelector(selectChatState);

  const asyncSelectStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: '10px',
      borderColor: '#E7E7E7',
      padding: '6px',
      background: 'rgba(245, 247, 251, 0.2)',
    }),
  }

  const startRoom = () => {
    if (token && selectedUser && selectedUser.value) {
      let room = rooms?.find(room => {
        const user = room.user.id === currentUser?.id ? room.contact : room.user;
        return String(user.id) === selectedUser.value
      });
      if (room) {
        dispatch(selectRoom(room));
      } else {
        createRoom(token, selectedUser.value).then(response => {
          dispatch(initializeChatRoom());
          dispatch(addNewRoom(response.data));
          dispatch(selectRoom(response.data));
        });
      }
      if (closeRef.current) {
        closeRef.current.click();
      }
    };
  }

  const loadOptions = (inputValue: any, callback: any) => {
    return searchUsersForChat(token, query).then((response) => callback(response.data.results));
  };

  return (
    <div className="modal fade" id="startNewChatModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered  diloge-650  ">
        <div className="modal-content  ">
          <div className="modal-body ">
            <h3 className="text-center"> Select contact for chat </h3>
            <AsyncSelect
              cacheOptions
              styles={asyncSelectStyles}
              components={animatedComponents}
              getOptionLabel={(e: IOption) => {
                return e?.label;
              }}
              getOptionValue={(e: IOption) => {
                return e?.value;
              }}
              loadOptions={loadOptions}
              defaultValue={null}
              onInputChange={(value) => setQuery(value)}
              onChange={(value: any) => {
                setSelectedUser(value);
              }}
            />
            <div className="form-group mt-xl-5  mb-xl-3  mb-2 justify-content-end  text-right btn-space  ">
              <button type="button" ref={closeRef} onClick={() => {
                $("#startNewChatModal").modal('hide');
              }} className="btn btn-outline-primary  btn-sm  min-w-10" data-bs-dismiss="modal">Cancel </button>
              <button type="button" className="btn btn-primary btn-sm" onClick={startRoom}>Start Chat</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewChatModal;
