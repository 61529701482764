import classNames from "classnames";
import { format } from 'date-fns';
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { getImage } from "../helpers/assets";
import { useAuth } from "../hooks/useAuth";
import { useDebounce } from "../hooks/useDebounce";
import usePrevious from "../hooks/usePrevious";
import { ESelectedMenu } from "../pages/Dashboard/enums";

import { deleteAllEmails, getAllEmails, getEmailListMeta, getNylasAuthorizationUrl, searchEmails, starUnstarEmail } from "../services/email";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { IEmailDetails, resetEmailState, selectEmailState, setEmails, setFilterType, setShowEmailEditor, setCurrentPage, setEmailListMeta, starUnStarEmail, initialState, initializeEmail } from "../store/reducers/email/emailSlice";
import GoogleAuthComponent from "./GoogleLoginComponent";

export enum ACTION_TYPE {
  BULK_DELETE = 'BULK_DELETE',
  STAR = 'STAR',
  UNSTAR = 'UNSTAR',
}

export enum EMAIL_LIST_TYPE {
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  ALL = 'ALL',
  STARRED = 'STARRED',
  DELETED = 'DELETED',
}

export interface IEmailListMeta {
  new_count: number,
  draft_count: number,
  sent_count: number,
  starred_count: number,
  deleted_count: number,
  all_count: number,
}

type ICheckboxes = { [key: number | string]: boolean };

const EmailBodyComponent = ({ IsNylasConnected }: { IsNylasConnected: boolean }) => {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pageSize = 5;
  const { token } = useAuth();
  const emailDetail = useAppSelector(selectEmailState);
  const { filterType, emails, currentPage, emailCount, metaInfo } = emailDetail;
  const [pageSizeArray, setPageSizeArray] = useState<number[]>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [toggleEmailListCall, setToggleEmailListCall] = useState<boolean>(true);
  const [checkboxes, setCheckboxes] = useState<ICheckboxes>();
  const [pageRange, setPageRange] = useState<{ start: number; end: number; } | null>(null);
  const [loading, setIsLoading] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const previousFilterType = usePrevious(filterType);
  const previousDebouncedSearchTerm = usePrevious(debouncedSearchTerm);

  const initializeCheckboxes = (emails: IEmailDetails[]) => {
    let localCheckboxes: { [key: number | string]: boolean } = {};
    emails.map((email) => {
      if (email.id) {
        localCheckboxes[email.id] = false;
      }
    });
    setCheckboxes(localCheckboxes);
  }

  const handleDeleteAll = () => {
    let emailIds: string[] = [];
    if (checkboxes) {
      emailIds = Object.keys(checkboxes).filter((key) => {
        return checkboxes[key]
      })
    }
    if (emailIds.length > 0) {
      deleteAllEmails(token, emailIds).then((response) => {
        setToggleEmailListCall(!toggleEmailListCall);
      })
      getEmailListMeta(token).then((response) => {
        dispatch(setEmailListMeta(response.data));
      })
    }
  }

  const handleStarUnStar = (emailId: string | number, starred: boolean | undefined) => {
    const action: ACTION_TYPE = starred ? ACTION_TYPE.UNSTAR : ACTION_TYPE.STAR;
    dispatch(starUnStarEmail({ emailId, action }));
    starUnstarEmail(token, emailId, action).catch(() => {
      dispatch(starUnStarEmail({ emailId, action: action === ACTION_TYPE.STAR ? ACTION_TYPE.UNSTAR : ACTION_TYPE.STAR }));
    })
  }

  useEffect(() => {
    if (!IsNylasConnected) return;
    getEmailListMeta(token).then((response) => {
      dispatch(setEmailListMeta(response.data));
    })
  }, [token])

  useEffect(() => {
    if (!IsNylasConnected) return;
    if (token) {
      setIsLoading(true);
      if (previousFilterType !== filterType) {
        dispatch(setCurrentPage(1));
        setSearchTerm('');
      }
      if (previousDebouncedSearchTerm !== debouncedSearchTerm && debouncedSearchTerm) {
        dispatch(setFilterType(EMAIL_LIST_TYPE.ALL))
      }
      getAllEmails(token, currentPage, filterType, debouncedSearchTerm).then((response) => {
        setIsLoading(false);
        dispatch(setEmails(response.data));
        initializeCheckboxes(response.data.emails);
        const pageArray = [];
        for (var i = 1; i <= Math.ceil(response.data.count / pageSize); i++) {
          pageArray.push(i);
        }
        setPageSizeArray(pageArray);
      });
    }
  }, [currentPage, filterType, token, IsNylasConnected, debouncedSearchTerm])

  useEffect(() => {
    if (!IsNylasConnected) return;
    if (currentPage && emailCount) {
      const start = (currentPage - 1) * pageSize + 1;
      let end = start + pageSize - 1;
      if (end > emailCount) {
        end = emailCount;
      }
      setPageRange({
        start,
        end,
      })
    }
  }, [currentPage, emailCount, IsNylasConnected])

  if (!IsNylasConnected) {
    return (
      <div className="form-content-area">
        <button className="btn btn-primary btn-sm padd-x-20"
          onClick={() => {
            if (token) {
              getNylasAuthorizationUrl(token).then(response => {
                const { authorization_url } = response.data;
                window.open(authorization_url, '_blank', `location=yes,height=570,width=520,top=${((window.screen.height / 2) - 285) / 2},left=${(window.screen.width / 2) - 260} scrollbars = yes, status = yes`)
              })
            }
          }}
        >
          Connect email with Nylas </button>
      </div>
    )
  }

  return (
    <div className="form-content-area">
      <div className="row statics-row uniform-space-b-5 ">
        <div className="col-sm-12">
          <div className="card-block ">
            <div className="align-with-leftright">
              <div>
                <h3> Your Email </h3>
                <p className="transform-none"> You have {metaInfo?.new_count && Number(metaInfo?.new_count || 0) > 0 ? metaInfo?.new_count : 'no'} new emails </p>
              </div>
              <div className="search-with-button">
                <button className="btn btn-primary btn-sm padd-x-20 cta-write-email " onClick={(e) => {
                  e.preventDefault();
                  // dispatch(resetEmailState());
                  dispatch(setShowEmailEditor(true))
                }}>
                  Write Email
                </button>
                <div className="searcharea ">
                  <i className="v-transform-center">
                    <img src={getImage("icon-search.svg")} alt="" />
                  </i>
                  <input
                    type="text"
                    className="input-control"
                    placeholder="Search keyword..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="mail-wrapper">
              <div className="mail-header-wrapper">
                <div className="form-check d-inline-block v-center">
                  {
                    !loading && checkboxes && <input
                      className="form-check-input lg-checkbox"
                      type="checkbox"
                      id="checkboxNoLabel"
                      defaultValue=""
                      aria-label="..."
                      onChange={(e) => {
                        let localCheckboxes: { [key: number | string]: boolean } = {};
                        Object.keys(checkboxes).forEach((key: string): void => {
                          localCheckboxes[key] = e.target.checked;
                        })
                        setCheckboxes(localCheckboxes);
                      }}
                    />
                  }
                </div>
                <ul
                  className="nav nav-tabs tabs-custom  d-inline-flex"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation" onClick={() => {
                    dispatch(setFilterType(EMAIL_LIST_TYPE.ALL))
                  }}>
                    <button
                      className={classNames("nav-link", {
                        "active": filterType === EMAIL_LIST_TYPE.ALL
                      })}
                      id="draft-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#draft"
                      type="button"
                      role="tab"
                      aria-controls="draft"
                      aria-selected="false"
                    >

                      <svg
                        width={18}
                        height={18}
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.5231 12.6998C14.1223 12.6109 14.8942 12.5936 15.9539 12.5905C15.2002 14.4179 13.6057 15.7978 11.6423 16.2583L11.6378 16.2594C11.6476 15.4971 11.6749 14.9092 11.7496 14.4344C11.8472 13.8132 12.0141 13.4699 12.2634 13.2207C12.5185 12.9656 12.8724 12.7964 13.5231 12.6998ZM11.9703 0.343699C10.0166 -0.114566 7.9834 -0.114566 6.02975 0.343699C3.20842 1.00549 1.0055 3.20841 0.3437 6.02974C-0.114567 7.9834 -0.114567 10.0166 0.3437 11.9703C1.0055 14.7916 3.20842 16.9945 6.02975 17.6563C7.67069 18.0412 9.36774 18.1028 11.0266 17.8411C11.3428 17.7913 11.6575 17.7297 11.9703 17.6563C14.7916 16.9945 16.9945 14.7916 17.6563 11.9703C18.1146 10.0166 18.1146 7.9834 17.6563 6.02975C16.9945 3.20842 14.7916 1.00549 11.9703 0.343699ZM5.55374 6.12812C5.55374 5.8109 5.8109 5.55374 6.12812 5.55374H8.99997C9.31719 5.55374 9.57435 5.8109 9.57435 6.12812C9.57435 6.44533 9.31719 6.70249 8.99997 6.70249H6.12812C5.8109 6.70249 5.55374 6.44533 5.55374 6.12812ZM5.55374 8.99997C5.55374 8.68276 5.8109 8.4256 6.12812 8.4256H10.9145C11.2318 8.4256 11.4889 8.68276 11.4889 8.99997C11.4889 9.31719 11.2318 9.57435 10.9145 9.57435H6.12812C5.8109 9.57435 5.55374 9.31719 5.55374 8.99997Z"
                          fill="currentColor"
                        />
                      </svg>
                      All {metaInfo?.all_count && `(${metaInfo?.all_count})`}
                    </button>
                  </li >
                  <li className="nav-item" role="presentation" onClick={() => dispatch(setFilterType(EMAIL_LIST_TYPE.DRAFT))}>
                    <button
                      className={classNames("nav-link", {
                        "active": filterType === EMAIL_LIST_TYPE.DRAFT
                      })}
                      id="draft-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#draft"
                      type="button"
                      role="tab"
                      aria-controls="draft"
                      aria-selected="false"
                    >

                      <svg
                        width={18}
                        height={18}
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.5231 12.6998C14.1223 12.6109 14.8942 12.5936 15.9539 12.5905C15.2002 14.4179 13.6057 15.7978 11.6423 16.2583L11.6378 16.2594C11.6476 15.4971 11.6749 14.9092 11.7496 14.4344C11.8472 13.8132 12.0141 13.4699 12.2634 13.2207C12.5185 12.9656 12.8724 12.7964 13.5231 12.6998ZM11.9703 0.343699C10.0166 -0.114566 7.9834 -0.114566 6.02975 0.343699C3.20842 1.00549 1.0055 3.20841 0.3437 6.02974C-0.114567 7.9834 -0.114567 10.0166 0.3437 11.9703C1.0055 14.7916 3.20842 16.9945 6.02975 17.6563C7.67069 18.0412 9.36774 18.1028 11.0266 17.8411C11.3428 17.7913 11.6575 17.7297 11.9703 17.6563C14.7916 16.9945 16.9945 14.7916 17.6563 11.9703C18.1146 10.0166 18.1146 7.9834 17.6563 6.02975C16.9945 3.20842 14.7916 1.00549 11.9703 0.343699ZM5.55374 6.12812C5.55374 5.8109 5.8109 5.55374 6.12812 5.55374H8.99997C9.31719 5.55374 9.57435 5.8109 9.57435 6.12812C9.57435 6.44533 9.31719 6.70249 8.99997 6.70249H6.12812C5.8109 6.70249 5.55374 6.44533 5.55374 6.12812ZM5.55374 8.99997C5.55374 8.68276 5.8109 8.4256 6.12812 8.4256H10.9145C11.2318 8.4256 11.4889 8.68276 11.4889 8.99997C11.4889 9.31719 11.2318 9.57435 10.9145 9.57435H6.12812C5.8109 9.57435 5.55374 9.31719 5.55374 8.99997Z"
                          fill="currentColor"
                        />
                      </svg>
                      Draft {metaInfo?.draft_count && `(${metaInfo?.draft_count})`}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation" onClick={() => dispatch(setFilterType(EMAIL_LIST_TYPE.SENT))}>
                    <button
                      className={classNames("nav-link", {
                        "active": filterType === EMAIL_LIST_TYPE.SENT
                      })}
                      id="draft-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#draft"
                      type="button"
                      role="tab"
                      aria-controls="draft"
                      aria-selected="false"
                    >

                      <svg
                        width={18}
                        height={18}
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.5231 12.6998C14.1223 12.6109 14.8942 12.5936 15.9539 12.5905C15.2002 14.4179 13.6057 15.7978 11.6423 16.2583L11.6378 16.2594C11.6476 15.4971 11.6749 14.9092 11.7496 14.4344C11.8472 13.8132 12.0141 13.4699 12.2634 13.2207C12.5185 12.9656 12.8724 12.7964 13.5231 12.6998ZM11.9703 0.343699C10.0166 -0.114566 7.9834 -0.114566 6.02975 0.343699C3.20842 1.00549 1.0055 3.20841 0.3437 6.02974C-0.114567 7.9834 -0.114567 10.0166 0.3437 11.9703C1.0055 14.7916 3.20842 16.9945 6.02975 17.6563C7.67069 18.0412 9.36774 18.1028 11.0266 17.8411C11.3428 17.7913 11.6575 17.7297 11.9703 17.6563C14.7916 16.9945 16.9945 14.7916 17.6563 11.9703C18.1146 10.0166 18.1146 7.9834 17.6563 6.02975C16.9945 3.20842 14.7916 1.00549 11.9703 0.343699ZM5.55374 6.12812C5.55374 5.8109 5.8109 5.55374 6.12812 5.55374H8.99997C9.31719 5.55374 9.57435 5.8109 9.57435 6.12812C9.57435 6.44533 9.31719 6.70249 8.99997 6.70249H6.12812C5.8109 6.70249 5.55374 6.44533 5.55374 6.12812ZM5.55374 8.99997C5.55374 8.68276 5.8109 8.4256 6.12812 8.4256H10.9145C11.2318 8.4256 11.4889 8.68276 11.4889 8.99997C11.4889 9.31719 11.2318 9.57435 10.9145 9.57435H6.12812C5.8109 9.57435 5.55374 9.31719 5.55374 8.99997Z"
                          fill="currentColor"
                        />
                      </svg>
                      Sent {metaInfo?.sent_count && `(${metaInfo?.sent_count})`}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation" onClick={() => dispatch(setFilterType(EMAIL_LIST_TYPE.STARRED))}>
                    <button
                      className={classNames("nav-link", {
                        "active": filterType === EMAIL_LIST_TYPE.STARRED
                      })}
                      id="starred-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#starred"
                      type="button"
                      role="tab"
                      aria-controls="starred"
                      aria-selected="false"
                    >

                      <svg
                        width={18}
                        height={18}
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.857 1.92587C10.7901 -0.641954 7.20991 -0.641957 6.14297 1.92587L5.58369 3.27189C5.361 3.80784 4.87574 4.17228 4.3211 4.23508L2.76039 4.41179C0.12208 4.71052 -0.9307 7.99488 0.966294 9.82888L2.29142 11.11C2.68291 11.4885 2.85894 12.0499 2.75098 12.596L2.43104 14.2142C1.89984 16.901 4.795 18.9982 7.12787 17.5005L8.17453 16.8286C8.67966 16.5043 9.32034 16.5043 9.82547 16.8286L10.8721 17.5005C13.205 18.9982 16.1002 16.901 15.569 14.2142L15.249 12.596C15.1411 12.0499 15.3171 11.4885 15.7086 11.11L17.0337 9.82888C18.9307 7.99488 17.8779 4.71052 15.2396 4.41179L13.6789 4.23508C13.1243 4.17228 12.639 3.80784 12.4163 3.27189L11.857 1.92587Z"
                          fill="currentColor"
                        />
                      </svg>
                      Starred {metaInfo?.starred_count && `(${metaInfo?.starred_count})`}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation" onClick={() => dispatch(setFilterType(EMAIL_LIST_TYPE.DELETED))}>
                    <button
                      className={classNames("nav-link", {
                        "active": filterType === EMAIL_LIST_TYPE.DELETED
                      })}
                      id="trash-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact"
                      type="button"
                      role="tab"
                      aria-controls="trash"
                      aria-selected="false"
                    >
                      <svg
                        width={18}
                        height={18}
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9 0C8.3742 0 7.75297 0.110313 7.17119 0.32613C6.58938 0.541958 6.05585 0.860065 5.60284 1.26577C5.14968 1.67161 4.78526 2.1579 4.53483 2.69935C4.38345 3.02664 4.27593 3.36939 4.21453 3.72006H0.692308C0.309957 3.72006 0 4.03235 0 4.41757C0 4.8028 0.309957 5.11509 0.692308 5.11509H1.84615V9.16223C1.84615 10.6812 2.05998 12.1924 2.48125 13.6508C3.11125 15.8318 4.92504 17.4569 7.14857 17.8324L7.29418 17.857C8.4235 18.0477 9.57646 18.0477 10.7058 17.857L10.8514 17.8324C13.0749 17.4569 14.8887 15.8319 15.5187 13.6509C15.94 12.1924 16.1538 10.6811 16.1538 9.1622V5.11509H17.3077C17.69 5.11509 18 4.8028 18 4.41757C18 4.03235 17.69 3.72006 17.3077 3.72006H13.7855C13.7241 3.36939 13.6165 3.02664 13.4652 2.69935C13.2147 2.1579 12.8503 1.67161 12.3972 1.26577C11.9441 0.860066 11.4106 0.541959 10.8288 0.32613C10.247 0.110313 9.62581 0 9 0ZM7.64959 1.63524C8.07575 1.47716 8.53482 1.39502 9 1.39502C9.46518 1.39502 9.92426 1.47716 10.3504 1.63524C10.7765 1.79331 11.1588 2.02324 11.4773 2.30842C11.7955 2.59346 12.0431 2.92736 12.2101 3.28858C12.2753 3.42941 12.3278 3.57365 12.3678 3.72006L5.63224 3.72006C5.67215 3.57365 5.72473 3.42941 5.78987 3.28858C5.95694 2.92736 6.20445 2.59346 6.52273 2.30842C6.84116 2.02324 7.22347 1.79331 7.64959 1.63524ZM7.15385 8.37014C7.5362 8.37014 7.84615 8.68243 7.84615 9.06765V12.7877C7.84615 13.1729 7.5362 13.4852 7.15385 13.4852C6.7715 13.4852 6.46154 13.1729 6.46154 12.7877V9.06765C6.46154 8.68243 6.7715 8.37014 7.15385 8.37014ZM10.8462 8.37014C11.2285 8.37014 11.5385 8.68243 11.5385 9.06765V12.7877C11.5385 13.1729 11.2285 13.4852 10.8462 13.4852C10.4638 13.4852 10.1538 13.1729 10.1538 12.7877V9.06765C10.1538 8.68243 10.4638 8.37014 10.8462 8.37014Z"
                          fill="currentColor"
                        />
                      </svg>
                      Trash
                    </button>
                  </li>
                </ul >
                {!loading && emails &&
                  emails.length > 0 &&
                  <div className="right-action-mail">
                    <ul>
                      {checkboxes && Object.keys(checkboxes).filter(key => checkboxes[key]).length >= 1 &&
                        <li>
                          <button className="btn-delete" onClick={handleDeleteAll}>
                            <i className="fa fa-trash" /> {checkboxes && Object.keys(checkboxes).filter(key => checkboxes[key]).length > 1 ? 'Delete All' : 'Delete'}
                          </button>
                        </li>
                      }

                      {metaInfo?.all_count &&
                        <>
                          <li>{pageRange?.start}-{pageRange?.end} {pageRange?.end !== emailCount ? `from ${metaInfo?.all_count}` : ''}</li>
                          <li>
                            <button className={classNames("btn-action", { "disabled": currentPage === 1 })} onClick={() => {
                              if (currentPage && currentPage > 1) {
                                dispatch(setCurrentPage(currentPage - 1))
                              }
                            }}>
                              <i className=" fa fa-caret-left" />
                            </button>
                          </li>
                          <li>
                            <button className={classNames("btn-action", { "disabled": !(pageSizeArray && currentPage === pageSizeArray.length) })} onClick={() => {
                              if (pageSizeArray && currentPage && currentPage !== pageSizeArray.length) {
                                dispatch(setCurrentPage(currentPage + 1))
                              }
                            }}>
                              <i className=" fa fa-caret-right" />
                            </button>
                          </li>
                        </>
                      }
                    </ul>
                  </div>
                }

              </div >
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="allemails"
                  role="tabpanel"
                  aria-labelledby="allemails-tab"
                >
                  {loading && <>Loading Emails ...</>}
                  {!loading && emails && emails.length === 0 && <>No Emails</>}
                  {!loading && emails && emails.map((email, index) => {
                    const fullName = email?.fromlist ? email?.fromlist[0].name : '';
                    const [firstName, lastName] = fullName.split(' ');
                    const badge = `${firstName ? firstName.charAt(0).toUpperCase() : ''}${lastName ? lastName.charAt(0).toUpperCase() : ''}`;
                    const createdAt = new Date(email.created_at || 0);
                    if (checkboxes && email.id) {
                      return (
                        <div className={classNames("mail-row d-flex cursor-pointer", {
                          "new-email": email.read
                        })} key={index} >
                          <div className="form-check d-inline-block v-center lg-checkbox">
                            <input
                              className="form-check-input lg-checkbox"
                              type="checkbox"
                              id="checkboxNoLabel"
                              aria-label="..."
                              onChange={(e) => {
                                let localCheckboxes = {
                                  ...checkboxes,
                                };
                                if (localCheckboxes && email?.id) {
                                  localCheckboxes[email.id] = e.target.checked;
                                  setCheckboxes(localCheckboxes);
                                }
                              }}
                              checked={checkboxes[email.id]}
                            />
                          </div>
                          <div className="col-xl-3" >
                            {filterType !== EMAIL_LIST_TYPE.DRAFT &&
                              <>
                                <span className="star-mail-column d-inline-block v-center">
                                  {
                                    email.is_deleted &&
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM18 4H15.5L14.79 3.29C14.61 3.11 14.35 3 14.09 3H9.91C9.65 3 9.39 3.11 9.21 3.29L8.5 4H6C5.45 4 5 4.45 5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4Z" fill="#585858"></path>
                                    </svg>
                                  }
                                  <span onClick={(e) => {
                                    if (email && email.id) {
                                      handleStarUnStar(email.id, email.starred);
                                    }
                                  }}>
                                    <img src={getImage(`${email.starred ? 'icon-starfilled' : 'icon-star'}.svg`)} alt="" />
                                  </span>
                                </span>
                              </>
                              || <span className="star-mail-column d-inline-block v-center"></span>}
                            <span className="text-primary font-semibold" onClick={(e) => {
                              e.stopPropagation();
                              if (email.is_draft) {
                                dispatch(initializeEmail(email))
                                dispatch(setShowEmailEditor(true))
                              } else {
                                navigate(`/emails/detail/${email.id}`, { state: { selectedMenu: ESelectedMenu.EMAIL }, replace: true });
                              }
                            }}>
                              <span className="number-badge badege-pink me-1">
                                {badge || 'NA'}
                              </span>
                              {/* <Link to={`/emails/detail/${email.id}`}> */}
                              {`${fullName || 'Untitled'}`}
                              {/* </Link> */}
                            </span>

                          </div>
                          <div className="col-xl-7" onClick={(e) => {
                            e.stopPropagation();
                            if (email.is_draft) {
                              dispatch(initializeEmail(email))
                              // dispatch(resetEmailState());
                              dispatch(setShowEmailEditor(true))
                            } else {
                              navigate(`/emails/detail/${email.id}`, { state: { selectedMenu: ESelectedMenu.EMAIL }, replace: true });
                            }
                          }}>
                            {
                              email.is_draft &&
                              <span className="text-primary font-semibold d-inline-block v-center">
                                <span className="number-badge badege-pink me-1"> Draft </span>
                              </span>
                            }
                            <div className="view-message v-center  d-inline-block">
                              <strong>{email.subject || '(No Subject)'}</strong> - {email?.snippet}
                            </div>
                          </div>
                          {
                            createdAt &&
                            <div className="col-xl-2  email-date">{`${format(createdAt, 'dd MMM HH:mma')}`}</div>
                          }
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div >

            {!loading && emails && emails.length > 0 &&
              <nav aria-label="Page">
                <ul className="pagination justify-content-center">
                  <li className={classNames("page-item", { "disabled": currentPage === 1 })} onClick={() => {
                    if (currentPage && currentPage > 1) {
                      dispatch(setCurrentPage(currentPage - 1))
                    }
                  }}>
                    <span className="page-link prev" tabIndex={-1} aria-disabled="true">
                      <img src={getImage("angle-left.svg")} alt="" />
                    </span>
                  </li>
                  {pageSizeArray && pageSizeArray.map((page) =>
                    <li key={page} className={classNames("page-item", { "active": page === currentPage })}
                      onClick={() => {
                        dispatch(setCurrentPage(page))
                      }}>
                      <span className="page-link">{page}</span>
                    </li>
                  )}
                  <li className={classNames("page-item", { "disabled": !(pageSizeArray && currentPage === pageSizeArray.length) })} onClick={() => {
                    if (pageSizeArray && currentPage && currentPage !== pageSizeArray.length) {
                      dispatch(setCurrentPage(currentPage + 1))
                    }
                  }}>
                    <span className="page-link next">
                      <img src={getImage("angle-right.svg")} alt="" />
                    </span>
                  </li>
                </ul>
              </nav>}
          </div >
        </div >
      </div >
    </div >

  )
}


export default EmailBodyComponent;
