import { getImage } from "../../../helpers/assets";
import { useAppDispatch } from "../../../store/hooks";

const RenderRelatedEmails = () => {
  const dispatch = useAppDispatch();
  return (
    <div className="card-block uniform-space-b-4 ">
      <div className="accordion" id="accordionnotesAttachments">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingSeven">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
              Related Emails
            </button>
          </h2>
          <div id="collapseSeven" className="accordion-collapse collapse show" aria-labelledby="headingSeven" data-bs-parent="#accordionnotesAttachments">
            <div className="accordion-body">
              <div className="mail-wrapper">
                <div className="mail-header-wrapper">
                  <div className="form-check d-inline-block v-center">
                    <input className="form-check-input lg-checkbox" type="checkbox" id="checkboxNoLabel" aria-label="..." />
                  </div>
                  <ul className="nav nav-tabs tabs-custom  d-inline-flex" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="allemails-tab" data-bs-toggle="tab" data-bs-target="#allemails" type="button" role="tab" aria-controls="allemails" aria-selected="true"><svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.26585 1.6772C9.6874 1.34375 11.1668 1.34375 12.5884 1.6772C14.4413 2.11185 15.8882 3.55866 16.3228 5.41162C16.6562 6.83317 16.6562 8.3126 16.3228 9.73415C16.1087 10.647 15.6489 11.4614 15.0158 12.1047C15.2314 10.7218 15.1788 9.30758 14.858 7.93994C14.2995 5.55929 12.4407 3.70045 10.0601 3.14203C8.69242 2.82122 7.27823 2.76862 5.89533 2.9842C6.53863 2.35114 7.353 1.89133 8.26585 1.6772ZM17.7122 10.0601C17.2712 11.94 16.0194 13.4944 14.3401 14.3401C13.4944 16.0194 11.94 17.2712 10.0601 17.7122C8.42415 18.0959 6.72163 18.0959 5.08571 17.7122C2.70506 17.1538 0.846227 15.2949 0.287801 12.9143C-0.0959338 11.2784 -0.0959336 9.57585 0.287801 7.93994C0.728758 6.06007 1.98057 4.50558 3.65987 3.65982C4.50562 1.9806 6.05997 0.72878 7.93994 0.2878C9.57586 -0.0959333 11.2784 -0.0959334 12.9143 0.2878C15.2949 0.846225 17.1538 2.70506 17.7122 5.08571C18.0959 6.72162 18.0959 8.42415 17.7122 10.0601ZM4.24369 8.68336C4.41857 8.42104 4.77299 8.35015 5.03531 8.52503L6.83405 9.72419C7.28146 10.0225 7.86433 10.0225 8.31174 9.72419L10.1105 8.52503C10.3728 8.35015 10.7272 8.42104 10.9021 8.68336C11.077 8.94568 11.0061 9.3001 10.7438 9.47498L8.94504 10.6741C8.11413 11.2281 7.03165 11.2281 6.20075 10.6741L4.40202 9.47498C4.1397 9.3001 4.06881 8.94568 4.24369 8.68336Z" fill="currentColor" />
                      </svg>
                        All Email (250)</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="draft-tab" data-bs-toggle="tab" data-bs-target="#draft" type="button" role="tab" aria-controls="draft" aria-selected="false"> <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M13.5231 12.6998C14.1223 12.6109 14.8942 12.5936 15.9539 12.5905C15.2002 14.4179 13.6057 15.7978 11.6423 16.2583L11.6378 16.2594C11.6476 15.4971 11.6749 14.9092 11.7496 14.4344C11.8472 13.8132 12.0141 13.4699 12.2634 13.2207C12.5185 12.9656 12.8724 12.7964 13.5231 12.6998ZM11.9703 0.343699C10.0166 -0.114566 7.9834 -0.114566 6.02975 0.343699C3.20842 1.00549 1.0055 3.20841 0.3437 6.02974C-0.114567 7.9834 -0.114567 10.0166 0.3437 11.9703C1.0055 14.7916 3.20842 16.9945 6.02975 17.6563C7.67069 18.0412 9.36774 18.1028 11.0266 17.8411C11.3428 17.7913 11.6575 17.7297 11.9703 17.6563C14.7916 16.9945 16.9945 14.7916 17.6563 11.9703C18.1146 10.0166 18.1146 7.9834 17.6563 6.02975C16.9945 3.20842 14.7916 1.00549 11.9703 0.343699ZM5.55374 6.12812C5.55374 5.8109 5.8109 5.55374 6.12812 5.55374H8.99997C9.31719 5.55374 9.57435 5.8109 9.57435 6.12812C9.57435 6.44533 9.31719 6.70249 8.99997 6.70249H6.12812C5.8109 6.70249 5.55374 6.44533 5.55374 6.12812ZM5.55374 8.99997C5.55374 8.68276 5.8109 8.4256 6.12812 8.4256H10.9145C11.2318 8.4256 11.4889 8.68276 11.4889 8.99997C11.4889 9.31719 11.2318 9.57435 10.9145 9.57435H6.12812C5.8109 9.57435 5.55374 9.31719 5.55374 8.99997Z" fill="currentColor" />
                      </svg>
                        Draft (2)</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="starred-tab" data-bs-toggle="tab" data-bs-target="#starred" type="button" role="tab" aria-controls="starred" aria-selected="false">
                        <svg width={18} height={17} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.00032 14.2698L13.1503 16.7798C13.9103 17.2398 14.8403 16.5598 14.6403 15.6998L13.5403 10.9798L17.2103 7.79977C17.8803 7.21977 17.5203 6.11977 16.6403 6.04977L11.8103 5.63977L9.92032 1.17977C9.58032 0.369766 8.42032 0.369766 8.08032 1.17977L6.19032 5.62977L1.36032 6.03977C0.480317 6.10977 0.120317 7.20977 0.790317 7.78977L4.46032 10.9698L3.36032 15.6898C3.16032 16.5498 4.09032 17.2298 4.85032 16.7698L9.00032 14.2698Z" fill="currentColor" />
                        </svg>
                        Starred (3)</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="trash-tab" data-bs-toggle="tab" data-bs-target="#trash" type="button" role="tab" aria-controls="trash" aria-selected="false"><svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9 0C8.3742 0 7.75297 0.110313 7.17119 0.32613C6.58938 0.541958 6.05585 0.860065 5.60284 1.26577C5.14968 1.67161 4.78526 2.1579 4.53483 2.69935C4.38345 3.02664 4.27593 3.36939 4.21453 3.72006H0.692308C0.309957 3.72006 0 4.03235 0 4.41757C0 4.8028 0.309957 5.11509 0.692308 5.11509H1.84615V9.16223C1.84615 10.6812 2.05998 12.1924 2.48125 13.6508C3.11125 15.8318 4.92504 17.4569 7.14857 17.8324L7.29418 17.857C8.4235 18.0477 9.57646 18.0477 10.7058 17.857L10.8514 17.8324C13.0749 17.4569 14.8887 15.8319 15.5187 13.6509C15.94 12.1924 16.1538 10.6811 16.1538 9.1622V5.11509H17.3077C17.69 5.11509 18 4.8028 18 4.41757C18 4.03235 17.69 3.72006 17.3077 3.72006H13.7855C13.7241 3.36939 13.6165 3.02664 13.4652 2.69935C13.2147 2.1579 12.8503 1.67161 12.3972 1.26577C11.9441 0.860066 11.4106 0.541959 10.8288 0.32613C10.247 0.110313 9.62581 0 9 0ZM7.64959 1.63524C8.07575 1.47716 8.53482 1.39502 9 1.39502C9.46518 1.39502 9.92426 1.47716 10.3504 1.63524C10.7765 1.79331 11.1588 2.02324 11.4773 2.30842C11.7955 2.59346 12.0431 2.92736 12.2101 3.28858C12.2753 3.42941 12.3278 3.57365 12.3678 3.72006L5.63224 3.72006C5.67215 3.57365 5.72473 3.42941 5.78987 3.28858C5.95694 2.92736 6.20445 2.59346 6.52273 2.30842C6.84116 2.02324 7.22347 1.79331 7.64959 1.63524ZM7.15385 8.37014C7.5362 8.37014 7.84615 8.68243 7.84615 9.06765V12.7877C7.84615 13.1729 7.5362 13.4852 7.15385 13.4852C6.7715 13.4852 6.46154 13.1729 6.46154 12.7877V9.06765C6.46154 8.68243 6.7715 8.37014 7.15385 8.37014ZM10.8462 8.37014C11.2285 8.37014 11.5385 8.68243 11.5385 9.06765V12.7877C11.5385 13.1729 11.2285 13.4852 10.8462 13.4852C10.4638 13.4852 10.1538 13.1729 10.1538 12.7877V9.06765C10.1538 8.68243 10.4638 8.37014 10.8462 8.37014Z" fill="currentColor" />
                      </svg>
                        Trash</button>
                    </li>
                  </ul>
                  <div className="right-action-mail">
                    <ul>
                      <li>
                        <button className="btn-delete"> <i className="fa fa-trash" /> Delete All
                        </button>
                      </li>
                      <li>
                        1-50 form 250
                      </li>
                      <li> <button className="btn-action"> <i className=" fa fa-caret-left" />
                      </button> </li>
                      <li> <button className="btn-action"> <i className=" fa fa-caret-right" />
                      </button> </li>
                    </ul>
                  </div>
                </div>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade active show" id="allemails" role="tabpanel" aria-labelledby="allemails-tab">
                    <div className="mail-row d-flex ">
                      <div className="col-xl-3">
                        <div className="form-check d-inline-block v-center lg-checkbox">
                          <input className="form-check-input lg-checkbox" type="checkbox" id="checkboxNoLabel" aria-label="..." />
                        </div>
                        <span className="star-mail-column d-inline-block v-center ">
                          <img src={getImage("icon-star.svg")} alt="" />
                        </span>
                        <span className="text-primary font-semibold"> <span className="number-badge badege-pink me-1"> JC
                        </span> Jane
                          Cooper </span>
                      </div>
                      <div className="col-xl-7">
                        <div className="view-message v-center  d-inline-block">
                          <strong> Update
                            Project </strong> - Hey Nicolas! How are you
                          doing? Right
                          now I will write….
                        </div>
                      </div>
                      <div className="col-xl-2  email-date">
                        15 Apr 3:42PM
                      </div>
                    </div>
                    <div className="mail-row d-flex  search-email ">
                      <div className="col-xl-3">
                        <div className="form-check d-inline-block v-center lg-checkbox">
                          <input className="form-check-input lg-checkbox" type="checkbox" id="checkboxNoLabel" aria-label="..." />
                        </div>
                        <span className="star-mail-column d-inline-block v-center ">
                          <img src={getImage("icon-star.svg")} alt="" />
                        </span>
                        <span className="text-primary font-semibold"> <span className="number-badge badege-pink me-1"> JC
                        </span> Jane
                          Cooper </span>
                      </div>
                      <div className="col-xl-7">
                        <span className="text-primary font-semibold d-inline-block v-center">
                          <span className="number-badge badege-pink me-1 searchbadge">
                            UPDATE PROJECT </span> </span>
                        <div className="view-message v-center  d-inline-block">
                          <strong> Update
                            Project </strong> - Hey Nicolas! How are you
                          doing? Right
                          now I will write….
                        </div>
                      </div>
                      <div className="col-xl-2  email-date">
                        15 Apr 3:42PM
                      </div>
                    </div>
                    <div className="mail-row d-flex new-mail ">
                      <div className="col-xl-3">
                        <div className="form-check d-inline-block v-center lg-checkbox">
                          <input className="form-check-input lg-checkbox" type="checkbox" id="checkboxNoLabel" aria-label="..." />
                        </div>
                        <span className="star-mail-column d-inline-block v-center ">
                          <img src={getImage("icon-star.svg")} alt="" />
                        </span>
                        <span className="text-primary font-semibold"> <span className="number-badge badege-pink me-1"> JC
                        </span> Jane
                          Cooper </span>
                      </div>
                      <div className="col-xl-7">
                        <span className="text-primary font-semibold d-inline-block v-center">
                          <span className="number-badge badege-pink me-1"> NEW
                          </span> </span>
                        <div className="view-message v-center  d-inline-block">
                          <strong> Update
                            Project </strong> - Hey Nicolas! How are you
                          doing? Right
                          now I will write….
                        </div>
                      </div>
                      <div className="col-xl-2  email-date">
                        15 Apr 3:42PM
                      </div>
                    </div>
                    <div className="mail-row d-flex ">
                      <div className="col-xl-3">
                        <div className="form-check d-inline-block v-center lg-checkbox">
                          <input className="form-check-input lg-checkbox" type="checkbox" id="checkboxNoLabel" aria-label="..." />
                        </div>
                        <span className="star-mail-column d-inline-block v-center ">
                          <img src={getImage("icon-star.svg")} alt="" />
                        </span>
                        <span className="text-primary font-semibold"> <span className="number-badge badege-pink me-1"> JC
                        </span> Jane
                          Cooper </span>
                      </div>
                      <div className="col-xl-7">
                        <span className="text-primary font-semibold d-inline-block v-center">
                          <span className="number-badge badege-pink me-1"> NEW
                          </span> </span>
                        <div className="view-message v-center  d-inline-block">
                          <strong> Update
                            Project </strong> - Hey Nicolas! How are you
                          doing? Right
                          now I will write….
                        </div>
                      </div>
                      <div className="col-xl-2  email-date">
                        15 Apr 3:42PM
                      </div>
                    </div>
                    <div className="mail-row d-flex ">
                      <div className="col-xl-3">
                        <div className="form-check d-inline-block v-center lg-checkbox">
                          <input className="form-check-input lg-checkbox" type="checkbox" id="checkboxNoLabel" aria-label="..." />
                        </div>
                        <span className="star-mail-column d-inline-block v-center ">
                          <img src={getImage("icon-star.svg")} alt="" />
                        </span>
                        <span className="text-primary font-semibold"> <span className="number-badge badege-pink me-1"> JC
                        </span> Jane
                          Cooper </span>
                      </div>
                      <div className="col-xl-7">
                        <span className="text-primary font-semibold d-inline-block v-center">
                        </span>
                        <div className="view-message v-center  d-inline-block">
                          <strong> Update
                            Project </strong> - Hey Nicolas! How are you
                          doing? Right
                          now I will write….
                        </div>
                      </div>
                      <div className="col-xl-2  email-date">
                        15 Apr 3:42PM
                      </div>
                    </div>
                    <div className="mail-row d-flex new-mail">
                      <div className="col-xl-3">
                        <div className="form-check d-inline-block v-center lg-checkbox">
                          <input className="form-check-input lg-checkbox" type="checkbox" id="checkboxNoLabel" aria-label="..." />
                        </div>
                        <span className="star-mail-column d-inline-block v-center ">
                          <img src={getImage("icon-star.svg")} alt="" />
                        </span>
                        <span className="text-primary font-semibold"> <span className="number-badge badege-pink me-1"> JC
                        </span> Jane
                          Cooper </span>
                      </div>
                      <div className="col-xl-7">
                        <span className="text-primary font-semibold d-inline-block v-center">
                          <span className="number-badge badege-pink me-1"> NEW
                          </span> </span>
                        <div className="view-message v-center  d-inline-block">
                          <strong> Update
                            Project </strong> - Hey Nicolas! How are you
                          doing? Right
                          now I will write….
                        </div>
                      </div>
                      <div className="col-xl-2  email-date">
                        15 Apr 3:42PM
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="draft" role="tabpanel" aria-labelledby="draft-tab">
                    <div className="mail-row d-flex  ">
                      <div className="col-xl-3">
                        <div className="form-check d-inline-block v-center lg-checkbox">
                          <input className="form-check-input lg-checkbox" type="checkbox" id="checkboxNoLabel" aria-label="..." />
                        </div>
                        <span className="star-mail-column d-inline-block v-center ">
                          <svg width={18} height={17} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.6503 6.04L11.8103 5.62L9.92032 1.17C9.58032 0.36 8.42032 0.36 8.08032 1.17L6.19032 5.63L1.36032 6.04C0.480317 6.11 0.120317 7.21 0.790317 7.79L4.46032 10.97L3.36032 15.69C3.16032 16.55 4.09032 17.23 4.85032 16.77L9.00032 14.27L13.1503 16.78C13.9103 17.24 14.8403 16.56 14.6403 15.7L13.5403 10.97L17.2103 7.79C17.8803 7.21 17.5303 6.11 16.6503 6.04ZM9.00032 12.4L5.24032 14.67L6.24032 10.39L2.92032 7.51L7.30032 7.13L9.00032 3.1L10.7103 7.14L15.0903 7.52L11.7703 10.4L12.7703 14.68L9.00032 12.4Z" fill="#AAAAAA" />
                          </svg>
                        </span>
                        <span className="text-primary font-semibold"> <span className="number-badge badege-pink me-1"> JC
                        </span> Jane
                          Cooper </span>
                      </div>
                      <div className="col-xl-7">
                        <span className="text-primary font-semibold d-inline-block v-center">
                          <span className="number-badge badege-pink me-1">
                            Draft </span> </span>
                        <div className="view-message v-center  d-inline-block">
                          <strong> Update
                            Project </strong> - Hey Nicolas! How are you
                          doing? Right
                          now I will write….
                        </div>
                      </div>
                      <div className="col-xl-2  email-date">
                        15 Apr 3:42PM
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="starred" role="tabpanel" aria-labelledby="starred-tab">
                    <div className="mail-row d-flex ">
                      <div className="col-xl-3">
                        <div className="form-check d-inline-block v-center lg-checkbox">
                          <input className="form-check-input lg-checkbox" type="checkbox" id="checkboxNoLabel" aria-label="..." />
                        </div>
                        <span className="star-mail-column d-inline-block v-center ">
                          <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.0003 17.2698L16.1503 19.7798C16.9103 20.2398 17.8403 19.5598 17.6403 18.6998L16.5403 13.9798L20.2103 10.7998C20.8803 10.2198 20.5203 9.11977 19.6403 9.04977L14.8103 8.63977L12.9203 4.17977C12.5803 3.36977 11.4203 3.36977 11.0803 4.17977L9.19032 8.62977L4.36032 9.03977C3.48032 9.10977 3.12032 10.2098 3.79032 10.7898L7.46032 13.9698L6.36032 18.6898C6.16032 19.5498 7.09032 20.2298 7.85032 19.7698L12.0003 17.2698Z" fill="#FFB550" />
                          </svg>
                        </span>
                        <span className="text-primary font-semibold"> <span className="number-badge badege-pink me-1"> JC
                        </span> Jane
                          Cooper </span>
                      </div>
                      <div className="col-xl-7">
                        <div className="view-message v-center  d-inline-block">
                          <strong> Update
                            Project </strong> - Hey Nicolas! How are you
                          doing? Right
                          now I will write….
                        </div>
                      </div>
                      <div className="col-xl-2  email-date">
                        15 Apr 3:42PM
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="trash" role="tabpanel" aria-labelledby="trash-tab">
                    <div className="mail-row d-flex ">
                      <div className="col-xl-3">
                        <div className="form-check d-inline-block v-center lg-checkbox">
                          <input className="form-check-input lg-checkbox" type="checkbox" id="checkboxNoLabel" aria-label="..." />
                        </div>
                        <span className="star-mail-column d-inline-block v-center ">
                          <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM18 4H15.5L14.79 3.29C14.61 3.11 14.35 3 14.09 3H9.91C9.65 3 9.39 3.11 9.21 3.29L8.5 4H6C5.45 4 5 4.45 5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4Z" fill="#585858" />
                          </svg>
                        </span>
                        <span className="text-primary font-semibold"> <span className="number-badge badege-pink me-1"> JC
                        </span> Jane
                          Cooper </span>
                      </div>
                      <div className="col-xl-7">
                        <div className="view-message v-center  d-inline-block">
                          <strong> Update
                            Project </strong> - Hey Nicolas! How are you
                          doing? Right
                          now I will write….
                        </div>
                      </div>
                      <div className="col-xl-2  email-date">
                        15 Apr 3:42PM
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderRelatedEmails;
