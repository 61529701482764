import { getImage } from "../../../helpers/assets";
import { useAppDispatch } from "../../../store/hooks";

const RenderContactsTabs = () => {
  const dispatch = useAppDispatch();
  return (
    <div className="card-block p-3 mb-3">
      <ul className="nav nav-pills custom-pills-tab mb-0" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <button className="nav-link active text-uppercase" id="contactInfo-tab" data-bs-toggle="pill" data-bs-target="#contactInfo" type="button" role="tab" aria-controls="contactInfo" aria-selected="true">CONNTACT INFO</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link  text-uppercase" id="appointments-tab" data-bs-toggle="pill" data-bs-target="#appointments" type="button" role="tab" aria-controls="appointments" aria-selected="false">Appointments</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link  text-uppercase" id="surgeries-tab" data-bs-toggle="pill" data-bs-target="#surgeries" type="button" role="tab" aria-controls="pills-surgeries" aria-selected="false">Surgeries</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link  text-uppercase" id="revenue-tab" data-bs-toggle="pill" data-bs-target="#revenue" type="button" role="tab" aria-controls="pills-revenue" aria-selected="false">Revenue</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link  text-uppercase" id="tasks-tab" data-bs-toggle="pill" data-bs-target="#tasks" type="button" role="tab" aria-controls="pills-tasks" aria-selected="false">Tasks</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link  text-uppercase" id="communications-tab" data-bs-toggle="pill" data-bs-target="#communications" type="button" role="tab" aria-controls="pills-communications" aria-selected="false">Communications</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link  text-uppercase" id="files-tab" data-bs-toggle="pill" data-bs-target="#files" type="button" role="tab" aria-controls="pills-files" aria-selected="false">Files</button>
        </li>
      </ul>
    </div>
  );
}

export default RenderContactsTabs;
