import { useCallback, useEffect, useReducer, useState } from "react";
import { useCookies } from "react-cookie";
import { authenticateUserToken } from "../services/auth";
import { IUserDetails } from "../store/reducers/user/userSlice";


export const useAuth = (): {
  token?: string | null;
  isAuthenticated?: boolean;
  currentUser?: IUserDetails | null | undefined;
  loginUser?: (token: string | null | undefined, user: IUserDetails, rememberMe?: boolean) => void;
  logoutUser?: () => void;
} => {
  const tokenKey = "access-token";
  const [cookies, setCookie, removeCookie] = useCookies([tokenKey]);
  const [token, setToken] = useState<string | null>(cookies[tokenKey]);
  const [localStoragetoken, setLocalStorageAuthToken] = useReducer(
    (_old: string | null, localStorageToken: string | null) => {
      if (!token && localStorageToken) {
        localStorage.setItem(tokenKey, localStorageToken);
        setToken(localStorageToken);
      }
      return localStorageToken;
    },
    localStorage.getItem(tokenKey)
  );
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    Boolean(cookies[tokenKey]) || Boolean(localStoragetoken)
  );
  const [currentUser, setCurrentUser] = useState<IUserDetails | null>();
  const [loading, setLoading] = useState<boolean>();

  const loginUser = (
    token: string | null | undefined,
    user: IUserDetails,
    rememberMe?: boolean
  ) => {
    setCookie(tokenKey, token);
    setCurrentUser(user);
    setIsAuthenticated(true);
    if (token && rememberMe) {
      setLocalStorageAuthToken(token);
    }
  };

  const logoutUser = useCallback(() => {
    localStorage.removeItem(tokenKey);
    setLocalStorageAuthToken(null);
    setCurrentUser(null);
    removeCookie(tokenKey);
    setIsAuthenticated(false);
  }, [removeCookie]);

  useEffect(() => {
    if (!currentUser && token && !loading) {
      setLoading(true);
      authenticateUserToken(token)
        .then((response) => {
          const { user } = response.data;
          setCurrentUser(user);
          setIsAuthenticated(true);
          setLoading(false);
        })
        .catch((error) => {
          logoutUser();
          setLoading(false);
        });
    }
  }, [currentUser, token, logoutUser, loading]);

  return {
    token: cookies["access-token"] || localStoragetoken,
    isAuthenticated,
    currentUser,
    loginUser,
    logoutUser,
  };
};

