import httpClient from "../helpers/httpClient";

export const getDashboardMeta = (token: string) => {
  return httpClient.get(`/dashboard/meta/`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};


export const getDashboardGraphData = (token: string, selectedGraph: string) => {
  return httpClient.get(`/dashboard/graph/?selected_graph=${selectedGraph}`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

