import { getImage } from "../../../helpers/assets";
import { useAppDispatch } from "../../../store/hooks";

const RenderRelatedTasks = () => {
  const dispatch = useAppDispatch();
  return (
    <div className="card-block uniform-space-b-4 ">
      <div className="accordion" id="accordionnotesAttachments">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseTwo">
              Related Tasks
            </button>
          </h2>
          <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionnotesAttachments">
            <div className="accordion-body">
              <div className=" table-custome">
                <table className="table datatable responsive-mobile-table">
                  <thead>
                    <tr>
                      <th scope="col"> <span> Subject </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i>
                      </th>
                      <th scope="col"> <span> Category </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i>
                      </th>
                      <th scope="col"> <span> Due Date </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i>
                      </th>
                      <th scope="col"> <span> Status </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i>
                      </th>
                      <th scope="col"><span> Priority </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i>
                      </th>
                      <th scope="col"> <span> assigned to </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i>
                      </th>
                      <th scope="col"> <span> patient Name </span> <i className="short-arrow"> <img src={getImage("short-arrow.svg")} alt="" /> </i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-cell="Subject"> <span className="text-primary d-inline-block v-center font-semibold">
                        <a href="task-detail.html" className="no-underline">
                          3rd patient appointment </a></span> </td>
                      <td data-cell="Category"> <span className="number-badge badege-gray me-1">
                        Appointments </span> </td>
                      <td data-cell="Due Date ">Mar 28, 2022 </td>
                      <td data-cell="Status"> In Progress </td>
                      <td data-cell="Priority"><span className="number-badge badege-danger ms-1"> Highest
                      </span> </td>
                      <td data-cell="assigned to"> <span className="number-badge badege-primary ms-1"> Jane
                        Cooper </span> <span className="number-badge badege-primary ms-1"> +2
                        </span> </td>
                      <td data-cell="patient Name"> <span className="text-primary font-semibold"> <span className="number-badge badege-primary me-1"> MT
                      </span> Mitsue Tollner </span> </td>
                    </tr>
                    <tr>
                      <td data-cell="Subject"> <span className="text-primary d-inline-block v-center font-semibold">
                        <a href="task-detail.html" className="no-underline">
                          3rd patient appointment </a></span> </td>
                      <td data-cell="Category"> <span className="number-badge badege-gray me-1">
                        Appointments </span> </td>
                      <td data-cell="Due Date ">Mar 28, 2022 </td>
                      <td data-cell="Status"> In Progress </td>
                      <td data-cell="Priority"><span className="number-badge badege-danger ms-1"> Highest
                      </span> </td>
                      <td data-cell="assigned to"> <span className="number-badge badege-primary ms-1"> Jane
                        Cooper </span> <span className="number-badge badege-primary ms-1"> +2
                        </span> </td>
                      <td data-cell="patient Name"> <span className="text-primary font-semibold"> <span className="number-badge badege-primary me-1"> MT
                      </span> Mitsue Tollner </span> </td>
                    </tr>
                    <tr>
                      <td data-cell="Subject"> <span className="text-primary d-inline-block v-center font-semibold">
                        <a href="task-detail.html" className="no-underline">
                          3rd patient appointment </a></span> </td>
                      <td data-cell="Category"> <span className="number-badge badege-gray me-1">
                        Appointments </span> </td>
                      <td data-cell="Due Date ">Mar 28, 2022 </td>
                      <td data-cell="Status"> In Progress </td>
                      <td data-cell="Priority"><span className="number-badge badege-danger ms-1"> Highest
                      </span> </td>
                      <td data-cell="assigned to"> <span className="number-badge badege-primary ms-1"> Jane
                        Cooper </span> <span className="number-badge badege-primary ms-1"> +2
                        </span> </td>
                      <td data-cell="patient Name"> <span className="text-primary font-semibold"> <span className="number-badge badege-primary me-1"> MT
                      </span> Mitsue Tollner </span> </td>
                    </tr>
                    <tr>
                      <td data-cell="Subject"> <span className="text-primary d-inline-block v-center font-semibold">
                        <a href="task-detail.html" className="no-underline">
                          3rd patient appointment </a></span> </td>
                      <td data-cell="Category"> <span className="number-badge badege-gray me-1">
                        Appointments </span> </td>
                      <td data-cell="Due Date ">Mar 28, 2022 </td>
                      <td data-cell="Status"> In Progress </td>
                      <td data-cell="Priority"><span className="number-badge badege-danger ms-1"> Highest
                      </span> </td>
                      <td data-cell="assigned to"> <span className="number-badge badege-primary ms-1"> Jane
                        Cooper </span> <span className="number-badge badege-primary ms-1"> +2
                        </span> </td>
                      <td data-cell="patient Name"> <span className="text-primary font-semibold"> <span className="number-badge badege-primary me-1"> MT
                      </span> Mitsue Tollner </span> </td>
                    </tr>
                    <tr>
                      <td data-cell="Subject"> <span className="text-primary d-inline-block v-center font-semibold">
                        <a href="task-detail.html" className="no-underline">
                          3rd patient appointment </a></span> </td>
                      <td data-cell="Category"> <span className="number-badge badege-gray me-1">
                        Appointments </span> </td>
                      <td data-cell="Due Date ">Mar 28, 2022 </td>
                      <td data-cell="Status"> In Progress </td>
                      <td data-cell="Priority"><span className="number-badge badege-danger ms-1"> Highest
                      </span> </td>
                      <td data-cell="assigned to"> <span className="number-badge badege-primary ms-1"> Jane
                        Cooper </span> <span className="number-badge badege-primary ms-1"> +2
                        </span> </td>
                      <td data-cell="patient Name"> <span className="text-primary font-semibold"> <span className="number-badge badege-primary me-1"> MT
                      </span> Mitsue Tollner </span> </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderRelatedTasks;
