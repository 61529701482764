import { useState } from "react";
import { getImage } from "../../../helpers/assets";
import { useAuth } from "../../../hooks/useAuth";
import { useCalling } from "../../../hooks/useCall";
import { updateContact } from "../../../services/contacts";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { addTag, IContactDetail, removeLastTag, selectContactState } from "../../../store/reducers/contact/contactSlice";


const RenderMainSection = () => {
  const dispatch = useAppDispatch();
  const { token } = useAuth();
  const { handleCallClick } = useCalling();
  const contactDetail = useAppSelector(selectContactState);
  const { id, tags, first_name, last_name, phone_no } = contactDetail;
  const [tagEditable, setTagEditable] = useState<boolean>(false);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [newTag, setNewTag] = useState<string>();
  const tagColors = ['badege-orange-dark', 'badege-green-dark', 'badege-orange-dark']


  const handleAddTag = (newTag: string) => {
    setTagEditable(false);
    if (tags && token) {
      const localTags = [...tags];
      localTags?.push(newTag);
      setSaveInProgress(true);
      updateContact(token, id, {
        tags: localTags
      }).then((response) => {
        setSaveInProgress(false);
        dispatch(addTag(newTag))
      }).catch((error) => {
        setSaveInProgress(false);
      });
    }
  }

  const handleRemoveTag = () => {
    setTagEditable(false);
    if (tags && token) {
      const localTags = [...tags];
      localTags?.pop();
      setSaveInProgress(true);
      updateContact(token, id, {
        tags: localTags
      }).then((response) => {
        setSaveInProgress(false);
        dispatch(removeLastTag())
      }).catch((error) => {
        setSaveInProgress(false);
      });
    }
  }

  return (
    <div className="col-xl-10">
      <div className="card-block">
        <div className="subaction-with-title d-flex justify-content-between align-items-center  ">
          <div className="d-flex align-items-center">
            <span className="number-badge name-placeholder lg-placeholder badege-pink ms-1 text-uppercase me-4 ">
              {`${first_name && first_name.charAt(0).toUpperCase()}${last_name && last_name.charAt(0).toUpperCase()}`}
            </span>
            <div className="title-with-edit">
              <h3> {first_name} {last_name}</h3>
              <div className="space-x-2 mt-3">
                {tags && tags.map((tag, index) => <span key={`tag - ${index}`} className={`number-badge ms-1 text-uppercase ${tagColors[index]}`}>&nbsp;{tag}&nbsp;</span>)}
                {tagEditable && <span className={`number-badge ms-1 text-uppercase tag-editable-input ${tags && tagColors[tags?.length] || 'badege-orange-dark'}`}>&nbsp;<input
                  autoFocus
                  onChange={(e) => {
                    setNewTag(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && newTag) {
                      handleAddTag(newTag);
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value) {
                      handleAddTag(e.target.value);
                    } else {
                      setTagEditable(!tagEditable);
                    }
                  }}
                  type="text"
                  placeholder="Untitled"
                />&nbsp;</span>}
                {(!tags || (tags && tags.length < 3)) && <button className="btn btn-outline-primary btn-xs ms-1" onClick={() => {
                  setTagEditable(!tagEditable);
                }}>
                  <i className="fa fa-plus"></i>
                </button>}
                {tags && tags.length >= 1 && <button className="btn btn-outline-primary btn-xs ms-1" onClick={() => {
                  handleRemoveTag();
                }}>
                  <i className="fa fa-minus"></i>
                </button>}
              </div>
              {saveInProgress && 'Saving...'}
            </div>
          </div>
          <div className="action-right   ">
            <div className=" d-inline-block list-action v-center ">
              <a href="#" className="icon-btn me-1 "><img src={getImage("icon-toggle-white.svg")} alt="" /></a>
              <a href="#" className="icon-btn me-1"><img src={getImage("icon-message-white.svg")} alt="" /></a>
              <span className="icon-btn " onClick={() => {
                if (phone_no && id) {
                  let username = phone_no;
                  if (first_name || last_name) {
                    const username = `${first_name} ${last_name}`;
                  }
                  handleCallClick(phone_no, username, id);
                }
              }}><img src={getImage("icon-call-white.svg")} alt="" /></span>
            </div>
            <div className=" dropdown d-inline-block ">
              <a className="btn-dots dropdown-toggle d-inline-block" href="#" id="dropdonw-more" data-bs-toggle="dropdown" aria-expanded="false">
                <div className="btn-moreaction">
                  <img src={getImage("icon-more-verticale.svg")} />
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-right shadow dropdown-items-with-icon" aria-labelledby="dropdonw-more" style={{}}><a className="btn-dots dropdown-toggle" href="#" id="dropdonw-more" data-bs-toggle="dropdown" aria-expanded="false">
              </a><a className="dropdown-item" href="#">
                  <img src={getImage("icon-contact-info.svg")} alt="" />
                  Action
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RenderMainSection;
