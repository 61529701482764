import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { useEffect, useRef, useState } from 'react';
import { getImage } from "../../../helpers/assets";
import { useAuth } from '../../../hooks/useAuth';
import { sendMessage } from '../../../services/chat';
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IMessages, MESSAGE_TYPE, selectChatState, setReplyTo } from "../../../store/reducers/chat/chatSlice";

const ChatTemplateMessages = () => {
  const dispatch = useAppDispatch();
  const { token } = useAuth();
  const { client, currentRoom, replyTo } = useAppSelector(selectChatState);

  const sendTemplateMessage = (textMessage: string) => {
    if (currentRoom) {
      sendMessage(token, currentRoom.id, textMessage, MESSAGE_TYPE.TEXT, replyTo?.id).then((response) => {
        if (client) {
          client.send(
            JSON.stringify({
              action: "ADD_MESSAGE",
              message: response.data
            })
          );
        }
      })
    }
  }

  return (
    <ul className="template-message">
      <li> Template massage </li>
      <li>
        <button className="btn btn-sm btn-primary">
          + add a new template
        </button>
      </li>
      <li>
        <button className="btn  btn-sm btn-light" onClick={() => {
          sendTemplateMessage("Waiting to reply");
        }}>
          Waiting to reply
        </button>
      </li>
      <li>
        <button className="btn  btn-sm  btn-light" onClick={() => {
          sendTemplateMessage("Thank you for waiting");
        }}>
          Thank you for waiting
        </button>
      </li>
    </ul>
  );
}

const MessageReplyComponent = ({ message }: { message: IMessages }) => {
  const dispatch = useAppDispatch();

  return (
    <div className="quote-message-to-forward">
      <span className="close" onClick={() => dispatch(setReplyTo(null))}>
        <img src={getImage("icon-close-dark.png")} alt="" />
      </span>
      <h4>
        {`${message?.user?.first_name} ${message?.user?.last_name}`}
      </h4>
      <p>
        {message.message}
      </p>
    </div>
  );
}

const ChatFooterComponent = () => {
  const dispatch = useAppDispatch();
  const { token } = useAuth();
  const chatBoxRef = useRef(null);
  const textInputRef = useRef<HTMLInputElement>(null);
  const [textMessage, setTextMessage] = useState<string>("")
  const [showEmojiPicker, toggleShowEmojiPicker] = useState<boolean>(false)
  const { client, currentRoom } = useAppSelector(selectChatState);
  const { replyTo, isMessageSelectorEnabled, selectedMessages } = useAppSelector(selectChatState);

  const handleSendMessageClick = () => {
    if (currentRoom && textMessage) {
      sendMessage(token, currentRoom.id, textMessage, MESSAGE_TYPE.TEXT, replyTo?.id).then((response) => {
        if (client) {
          client.send(
            JSON.stringify({
              action: "ADD_MESSAGE",
              message: response.data
            })
          );
        }
        setTextMessage("");
        if (textInputRef.current) {
          textInputRef.current.value = '';
        }
      })

    }
  }

  const handleBulkDelete = () => {
    console.log(selectedMessages);
    // Handle Bulk Delete
  }

  const handleBulkStar = () => {
    console.log(selectedMessages);
    // Handle Bulk Star
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (event.target && $(document.getElementsByClassName("EmojiPickerReact")).find(event.target).length === 0) {
        toggleShowEmojiPicker(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [chatBoxRef]);
  

  if (isMessageSelectorEnabled) {
    return (
      <div className="footer-message">
        <div className="footer-message-formword">
          <div className="selected-n">
            <img src={getImage("icon-close-dark.png")} alt="" />
            <span> 2 Selected </span>
          </div>
          <div className="action">
            <span onClick={handleBulkStar}><img src={getImage("star_border.svg")} alt="" /></span>
            <span onClick={handleBulkDelete}><img src={getImage("delete_outline.svg")} alt="" /></span>
            <span onClick={() => {
              $(`#selectedMessagesModal`).modal('show');
            }}>
              <img src={getImage("forward.svg")} alt="" />
            </span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="footer-message">
      <ChatTemplateMessages />
      {replyTo && <MessageReplyComponent message={replyTo} />}
      <div className="form-group row chat-input-box">
        <div className="col-xl-10 relative">
          <div className="over-input-icon">
            {/* <span >
              <img src={getImage("attach_file.png")} alt="" />
            </span> */}
            <span onClick={() => {
              toggleShowEmojiPicker(!showEmojiPicker)
            }}>
              <img src={getImage("insert_emoticon.png")} alt="" />
            </span>
          </div>
          {showEmojiPicker &&
            <div className="over-input-icon" ref={chatBoxRef}>
              <EmojiPicker onEmojiClick={(emojiData: EmojiClickData, event: MouseEvent) => {
                const currentCaretPosition = textInputRef.current?.selectionStart || 0;
                let caretPosition = currentCaretPosition + 1;
                const updatedText = caretPosition ?
                  textMessage.slice(0, caretPosition - 1) + emojiData.emoji + textMessage.slice(caretPosition - 1) :
                  textMessage + emojiData.emoji
                setTextMessage(updatedText);
              }} />
            </div>
          }
          <input
            type="text"
            ref={textInputRef}
            value={textMessage}
            onChange={(e) => setTextMessage(e.target.value)}
            placeholder="Write a message here"
            className="styled-control control-sm chat-input"
          />
        </div>
        <div className="col-xl-2">
          <button className="btn btn-primary btn-sm padd-x-20  w-full" onClick={handleSendMessageClick}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChatFooterComponent;
