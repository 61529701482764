import logo from "../assets/images/logo.svg";

const LogoComponent = () => {
  return (
    <div className="logo-area mobile-hide">
      <img src={logo} alt="logo" />
    </div>
  );
};

export default LogoComponent;
